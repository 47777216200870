import {useCallback} from "react";
import {Dnd5eActionMessage} from "common/qlab/message/dnd-5e/dnd-5e-action-message.ts";
import {useAccess, useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import {generateMessageID, MessageOperation, MessageValue} from "common/qlab/message/index.ts";
import {useSeedTime} from "../../../../../routes/game/use-seed-time.ts";
import {fromSignal, MutableRef} from "common/ref";
import {Optional} from "common/types/generic/optional/index.ts";
import {ValueFn} from "common/types/generic/value/index.ts";
import {userIDRef} from "#lib/auth/use-get-user-id.ts";

export function useSendDnd5eAction() {
  const database = useDatabase();
  const access = useAccess();
  const time = useSeedTime();
  return useCallback(async (props: Omit<Dnd5eActionMessage, "userID" | "rollResults">): Promise<MutableRef<Optional<MessageValue>, MessageOperation[]>> => {
    const messageID = generateMessageID(time());
    return database.apply((_) => {
      return [{type: "message", messageID, operations: ValueFn.set(undefined, {
        type: "dnd-5e-action-message", data: {
          ...props,
          userID: userIDRef.value!,
          rollResults: undefined
        }
      })}]
    }).then(() => fromSignal(access.message(messageID)));
  }, [database, access, time])
}