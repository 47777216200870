import {ButtonBar, ButtonBarProps} from "./button/index.ts";
import {HTMLAttributes, LabelHTMLAttributes, PropsWithChildren, ReactNode, useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {useToggle} from "#lib/components/toggle/index.ts";
import {ulid} from "ulid";

export function PanelHeader({children, className, ...props}: PropsWithChildren<ButtonBarProps>) {
  return (<ButtonBar {...props} className={twMerge("flex bg-zinc-900/50 py-2 items-center", className)}>
    <span className="px-4 text-h500">{children}</span>
  </ButtonBar>);
}
export function PanelBody({className, children}: PropsWithChildren<{className?: string}>) {
  return (<div className={twMerge("flex flex-col gap-2 divide-y-2 divide-zinc-800/50", className)}>
    {children}
  </div>);
}

export function Panel({title, children, className}: PropsWithChildren<{
  title?: string,
  className?: string
}>) {
  return (
    <BaseComponent className={twMerge("flex flex-col gap-0", className)}>
      {title && <PanelHeader>{title}</PanelHeader>}
      <PanelBody>{children}</PanelBody>
    </BaseComponent>
  );
}

export function SectionBody({className, children}: PropsWithChildren<{className?: string}>) {
  return (<div className={twMerge("flex flex-col gap-2 pb-2", className)}>
    {children}
  </div>);
}


export function SectionHeader({children, className, ...props}: PropsWithChildren<ButtonBarProps>) {
  return (<ButtonBar {...props} className={twMerge("flex", props.onClick && "cursor-pointer", className)}>
    <span className="flex-1 px-4 h-12 text-h400 items-center flex">{children}</span>
  </ButtonBar>);
}

export function Section({title, open, openByDefault = true, children, options, pt}: PropsWithChildren<{
  open?: boolean,
  openByDefault?: boolean,
  title?: ReactNode,
  options?: ReactNode,
  pt?: {
    root?: string,
    header?: string,
    body?: string
  }
}>) {
  const [toggled, toggle] = useToggle(openByDefault);
  return (
    <div className={twMerge("flex flex-col gap-2", pt?.root)}>
      {title && <SectionHeader className={twMerge("pt-2", pt?.header)}>
        <span className="flex-1" onClick={e => open === undefined && toggle()}>{title}</span>
        {options}
      </SectionHeader>}
      {toggled && <SectionBody className={twMerge("pb-2", pt?.body)}>{children}</SectionBody>}
    </div>
  );
}


export function useUniqueID(prefix: string) {
  return useMemo(() => `${prefix}-${ulid()}`, [prefix]);
}

export function Field({children, className, ...props}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (<div className={twMerge("flex flex-col gap-1", className)} {...props}>
    {children}
  </div>);
}

export function FieldLabel({children, className, ...props}: PropsWithChildren<LabelHTMLAttributes<HTMLLabelElement>>) {
  return (<label className={twMerge("px-4 py-1 text-sm font-bold", className)} {...props}>{children}</label>);
}

