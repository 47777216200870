import {MultiType, MultiTypeOperation, MultiTypeValue, Optional, Type} from "../../types/index.ts";
import {TokenNode, TokenNodeOperation, tokenNodeType} from "./token-node.ts";
import {ImageNode, ImageNodeOperation, imageNodeType} from "./image-node.ts";
import {GroupNode, GroupNodeOperation, groupNodeType} from "./group-node.ts";
import {GridNode, GridNodeOperation, gridNodeType} from "./grid-node.ts";
import {WallNode, WallNodeOperation, wallNodeType} from "./wall-node.ts";
import {AreaNode, AreaNodeOperation, areaNodeType} from "./area-node.ts";
import {TextNode, TextNodeOperation, textNodeType} from "./text-node.ts";
import {ShapeNode, ShapeNodeOperation, shapeNodeType} from "./shape-node.ts";
import {ParallaxNode, ParallaxNodeOperation, parallaxNodeType} from "./parallax-node.ts";
import {LightNode, LightNodeOperation, lightNodeType} from "./light/light-node.ts";
import {CameraElement, CameraElementOperation, cameraElementType} from "./camera-element.ts";
import {NodeId} from "./node-id.ts";
import {VideoNode, VideoNodeOperation, videoNodeType} from "./video-node.ts";
import {MutableSignal} from "#common/signal";

export type NodeTypes = {
  "area": Type<AreaNode, AreaNodeOperation>;
  "grid": Type<GridNode, GridNodeOperation>;
  "group": Type<GroupNode, GroupNodeOperation>;
  "image": Type<ImageNode, ImageNodeOperation>;
  "shape": Type<ShapeNode, ShapeNodeOperation>;
  "text": Type<TextNode, TextNodeOperation>;
  "token": Type<TokenNode, TokenNodeOperation>;
  "wall": Type<WallNode, WallNodeOperation>;
  "parallax": Type<ParallaxNode, ParallaxNodeOperation>;
  "light": Type<LightNode, LightNodeOperation>;
  "video": Type<VideoNode, VideoNodeOperation>;
  "camera": Type<CameraElement, CameraElementOperation>;
}
export type Node = MultiTypeValue<NodeTypes>;
export type NodeOperation = MultiTypeOperation<NodeTypes>;
export const nodeType: Type<Node, NodeOperation> = new MultiType(() => ({
  area: areaNodeType,
  grid: gridNodeType,
  group: groupNodeType,
  image: imageNodeType,
  parallax: parallaxNodeType,
  shape: shapeNodeType,
  text: textNodeType,
  token: tokenNodeType,
  wall: wallNodeType,
  light: lightNodeType,
  video: videoNodeType,
  camera: cameraElementType
}));
export type OptionalNodeSignal = MutableSignal<Optional<Node>, NodeOperation[]>
export type NodeSignal = MutableSignal<Optional<Node>, NodeOperation[]>

export const NodeFn = {
  copyElement(element: Node): Node {
    return ({
      ...element,
      data: {
        ...element.data,
        id: NodeId.generate(),
        children: element.data.children.map(NodeFn.copyElement)
      }
    } as Node);
  }
};