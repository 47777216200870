import {computed} from "#common/signal";
import {AssetID, OptionalAssetSignal} from "#common/legends/index.ts";
import {Cache} from "../../cache.ts";
import {ResourceCache} from "#common/legends/access/resource/resource-cache.ts";
import {WeakCache} from "#common/legends/access/weak-cache.ts";
import {Optional} from "#common/types/index.js";

export type AssetCache = Cache<Optional<AssetID>, OptionalAssetSignal>;
export function AssetCache(resourceCache: ResourceCache): AssetCache {
  const cache = new WeakCache<AssetID, OptionalAssetSignal>(assetID => {
    const asset = resourceCache(assetID);
    return computed(
      () => {
        const resource = asset.value;
        return (resource?.type === "asset") ? resource.data : undefined;
      },
      operations => asset.apply(_ => [{type: "asset", operations}])
    )
  });
  return (assetID: Optional<AssetID>) => assetID ? cache.get(assetID) : computed(() => undefined, () => {});
}
