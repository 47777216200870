import {classNames} from "primereact/utils";
import {PrimeReactPTOptions} from "primereact/api";

export const DefaultTheme: PrimeReactPTOptions = {
  overlaypanel: {
    root: {
      className: classNames(
        "bg-transparent rounded-md overflow-hidden text-white origin-center shadow-lg text-white"
      )
    },
    content: {
      className: classNames("flex flex-col gap-2 px-0 py-0 items-stretch")
    }
  }
};