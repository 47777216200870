import * as PreactSignal from "@preact/signals-core";

export interface Signal<Value> {
  get value(): Value;
}
export interface ReadonlySignal<Value> extends Signal<Value> {}
export interface MutableSignal<Value, Action> extends Signal<Value> {
  apply(fn: (prev: Value) => Action): void;
}

export function effect(fn: () => void): () => void {
  return PreactSignal.effect(fn);
}

export function computed<Value, Action>(
  valueFn: () => Value,
  applyFn: (action: Action) => void
): MutableSignal<Value, Action>;
export function computed<Value>(
  valueFn: () => Value
): MutableSignal<Value, never[]>;
export function computed<Value, Action>(
  valueFn: () => Value,
  applyFn?: (action: Action) => Value
) {
  if (applyFn) {
    const computed = PreactSignal.computed(valueFn);
    return {
      get value() {
        return computed.value;
      },
      apply: (fn: (prev: Value) => Action): void => {
        const prev = computed.value;
        applyFn(fn(prev));
      }
    }
  } else {
    const computed = PreactSignal.computed(valueFn);
    return {
      get value() {
        return computed.value;
      },
      apply: (_: (prev: Value) => Action): void => {
        throw new Error("Unsupported");
      }
    }
  }
}

export function valueSignal<Value>(initialValue: Value, equality: (a: Value, b: Value) => boolean = (a, b) => Object.is(a, b)): MutableSignal<Value, Value> {
  const signal = new PreactSignal.Signal(initialValue);
  return {
    get value() {
      return signal.value;
    },
    apply(fn: (prev: Value) => Value) {
      const nextValue = fn(signal.value);
      if (!equality(signal.value, nextValue)) {
        signal.value = fn(signal.value);
      }
    }
  }
}
