import {Checkbox, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {Game, Player, UserID} from "common/legends/index.ts";
import {Owner} from "common/legends/game/owner/index.ts";
import {SetEntity} from "common/types/index.ts";
import {useGame} from "../../routes/game";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";

export type EditorCharacterOwnersInputProps = {
  value: SetEntity<UserID>;
};

export function OwnersField({value}: EditorCharacterOwnersInputProps) {
  const gameRef = useGame();
  const players: {[userId: UserID]: Player | Owner} = useComputedValue(gameRef, (game: Game) => ({
    [game.owner.id]: game.owner,
    ...game.players
  }));

  const ownerIDs = useRefValue(value);
  const options: UserID[] = [
    "GLOBAL" as UserID,
    ...ownerIDs.filter(ownerID => ownerID !== "GLOBAL"),
    ...(Object.keys(players).filter(playerID => !ownerIDs.includes(playerID as UserID)) as UserID[])
  ];

  return (<div className="shrink-0 flex flex-col py-2">
    <div className="flex flex-col gap-1">
      {options.map((playerID) => {
        return (<InputGroup className="mx-2 rounded-md pl-0 overflow-hidden" key={playerID}>
          <InputGroup className="px-4 flex items-center justify-center">
            <Checkbox checked={ownerIDs.includes(playerID)} onChange={(ev) => {
              const checked = ev.target.checked;
              value.apply(_ => checked
                ? [{type: "insert", item: playerID as UserID}]
                : [{type: "delete", item: playerID as UserID}]
              );
            }} />
          </InputGroup>
          <InputGroupLabel>{playerID === "GLOBAL" ? "Global" : (players[playerID]?.name ?? "Unknown")}</InputGroupLabel>
        </InputGroup>);
      })}
    </div>
  </div>);
}