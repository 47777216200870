import {useIsElementAccessible, VisibilityProvider} from "../common/context/visibility-context.ts";
import {Vision, VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {Framebuffer} from "../common/shader/framebuffer.tsx";
import {ClearCanvas} from "../../../routes/game/view/main/clear-canvas.tsx";
import {BackgroundShader} from "../common/shader/background-shader.tsx";
import {ElementRenderPass} from "../common/render-pass/element";
import {RenderPassTextureProvider} from "../common/context/render-pass-texture-context.ts";
import {LightElementLightPass} from "../common/light-pass/element/light/light-element-light-pass.tsx";
import {ElementLightPass} from "../common/light-pass/element/element-light-pass.tsx";
import {ElementHUDPass} from "../common/hud-pass/element/element-h-u-d-pass.tsx";
import {WallShader} from "../common/shader/wall-shader.tsx";
import {Color, HSLA, Point} from "common/types/generic/index.ts";
import {TextureShader} from "../common/shader/texture-shader.tsx";
import React, {useCallback, useMemo, useState} from "react";
import {Node} from "common/legends/node/index.ts";
import {useResolution} from "#lib/gl-react/hooks/resolution-context.ts";
import {useRenderingContext, useTexture2D} from "#lib/gl-react/index.ts";
import {useFramebuffer} from "#lib/gl-react/hooks/use-framebuffer.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {GrayscaleTextureShader} from "../common/shader/grayscale-texture-shader.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {VisionLimitShader} from "../common/shader/vision-limit-shader.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useNodeWalls} from "./scene-view.tsx";
import {useRootNodes} from "../common/context/root-context.ts";

export function VisionFramebuffer({vision, children, backgroundColor, globalLighting, origin}: {
  vision: Vision,
  origin?: Point,
  backgroundColor: HSLA,
  globalLighting: LightNode,
  children: Node[]
}) {
  const size = useResolution();
  const context = useRenderingContext();
  const diffuseTexture = useTexture2D();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.TEXTURE_BINDING_2D);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, diffuseTexture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, prev);
    }
  }, [diffuseTexture, size]);

  const normalTexture = useTexture2D();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.TEXTURE_BINDING_2D);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, normalTexture);
      context.texImage2D(WebGL2RenderingContext.TEXTURE_2D, 0, WebGL2RenderingContext.RGBA, size[0], size[1], 0, WebGL2RenderingContext.RGBA, WebGL2RenderingContext.UNSIGNED_BYTE, null);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MIN_FILTER, WebGL2RenderingContext.LINEAR);
      context.texParameteri(WebGL2RenderingContext.TEXTURE_2D, WebGL2RenderingContext.TEXTURE_MAG_FILTER, WebGL2RenderingContext.LINEAR);
      context.bindTexture(WebGL2RenderingContext.TEXTURE_2D, prev);
    }
  }, [normalTexture, size]);

  const framebuffer = useFramebuffer();
  useMemo(() => {
    if (size[0] > 0 && size[1] > 0) {
      const prev = context.getParameter(WebGL2RenderingContext.FRAMEBUFFER_BINDING);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, framebuffer);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT0, WebGL2RenderingContext.TEXTURE_2D, diffuseTexture, 0);
      context.framebufferTexture2D(WebGL2RenderingContext.FRAMEBUFFER, WebGL2RenderingContext.COLOR_ATTACHMENT1, WebGL2RenderingContext.TEXTURE_2D, normalTexture, 0);
      context.drawBuffers([context.COLOR_ATTACHMENT0, context.COLOR_ATTACHMENT1]);
      context.bindFramebuffer(WebGL2RenderingContext.FRAMEBUFFER, prev);
    }
  }, [framebuffer, diffuseTexture, normalTexture, size]);

  const isElementVisible = useCallback((element: Node) => VisionFn.canSee(vision, element), [vision.accessMask, vision.visibilityNodeIDs])
  const [sceneTexture, setSceneTexture] = useState<WebGLTexture | null>(null);
  const [visionTexture, setVisionTexture] = useState<WebGLTexture | null>(null);

  const renderPassTextures = useMemo((): [WebGLTexture, WebGLTexture] => [diffuseTexture, normalTexture], [diffuseTexture, normalTexture]);
  const point = origin ? Vector2.add(origin, vision.offset) : undefined;
  const walls = useRefValue(useNodeWalls(
    useRootNodes(),
    isElementVisible,
    useIsElementAccessible()
  ));
  return (<VisibilityProvider value={isElementVisible}>
    <framebuffer value={framebuffer}>
      <viewport x={0} y={0} width={size[0]} height={size[1]}/>
      <action onAction={(context) => {
        context.clearColor(0, 0, 0, 1);
        context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
      }}/>
      <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.FUNC_ADD}>
        <BlendFuncSeperate
          srcRGB={WebGL2RenderingContext.SRC_ALPHA} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA}
          srcAlpha={WebGL2RenderingContext.ZERO} dstAlpha={WebGL2RenderingContext.ONE}>
          <BackgroundShader color={backgroundColor}/>
          {[...children].reverse().map((child) => <ElementRenderPass key={child.data.id} value={child}/>)}
        </BlendFuncSeperate>
      </BlendEquationSeparate>
    </framebuffer>

    <Framebuffer ref={setSceneTexture}>
      <ClearCanvas width={size[0]} height={size[1]}/>
      <action onAction={(context) => {
        context.clearColor(0, 0, 0, 0);
        context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
      }}/>
      <RenderPassTextureProvider value={renderPassTextures}>
        <LightElementLightPass value={globalLighting}/>
        <binder>
          {[...children].reverse().map((element) => <ElementLightPass key={element.data.id} value={element}/>)}
        </binder>
      </RenderPassTextureProvider>
    </Framebuffer>

    <Framebuffer ref={setVisionTexture}>
      <ClearCanvas width={size[0]} height={size[1]}/>
      <action onAction={(context) => {
        context.clearColor(0, 0, 0, 0);
        context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
      }}/>

      <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.FUNC_ADD}>
        <BlendFuncSeperate
          srcRGB={WebGL2RenderingContext.ONE} dstRGB={WebGL2RenderingContext.ZERO}
          srcAlpha={WebGL2RenderingContext.ONE} dstAlpha={WebGL2RenderingContext.ZERO}>
          {sceneTexture && <>
            {vision.grayscale
              ? <GrayscaleTextureShader texture={sceneTexture}/>
              : <TextureShader texture={sceneTexture}/>}
          </>}
        </BlendFuncSeperate>
      </BlendEquationSeparate>

      <binder>
        {[...children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element}/>)}
      </binder>

      {point && <BlendEquationSeparate rgb={WebGL2RenderingContext.MIN} alpha={WebGL2RenderingContext.MIN}>
          <BlendFuncSeperate
              srcRGB={WebGL2RenderingContext.ONE} dstRGB={WebGL2RenderingContext.ONE}
              srcAlpha={WebGL2RenderingContext.ONE} dstAlpha={WebGL2RenderingContext.ONE}>
              <WallShader origin={point} color={Color.CLEAR} walls={walls} />
            {vision.limit && <VisionLimitShader origin={point} value={vision.limit}/>}
          </BlendFuncSeperate>
      </BlendEquationSeparate>}
    </Framebuffer>

    {visionTexture && <TextureShader texture={visionTexture} />}
  </VisibilityProvider>);
}