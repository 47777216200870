import {Sheet, SheetOperation, TokenNode} from "common/legends/index.ts";
import {useMemo} from "react";
import {distinct, from, map} from "common/observable";
import {pipe} from "common/pipe";
import {useInstance} from "#lib/qlab/index.ts";
import {MutableRef, NOOP_SIGNAL, Ref} from "common/ref";
import {Optional} from "common/types/index.ts";
import {useObservableRef} from "#lib/signal/react/use-observable-ref.ts";
import {SheetReference, SheetReferenceFn} from "../../../common/sheet/sheet-reference.ts";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";

export function useTokenSheetReference(value: TokenNode): Ref<Optional<SheetReference>> {
  const instance = useInstance();
  const sheet = value.tokenSheets[value.tokenReference.tokenID];
  return useObservableRef(useMemo(() => pipe(
    from(sheet),
    map((sheet): SheetReference | undefined => {
      if (sheet?.type === "link") return {type: "link", assetID: value.tokenReference.assetID, sheetID: sheet.data};
      else if (sheet?.type === "copy") return {type: "copy", nodeID: value.id, tokenID: value.tokenReference.tokenID};
      return undefined;
    }),
    distinct(SheetReferenceFn.equals)
  ), [instance, sheet])) || NOOP_SIGNAL;
}

export function useTokenSheet(value: TokenNode): MutableRef<Optional<Sheet>, SheetOperation[]> {
  const sheetReference = useTokenSheetReference(value);
  return useSheetSignal(sheetReference);
}
