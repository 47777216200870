import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {CameraElement} from "common/legends/node/camera-element.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useIsSelected} from "../../viewport/common/context/use-is-selected.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/hud-pass/element/selection-indicator.tsx";
import {CircleShader} from "../../viewport/common/shader/circle-shader.tsx";

export function CameraElementHUDPass({value: {id, children, transform, size, origin, pivot}}: {
  value: CameraElement;
}) {
  const {projection, view, model} = usePVM();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsSelected(id);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
      {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={origin} size={size} color={Color.WHITE} repeatX={1} repeatY={1} />}
      {isSelected && <>
        <CircleShader origin={[origin[0] - pivot[0]+2, origin[1] - pivot[1]+2]} size={[4,4]} color={Color.BLACK75} />
        <CircleShader origin={[origin[0] - pivot[0]+1, origin[1] - pivot[1]+1]} size={[2,2]} color={Color.GREEN} />
      </>}
    </ModelProvider>
  </binder>);
}