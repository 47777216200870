import {NodeTurn, NodeTurnOperation, NodeTurnSignals} from "common/legends/game/turn-tracker/index.ts";
import {SheetInterface} from "common/legends/index.ts";
import {InputNumber, useToggle} from "#lib/components/index.ts";
import {useMemo} from "react";
import {twMerge} from "tailwind-merge";
import {useNodeSheetReference} from "../../../viewport/token/use-node.ts";
import {isTokenElementOwner} from "../../../common/node/use-is-party-node.ts";
import {NodeIcon} from "../editor/turn/node-icon.tsx";
import {CONDITION_IMAGE} from "../../sheet/editor/dnd-5e/dnd-5e-condition/dnd-5e-conditions-view.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {useGlobalFeatures} from "../../sheet/editor/dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {fromSignal, MutableRef} from "common/ref";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";
import {useComputed} from "#lib/signal/react/use-computed.ts";
import {useAccess} from "../../../../routes/game/model/store-context.tsx";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {useCurrentStage} from "../../../viewport/token/use-current-stage.ts";

export type PlayerNodeTurnViewProps = {
  nodeTurnRef: MutableRef<NodeTurn, NodeTurnOperation[]>;
  isCurrentTurn: boolean;
};
export function PlayerNodeTurnView({nodeTurnRef, isCurrentTurn}: PlayerNodeTurnViewProps) {
  const {order} = useMemo(() => NodeTurnSignals(nodeTurnRef), [nodeTurnRef]);
  const nodeTurn = useRefValue(nodeTurnRef);

  const access = useAccess();
  const nodeRef = useMemo(() => fromSignal(access.node(nodeTurn.nodeID)), [access]);
  const [editOrder, toggleEditOrder, setEditOrder] = useToggle(false);
  const sheet = useRefValue(useSheetSignal(useNodeSheetReference(nodeRef)));

  const displayName = useComputed(() => {
    if (nodeRef.value?.type !== "token") return false;
    return nodeRef.value.data.displayName;
  }, [nodeRef]);
  const displayHealthNumbers = useComputed(() => {
    if (nodeRef.value?.type !== "token") return false;
    return nodeRef.value.data.displayHealthNumbers;
  }, [nodeRef]);
  const name = useComputed(() => nodeRef.value?.data.name, [nodeRef])

  const userID = useUserID()!;
  const stage = useCurrentStage(userID);
  const isOwner = useComputed(() => {
    if (nodeRef.value?.type === "token") return false;
    if (!isTokenElementOwner(nodeRef.value, userID, stage)) return false;
  }, [nodeRef, userID, stage]);

  const globalFeatures = useRefValue(useGlobalFeatures());

  return (<div className={twMerge(
    "pointer-events-auto backdrop-blur shrink-0 flex flex-row min-w-[106px] h-8 w-full gap-0.5 rounded-l-[16px] rounded-r-lg items-center overflow-hidden",
    isCurrentTurn ? "bg-zinc-900/80" : "bg-zinc-900/50",
  )}>
    <NodeIcon nodeID={nodeTurn.nodeID} />
    {!editOrder && <div className="shrink overflow-hidden basis-6 h-8 flex items-center justify-center text-white/40 text-xs italic" onClick={isOwner ? toggleEditOrder : undefined}>
      {nodeTurn.order || "???"}
    </div>}
    {editOrder && <div className="w-20 px-2"><InputNumber value={order} autoFocus onLeaveEdit={() => setEditOrder(false)} /></div>}
    <span className="shrink-[1000] min-w-0 flex-1 inline-flex items-center overflow-hidden whitespace-nowrap">{
      displayName ? name : "??"
    }</span>

    {<div className="inline-flex shrink-[9999] overflow-hidden gap-0.5">
      {displayHealthNumbers && sheet && <div className="inline-flex shrink overflow-hidden gap-0.5">
        <div className="text-xs overflow-hidden shrink px-2">{sheet ? SheetInterface.getHealth(sheet, globalFeatures) : ""}</div>
      </div>}
      <div className="flex flex-row gap-1 shrink-[9999] overflow-hidden">{sheet?.data.conditions.map((condition) => <img key={condition} alt={condition} title={condition} className="w-4 h-4" src={CONDITION_IMAGE[condition]} />)}</div>
    </div>}
  </div>);
}
