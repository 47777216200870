import {Transform} from "common/types/index.ts";
import React from "react";
import {ElementRenderPass} from "../../viewport/common/render-pass/element";
import {WallNode} from "common/legends/node/wall-node.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";

export function WallElementRenderPass({value}: {
  value: WallNode;
}) {
  const model = useModel();
  const valueOpacity = value.opacity * useOpacity();
  let valueModel = Transform.divide(value.transform, model);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}