import {UserID} from "common/legends/user/index.ts";
import {useGame} from "../../../routes/game";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {useMemo} from "react";
import {fromSignal} from "common/ref";
import {GameSignals} from "common/legends/game/index.ts";
import {useRefValue, useSignalValue} from "#lib/signal/index.ts";
import {computed} from "common/signal";

export function useCurrentStage(playerID: UserID) {
  const gameRef = useGame();
  const access = useAccess();

  const playerRef = useMemo(() => fromSignal(access.player(playerID)), [access, playerID]);
  const {globalStageIDRef} = useMemo(() => GameSignals(gameRef), [gameRef]);
  const globalStageID = useRefValue(globalStageIDRef);

  return useSignalValue(useMemo(() => computed(() => {
    const player = playerRef.value;
    return player?.stageID ?? globalStageID;
  }), [globalStageID]));
}