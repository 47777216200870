import React, {useMemo} from "react";
import {Button, ButtonBar, InputGroup, TagListInput} from "#lib/components/index.ts";
import {AssetTreeFile, AssetTreeFileOperation, AssetTreeFileSignals} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export type EditAssetModalProps = {
  onClose: () => void;
  value: MutableRef<AssetTreeFile, AssetTreeFileOperation[]>
};

export function EditAssetModal({onClose, value}: EditAssetModalProps) {
  const {name, tags} = useMemo(() => AssetTreeFileSignals(value), [value])

  return (<form onSubmit={ev => {ev.preventDefault(); return false}}>
    <Modal onClose={onClose}>
      <ModalTitle>Edit Asset</ModalTitle>
      <ModalBody>
        <Field>
          <FieldLabel>Name</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputString autoFocus value={name} />
          </InputGroup>
        </Field>
        <Field>
          <FieldLabel htmlFor="tag">Tags</FieldLabel>
          <TagListInput value={tags} />
        </Field>
      </ModalBody>
      <ButtonBar>
        <Button type="button" className="grow" onClick={onClose}>Cancel</Button>
      </ButtonBar>
    </Modal>
  </form>)
}