import {Apply, ApplyAction} from "#common/types/apply.ts";
import {fromSignal, MutableRef} from "#common/ref";
import {valueSignal} from "#common/signal";

export function observableValue<V, O>(
  initialValue: V,
  reducer: (value: V, operation: O) => V
): [MutableRef<V, V>, Apply<V, O>, (value: V) => void] {
  let signal = valueSignal(initialValue);

  const setObservable = (nextValue: V) => signal.apply((_: V) => nextValue);
  const applyToObservable = async (fn: ApplyAction<V, O>): Promise<V> => {
    signal.apply((v: V) => reducer(v, fn(v)));
    return signal.value;
  }

  return [fromSignal(signal), applyToObservable, setObservable];
}
