import {DependencyList, useEffect, useMemo, useState} from "react";
import {Ref} from "common/ref";
import {effect, Signal} from "common/signal";

export function useSignalValue<Value>(signal: Signal<Value>): Value {
  const [value, setValue] = useState<Value>(signal.value);
  useEffect(() => effect(() => setValue(signal.value)), [signal, setValue]);
  return value;
}

export function useRefValue<Value>(ref: Ref<Value>): Value {
  const [_, setValue] = useState<Value>(ref.value);
  useEffect(() => {
    return ref.observe({
      next: (value) => setValue(value), // trigger component to re-evaluate ref.value
      error: console.error,
      complete() {}
    });
  }, [ref, setValue]);
  return ref.value; // suspending causes use-effect to not trigger, making this value never updated
}

export function useComputedValue<Value, ComputedValue>(signal: Ref<Value>, fn: (value: Value) => ComputedValue, deps?: DependencyList | undefined): ComputedValue {
  return useRefValue(useMemo(() => signal.map(fn).distinct(), deps ? [signal, ...deps] : [signal]));
}
