import {Transform} from "common/types/index.ts";
import React, {useCallback, useEffect, useMemo} from "react";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {LightShapeGlobalLightPass} from "./light-shape-global-light-pass.tsx";
import {LightShapeSpotlightLightPass} from "./light-shape-spotlight-light-pass.tsx";
import {LightShapeSpriteLightPass} from "./light-shape-sprite-light-pass.tsx";
import {LightShapeFreeformLightPass} from "./light-shape-freeform-light-pass.tsx";
import {ElementLightPass} from "../element-light-pass.tsx";
import {ModelProvider, useModel} from "../../../context/pvm-context.ts";
import {useIsElementAccessible, VisibilityProvider} from "../../../context/visibility-context.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {Node} from "common/legends/node/index.ts";
import {useIsLightElementOnScreen} from "../../../context/use-is-light-element-on-screen.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useNodeWalls} from "../../../../scene/scene-view.tsx";
import {useRootNodes} from "../../../context/root-context.ts";

function useBlink(length: number, interval: number, offset: number) {
  const [isOn, setIsOn] = React.useState(true);

  useEffect(() => {
    if (interval >= 1) {
      setIsOn(true);
    } else if (interval <= 0) {
      setIsOn(false);
    } else {
      const nextOnTime = () => length - (Date.now() - offset * length) % length;
      const turnOn = () => {
        setIsOn(true);
        timeoutA = setTimeout(turnOn, nextOnTime());
      };
      let timeoutA = setTimeout(turnOn, nextOnTime() % length);

      const nextOffTime = () => length + interval * length - (Date.now() - offset * length) % length;
      const turnOff = () => {
        setIsOn(false);
        timeoutB = setTimeout(turnOff, nextOffTime());
      };
      let timeoutB = setTimeout(turnOff, nextOffTime() % length);

      setIsOn(nextOnTime() % length >= nextOffTime() % length);

      return () => {
        clearTimeout(timeoutA);
        clearTimeout(timeoutB);
      };
    }
  }, [length, interval, offset]);

  return isOn;
}


export const LightElementLightPass = React.memo(function LightElementLightPass({value}: {
  value: LightNode;
}) {
  const model = useModel();
  let valueModel = useMemo(() => Transform.divide(value.transform, model), [value.transform, model]);
  const isElementVisible = useCallback((element: Node) => AccessMaskFn.canSee(value.accessMask ? ["ALL", value.accessMask] : ["ALL"], element.data.visibilityLayer), [value.accessMask]);
  const isOnScreen = useIsLightElementOnScreen(value, valueModel);
  const isLightOn = useBlink(value.blinkLength, value.blinkInterval, value.blinkOffset);

  const walls = useRefValue(useNodeWalls(
    useRootNodes(),
    isElementVisible,
    useIsElementAccessible()
  ));

  return (<ModelProvider value={valueModel}>
    {isLightOn && isOnScreen && <VisibilityProvider value={isElementVisible}>
      {value.shape?.type === "global" && <LightShapeGlobalLightPass value={value.shape.data} />}
      {value.shape?.type === "sprite" && <LightShapeSpriteLightPass value={value.shape.data} origin={value.origin} walls={walls} />}
      {value.shape?.type === "spotlight" && <LightShapeSpotlightLightPass value={value.shape.data} origin={value.origin} walls={walls} />}
      {value.shape?.type === "freeform" && <LightShapeFreeformLightPass value={value.shape.data} origin={value.origin} walls={walls} />}
    </VisibilityProvider>}

    <binder>
      {[...value.children].reverse().map((element) => <ElementLightPass key={element.data.id} value={element}/>)}
    </binder>
  </ModelProvider>);
});
