import {GroupNode} from "common/legends/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/hud-pass/element/selection-indicator.tsx";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useIsSelected} from "../../viewport/common/context/use-is-selected.ts";

export function GroupElementHUDPass({value}: {
  value: GroupNode;
}) {
  const {projection, view, model} = usePVM();
  const valueOpacity = value.opacity * useOpacity();
  const valueModel = Transform.divide(value.transform, model);
  const isSelected = useIsSelected(value.id);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {[...value.children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
        {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={value.origin} size={value.size} color={Color.WHITE} repeatX={1} repeatY={1} />}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}