import {z} from "zod";
import {ulid} from "ulid";

export const StageID = z.string();
export type StageID = z.infer<typeof StageID>;

export const ALL_STAGES = "ALL";

export function generateStageID() {
  return ulid();
}

