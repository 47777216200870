import {ExpandableLabel, IconButton, InputGroup, InputGroupLabel, useToggle} from "#lib/components/index.ts";
import {FaSignOutAlt, FaTag} from "react-icons/fa";
import {Player, PlayerOperation, PlayerRef} from "common/legends/index.ts";
import {useMemo} from "react";
import {InputString} from "#lib/components/input/input-string.tsx";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputIcon} from "#lib/components/input/input-icon.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {MutableRef} from "common/ref";
import {Field, FieldLabel, SectionHeader} from "#lib/components/panel-header.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {PlayerStageIDField} from "../../common/stage/player-stage-i-d-field.tsx";

export type GamePlayerViewProps = {
  value: MutableRef<Player, PlayerOperation[]>;
  remove?: () => void;
};

export function GamePlayerView({value, remove}: GamePlayerViewProps) {
  const {nameRef, iconRef, colorRef, stageIDRef, gameMasterRef} = useMemo(() => PlayerRef(value), [value]);
  const [expanded, toggleExpand] = useToggle(false);

  return (<div className="flex flex-col gap-0 bg-zinc-800/50 mx-2 rounded-md overflow-hidden">
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpand}>
      <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpand}>{useRefValue(nameRef)}</SectionHeader>
      <IconButton disabled={!remove} variant="destructive" onClick={remove}><FaSignOutAlt /></IconButton>
    </ExpandableLabel>
    {expanded && <Fieldset>
      <Field>
        <FieldLabel>Name</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden">
          <InputIcon><FaTag /></InputIcon>
          <InputString value={nameRef} />
        </InputGroup>
      </Field>
      <InputGroup className="mx-2 rounded-md overflow-hidden pl-0">
        <InputCheckbox value={gameMasterRef} />
        <InputGroupLabel>Game Master</InputGroupLabel>
      </InputGroup>
      <Field>
        <FieldLabel>Icon</FieldLabel>
        <InputFile placeholder="Image" value={iconRef} />
      </Field>
      <ColorField label="Color" title="Player Color" value={colorRef} />
      <Field>
        <FieldLabel>Player Stage</FieldLabel>
        <div className="mx-2 rounded-md overflow-hidden flex flex-col gap-0.5">
          <PlayerStageIDField valueRef={stageIDRef}/>
        </div>
      </Field>
    </Fieldset>}
  </div>);
}
