import {MutableRef} from "common/ref";
import {StageID} from "common/legends/stage/stage-i-d.ts";
import {BooleanOperation, ConstantOperation, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useGame} from "../../../routes/game";
import {useMemo} from "react";
import {GameSignals} from "common/legends/game/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Stage} from "common/legends/stage/stage.ts";

function PlayerStageIDRadioListItem({
  stage,
  valueRef
}: {
  stage: Stage,
  valueRef: MutableRef<StageID | undefined, ValueOperation<StageID | undefined, ConstantOperation>[]>
}) {
  const itemRef = useMemo(() => valueRef.map<boolean, BooleanOperation[]>(
    value => value === stage.stageID,
    (value, _) => ValueFn.set(value, stage.stageID)
  ), [valueRef, stage.stageID]);

  return <InputGroup key={stage.stageID} className="pl-0">
    <InputCheckbox type="radio" value={itemRef} />
    <InputGroupLabel>{stage.name}</InputGroupLabel>
  </InputGroup>
}

export function PlayerStageIDField({valueRef}: {valueRef: MutableRef<StageID | undefined, ValueOperation<StageID | undefined, ConstantOperation>[]>}) {
  const gameRef = useGame();
  const {stagesRef} = useMemo(() => GameSignals(gameRef), [gameRef]);
  const stages = useRefValue(stagesRef);

  const allRef = useMemo(() => valueRef.map<boolean, BooleanOperation[]>(
    value => value === undefined,
    (value, _) => ValueFn.set(value, undefined)
  ), [valueRef]);

  return <>
    <InputGroup className="pl-0">
      <InputCheckbox type="radio" value={allRef} />
      <InputGroupLabel>Global</InputGroupLabel>
    </InputGroup>
    {stages.map(stage => <PlayerStageIDRadioListItem key={stage.stageID} stage={stage} valueRef={valueRef} />)}
  </>
}