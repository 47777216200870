import {twMerge} from "tailwind-merge";
import React, {HTMLAttributes, useMemo} from "react";
import {Color} from "common/types/index.ts";
import {PlayerRef, UserID} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import {useIsPlayerOnline} from "../../../common/game/use-is-player-online.ts";
import {usePlayer} from "../../../../routes/game/use-player.ts";
import {NodeIcon} from "../../../common/node-icon.tsx";
import {FaCheck} from "react-icons/fa6";
import {FaMinus} from "react-icons/fa";

export type PlayerIconProps = HTMLAttributes<HTMLDivElement> & {
  userID: UserID;
};

export function PlayerIcon({userID, ...props}: PlayerIconProps) {
  const isPlayerOnline = useIsPlayerOnline(userID);

  const player = usePlayer(userID);
  const {colorRef} = useMemo(() => PlayerRef(player), [player]);
  const resolvedColor = useObservable(colorRef.observe, undefined, [colorRef.observe]);
  const backgroundColor = resolvedColor && `#${Color.hslToHex([resolvedColor[0], resolvedColor[1], resolvedColor[2]])}`;

  return (<div className={"w-10 h-10 inline-flex relative cursor-pointer rounded-full items-center justify-center"} style={{backgroundColor: backgroundColor}} {...props}>
    <NodeIcon className={twMerge(
      "w-9 h-9",
      !isPlayerOnline && "grayscale opacity-80"
    )} userID={userID}/>
    <div title={isPlayerOnline ? "Online" : "Offline"} className={twMerge(
      "flex text-black items-center justify-center w-3 h-3 rounded-full absolute bottom-0 right-0 ring-offset-0 ring-1 ring-black",
      isPlayerOnline ? "bg-green-500" : "bg-gray-500"
    )}>
      {isPlayerOnline && <FaCheck size={6} />}
      {!isPlayerOnline && <FaMinus size={6} />}
    </div>
  </div>);
}