import {PointSignals, Transform, TransformOperation, TransformSignals} from "common/types/index.ts";
import {ExpandableLabel, InputGroup, InputNumber, useToggle} from "#lib/components/index.ts";
import React, {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel, SectionHeader} from "#lib/components/panel-header.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export type TransformFieldProps = {
  value: MutableRef<Transform, TransformOperation[]>;
};

export function TransformField({value}: TransformFieldProps) {
  const {position, scale, rotation} = useMemo(() => TransformSignals(value), [value]);
  const {x, y} = useMemo(() => PointSignals(position), [position]);

  const [expand, toggleExpand] = useToggle(false);
  return (<div className="mx-2 rounded-md overflow-hidden gap-0 bg-zinc-800/50">
    <ExpandableLabel className="w-full" expanded={expand} toggleExpand={toggleExpand}>
      <SectionHeader onClick={toggleExpand} className="flex-1 cursor-pointer bg-zinc-800/50">Transform</SectionHeader>
    </ExpandableLabel>
    {expand && <Fieldset>
      <Field>
        <FieldLabel>Position</FieldLabel>
        <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
          <InputGroup className="flex-1">
            <InputGroupIcon>X</InputGroupIcon>
            <InputNumber value={x} />
          </InputGroup>
          <InputGroup className="flex-1">
            <InputGroupIcon>Y</InputGroupIcon>
            <InputNumber value={y} />
          </InputGroup>
        </div>
      </Field>
      <Field>
        <FieldLabel>Rotation</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden">
          <InputNumber value={rotation} />
        </InputGroup>
      </Field>
      <Field>
        <FieldLabel>Scale</FieldLabel>
        <InputGroup className="mx-2 rounded-md overflow-hidden">
          <InputNumber value={scale} />
        </InputGroup>
      </Field>
    </Fieldset>}
  </div>);
}
