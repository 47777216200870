import {PointShader} from "../../shader/point-shader.tsx";
import {Color, Transform} from "common/types/generic/index.ts";
import {LineShader} from "../../shader/line-shader.tsx";
import {MeasureLineShader} from "../../shader/measure-line-shader.tsx";
import {Grid} from "common/legends/scene/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {Vector2f} from "#lib/math/index.ts";
import {PathMeasurement} from "common/legends/measurement/index.ts";
import {usePVM} from "../../context/pvm-context.ts";
import {useGetGrid} from "../../../../panel/properties/use-get-grid.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../../../panel/nav/editor/state/selection-ref.ts";
import {useMemo} from "react";
import {useGrid} from "../../context/grid-context.ts";

export function PathMeasurementView({rootRef, measurement: {nodes, nodeID, color}}: {
  rootRef: SceneSelectionRef | AssetTokenSelectionRef;
  measurement: PathMeasurement
}) {
  const {projection, view} = usePVM();
  const getGrid = useGetGrid();

  const defaultGrid = useGrid();
  const nodeRef = useMemo((): NodeSelectionRef | undefined => {
    if (nodeID === undefined) return undefined;
    if (rootRef.type === "scene") return {type: "scene-node", resourceId: rootRef.resourceId, nodeId: nodeID};
    if (rootRef.type === "asset-token") return {type: "asset-token-node", resourceId: rootRef.resourceId, tokenId: rootRef.tokenId, nodeId: nodeID};
  }, [rootRef, nodeID]);
  const grid = nodeRef ? getGrid(nodeRef) : defaultGrid;

  if (nodes.length === 0) return <></>;
  let distance = 0;
  const lineChildren = [
    <PointShader key={0} projection={projection} view={view} model={Transform.DEFAULT} origin={nodes[0]} scale={1 / view.scale} color={Color.BLACK75} />
  ];
  const distanceChildren = [];
  let lastNode = nodes[0];

  for (let i = 1; i < nodes.length; i ++) {
    let node = nodes[i];
    lineChildren.push(<PointShader key={i*3} projection={projection} view={view} model={Transform.DEFAULT} origin={node} scale={1 / view.scale} color={Color.BLACK75} />);
    if (nodeID) lineChildren.push(<LineShader origin={[0, 0]} key={i*3+1} start={lastNode} end={node} scale={1 / view.scale} color={color} />);
    else lineChildren.push(<MeasureLineShader key={i*3+1} projection={projection} view={view} model={Transform.DEFAULT} start={lastNode} end={node} scale={1 / view.scale} color={color} />);

    distance += Grid.distance(grid, lastNode, node);
    if (i + 1 !== nodes.length) {
      distanceChildren.push(
        <TextShader key={i*3+2} projection={projection} view={view} model={{
          position: node,
          scale: 1 / view.scale,
          rotation: -view.rotation
        }} value={`${Math.trunc(distance*100)/100}`} size={24} />
      );
    } else {
      distanceChildren.push(
        <TextShader key={i*3+2} projection={projection} view={view} model={{
          position: Vector2f.add(
            node,
            Vector2f.rotate([0, 16 / view.scale], -view.rotation / 180 * Math.PI)
          ),
          scale: 1 / view.scale,
          rotation: -view.rotation
        }} hTextAlign="center" vTextAlign="bottom" value={`${Math.trunc(distance*100)/100}`} size={64} />
      );
    }
    lastNode = node;
  }

  return <>
    {lineChildren}
    {distanceChildren}
  </>
}