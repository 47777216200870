import {QLabDatabaseSignal} from "#common/qlab/index.ts";
import {ResourceCache} from "./resource/resource-cache.ts";
import {SceneCache} from "./resource/scene/scene-cache.ts";
import {SceneIDsCache} from "./resource/scene/scene-ids-cache.ts";
import {SheetCache} from "./resource/sheet-cache.ts";
import {AssetCache} from "./resource/asset/asset-cache.ts";
import {AssetTokenCache} from "./resource/asset/asset-token-cache.ts";
import {NodeCache} from "./resource/node-cache.ts";
import {MessageCache} from "#common/legends/access/message/message-cache.ts";
import {PlayersCache} from "#common/legends/access/store/players-cache.ts";

export class LegendsAccess {
  public readonly resource;
  public readonly scene;
  public readonly sceneIDs;
  public readonly sheet;
  public readonly asset;
  public readonly assetToken;
  public readonly node;
  public readonly message;
  public readonly player;
  constructor(database: QLabDatabaseSignal) {
    this.resource = ResourceCache(database);
    this.scene = SceneCache(this.resource);
    this.sceneIDs = SceneIDsCache(database);
    this.asset = AssetCache(this.resource);
    this.assetToken = AssetTokenCache(this.asset);
    this.node = NodeCache(database);
    this.sheet = SheetCache(this.asset, this.node);
    this.message = MessageCache(database);
    this.player = PlayersCache(database);
  }
}
