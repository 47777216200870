import React, {useMemo} from 'react';
import {UserHomePage} from "./user-home/index.ts";
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {GameRoute} from "./game/index.ts";
import {JoinRoute} from "./game/join/join-route.tsx";
import {AuthProvider} from "#lib/auth/auth-provider.tsx";
import {DndProvider} from "react-dnd-multi-backend";
import {HTML5toTouch} from "rdndmb-html5-to-touch";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {PrimeReactProvider} from "primereact/api";
import {twMerge} from 'tailwind-merge';
import {DefaultTheme} from "./theme/default-theme.ts";

function App() {
  const queryClient = useMemo(() => new QueryClient(), []);
  return (
    <PrimeReactProvider value={{unstyled: true, pt: DefaultTheme, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge }}}>
      <QueryClientProvider client={queryClient}>
        <DndProvider options={HTML5toTouch}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/" element={<UserHomePage />} />
                <Route path="/game/:gameID">
                  <Route path="join/:code" element={<JoinRoute />} />
                  <Route index element={<GameRoute />} />
                </Route>
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </DndProvider>
      </QueryClientProvider>
    </PrimeReactProvider>
  );
}

export default App;
