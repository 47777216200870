import {useArrayBuffer, useAttribLocation, useBindVertexArribArray, useProgram, useShader, useUniformLocation, useVertexBuffer} from "#lib/gl-react/index.ts";
import React, {useMemo} from "react";
import {Color, Point} from "common/types/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {Line} from "../../scene/scene-view.tsx";
import {Vector2} from "common/math/vector/vector2.ts";
import {usePVM} from "../context/pvm-context.ts";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";

const vertexShader = `#version 300 es
precision highp float;
in vec2 a_position;
in vec4 a_tint;
uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec4 v_tint;

void main()
{
  gl_Position = u_projection * u_view * vec4(a_position, 0, 1);
  v_tint = a_tint;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

in vec4 v_tint;
out vec4 outColor;
void main() {
  outColor = vec4(v_tint.rgb, 1.0);
}
`;

export const WallTintShader = React.memo(function WallTintShader({origin, walls}: {
  origin: Point,
  walls: Line[]
}) {
  const {projection, view, model} = usePVM();
  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );

  const vboArray = useMemo((): Float32Array => {
    const invertViewTransform = Matrix4f.invert(Matrix4f.transform(view));
    const matrixTransform = Matrix4f.multiplyMatrix(invertViewTransform, Matrix4f.invert(projection));
    const topLeft = Vector2.multiplyMatrix4x4([-1, 1], matrixTransform);
    const topRight = Vector2.multiplyMatrix4x4([1, 1], matrixTransform);
    const bottomLeft = Vector2.multiplyMatrix4x4([-1, -1], matrixTransform);
    const bottomRight = Vector2.multiplyMatrix4x4([1, -1], matrixTransform);

    const o = Vector2.multiplyTransform(origin, model);

    const floats: number[] = [];
    for (const {start, end, viewLeft, viewRight, tint} of walls) {
      const s = start;
      const e = end;
      const t = Color.toRGBA(tint);

      const left = Vector2.cross(Vector2.subtract(e, o), Vector2.subtract(s, o)) < 0;
      if (left && !viewLeft) continue;
      if (!left && !viewRight) continue;

      const leftStartT = Vector2.lineIntersect(o, s, bottomLeft, topLeft);
      const leftEndT = Vector2.lineIntersect(o, e, bottomLeft, topLeft);
      const rightStartT = Vector2.lineIntersect(o, s, topRight, bottomRight);
      const rightEndT = Vector2.lineIntersect(o, e, topRight, bottomRight);

      if (leftStartT > rightStartT && leftEndT > rightEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, leftStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
        floats.push(...s, ...t, ...e, ...t, ...startIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startIntersect, ...t);
        continue;
      }

      const topStartT = Vector2.lineIntersect(o, s, topLeft, topRight);
      const topEndT = Vector2.lineIntersect(o, e, topLeft, topRight);
      const bottomStartT = Vector2.lineIntersect(o, s, bottomLeft, bottomRight);
      const bottomEndT = Vector2.lineIntersect(o, e, bottomLeft, bottomRight);
      if (topStartT > bottomStartT && topEndT > bottomEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT));
        floats.push(...s, ...t, ...e, ...t, ...startIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startIntersect, ...t);
        continue;
      }

      if (rightStartT > leftStartT && rightEndT > leftEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, rightStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
        floats.push(...s, ...t, ...e, ...t, ...startIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startIntersect, ...t);
        continue;
      }
      if (bottomStartT > topStartT && bottomEndT > topEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT));
        floats.push(...s, ...t, ...e, ...t, ...startIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startIntersect, ...t);
        continue;
      }

      if (leftStartT > rightStartT && bottomStartT > topStartT && rightEndT > leftEndT && topEndT > bottomEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT, leftStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT, rightEndT));
        const startEndIntersect = left ? bottomRight : topLeft;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }
      if (leftEndT > rightEndT && bottomEndT > topEndT && rightStartT > leftStartT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT, rightStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT, leftEndT));
        const startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }
      if (rightEndT > leftEndT && bottomEndT > topEndT && leftStartT > rightStartT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT, leftStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT, rightEndT));
        const startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }
      if (leftEndT > rightEndT && topEndT > bottomEndT && rightStartT > leftStartT && bottomStartT > topStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT, rightStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT, leftEndT));
        let startEndIntersect = left ? topRight : bottomLeft;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }

      if (topEndT > bottomEndT && rightStartT < leftStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, leftStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT));
        let startEndIntersect = left ? bottomRight : topLeft;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (topEndT > bottomEndT && rightStartT > leftStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, rightStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, topEndT));
        let startEndIntersect = left ? topRight : bottomLeft;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (topEndT < bottomEndT && rightStartT < leftStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, leftStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT));
        let startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (topEndT < bottomEndT && rightStartT > leftStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, rightStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, bottomEndT));
        let startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }

      if (leftEndT > rightEndT && topStartT < bottomStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
        let startEndIntersect = left ? topRight : bottomLeft;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (leftEndT > rightEndT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
        let startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (leftEndT < rightEndT && topStartT < bottomStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, bottomStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
        let startEndIntersect = left ? bottomRight : topLeft;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (leftEndT < rightEndT && topStartT > bottomStartT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
        let startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }

      if (topStartT > bottomStartT && rightEndT > leftEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, rightEndT));
        let startEndIntersect = left ? bottomLeft : topRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      } else if (topStartT > bottomStartT && rightEndT < leftEndT) {
        const startIntersect = Vector2.lerp(o, s, Math.max(1, topStartT));
        const endIntersect = Vector2.lerp(o, e, Math.max(1, leftEndT));
        let startEndIntersect = left ? topLeft : bottomRight;
        floats.push(...s, ...t, ...e, ...t, ...startEndIntersect, ...t);
        floats.push(...s, ...t, ...startIntersect, ...t, ...startEndIntersect, ...t);
        floats.push(...e, ...t, ...endIntersect, ...t, ...startEndIntersect, ...t);
        continue;
      }
    }
    return new Float32Array(floats);
  }, [projection, view, model, origin, walls]);

  const vbo = useArrayBuffer(vboArray);
  const vao = useVertexBuffer();
  useBindVertexArribArray(vao, useAttribLocation(program, "a_position"), vbo, 2, WebGL2RenderingContext.FLOAT, false, 6 * 4, 0);
  useBindVertexArribArray(vao, useAttribLocation(program, "a_tint"), vbo, 4, WebGL2RenderingContext.FLOAT, false, 6 * 4, 2 * 4);

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);

  return (
    <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.FUNC_ADD}>
      <BlendFuncSeperate srcRGB={WebGL2RenderingContext.ZERO} dstRGB={WebGL2RenderingContext.SRC_COLOR} srcAlpha={WebGL2RenderingContext.ZERO} dstAlpha={WebGL2RenderingContext.SRC_COLOR}>
        <program value={program}>
        <uniformMat4fv location={useUniformLocation(program, "u_projection")} transpose={true} data={projectionMatrix4f}/>
        <uniformMat4fv location={useUniformLocation(program, "u_view")} transpose={true} data={viewMatrix4f}/>
        <vertexArray value={vao}>
          <drawArrays mode={WebGL2RenderingContext.TRIANGLES} first={0} count={vboArray.length / 6}/>
        </vertexArray>
      </program>
    </BlendFuncSeperate>
  </BlendEquationSeparate>);
});
