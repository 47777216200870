import React from "react";
import {Transform} from "common/types/index.ts";
import {ElementRenderPass} from "../element-render-pass.tsx";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";

export function LightElementRenderPass({value: {children, transform}}: {
  value: LightNode;
}) {
  const model = useModel();
  const valueModel = Transform.divide(transform, model);
  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
    </ModelProvider>
  </binder>);
}