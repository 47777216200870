import {MutableRef} from "common/ref";
import {StageID} from "common/legends/stage/stage-i-d.ts";
import {BooleanOperation, booleanType, SetFn, SetOperation} from "common/types/generic/index.ts";
import {useGame} from "../../routes/game";
import {useMemo} from "react";
import {GameSignals} from "common/legends/game/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {applyAll} from "common/types/type/index.ts";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Field} from "#lib/components/panel-header.tsx";
import {Stage} from "common/legends/stage/stage.ts";

function StageIDCheckboxListItem({
  stage,
  valueRef
}: {
  stage: Stage,
  valueRef: MutableRef<StageID[], SetOperation<StageID>[]>
}) {
  const gameRef = useGame();
  const {stagesRef} = useMemo(() => GameSignals(gameRef), [gameRef])

  const itemRef = useMemo(() => valueRef.map<boolean, BooleanOperation[]>(
    value => value.includes("ALL") || value.includes(stage.stageID),
    (value, operations) => {
      const prev = value.includes("ALL") || value.includes(stage.stageID);
      const next = applyAll(booleanType, prev, operations);
      if (next) {
        if (value.includes("ALL")) return [];
        return value.includes(stage.stageID) ? [] : SetFn.insert(stage.stageID);
      } else {
        if (value.includes("ALL")) {
          let remainingStages = stagesRef.value
            .map(stage => stage.stageID)
            .filter(stageID => stageID !== stage.stageID);
          return SetFn.set(value,  remainingStages);
        } else {
          return SetFn.delete(stage.stageID);
        }
      }
    }
  ), [stagesRef, valueRef, stage.stageID]);

  return <InputGroup key={stage.stageID} className="mx-2 rounded-md overflow-hidden pl-0">
    <InputCheckbox value={itemRef} />
    <InputGroupLabel>{stage.name}</InputGroupLabel>
  </InputGroup>
}

export function StagesField({valueRef}: {valueRef: MutableRef<StageID[], SetOperation<StageID>[]>}) {
  const gameRef = useGame();
  const {stagesRef} = useMemo(() => GameSignals(gameRef), [gameRef]);
  const stages = useRefValue(stagesRef);

  const allRef = useMemo(() => valueRef.map<boolean, BooleanOperation[]>(
    value => value.includes("ALL"),
    (value, operations) => {
      const prev = value.includes("ALL");
      const next = applyAll(booleanType, prev, operations);
      if (prev === next) return [];
      return next
        ? SetFn.set(value, ["ALL"])
        : SetFn.set(value, stagesRef.value.map(s => s.stageID));
    }
  ), [valueRef]);

  return <Field className="gap-1">
    <InputGroup className="mx-2 rounded-md overflow-hidden pl-0">
      <InputCheckbox value={allRef} />
      <InputGroupLabel>All</InputGroupLabel>
    </InputGroup>
    {stages.map(stage => <StageIDCheckboxListItem key={stage.stageID} stage={stage} valueRef={valueRef} />)}
  </Field>
}