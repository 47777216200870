import {useCallback} from "react";
import {NodeSelectionRef} from "../nav/editor/state/selection-ref.ts";
import {Grid} from "common/legends/scene/index.ts";
import {Tree, TreePath} from "common/types/generic/index.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {Node} from "common/legends/node/index.ts";

export function useGetGrid() {
  const access = useAccess();
  return useCallback((nodeRef: NodeSelectionRef): Grid => {
    const getGridByPath = (children: Node[], path: TreePath): Grid | undefined => {
      for (let i = path.length - 1; i >= 1; i --) {
        const nodePath = path.slice(0, i);
        const node = Tree.getItemByPath(children, nodePath);
        if (node.type === "token") {
          const assetToken = access.assetToken(
            node.data.tokenReference.assetID,
            node.data.tokenReference.tokenID
          ).value;
          if (assetToken === undefined) continue;
          return assetToken.grid;
        }
      }
      return undefined;
    }

    if (nodeRef.type === "scene-node") {
      const scene = access.scene(nodeRef.resourceId).value;
      if (scene === undefined) return Grid.DEFAULT;
      const path = Tree.getPath(scene.children, node => node.data.id === nodeRef.nodeId);
      if (path === undefined) return scene.grid;
      return getGridByPath(scene.children, path) ?? scene.grid;
    } else if (nodeRef.type === "asset-token-node") {
      const token = access.assetToken(nodeRef.resourceId, nodeRef.tokenId).value;
      if (!token) return Grid.DEFAULT;
      const path = Tree.getPath(token.children, node => node.data.id === nodeRef.nodeId);
      if (!path) return token.grid;
      return getGridByPath(token.children, path) ?? token.grid;
    } else {
      return Grid.DEFAULT;
    }
  }, [access]);
}