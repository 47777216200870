import {InputString} from "#lib/components/input/input-string.tsx";
import {MutableRef} from "common/ref";
import {StringOperation} from "common/types/generic/index.ts";
import {Field, FieldLabel, useUniqueID} from "#lib/components/panel-header.tsx";
import {InputGroup} from "#lib/components/index.ts";

export function StringField({valueRef, label}: {
  label?: string,
  valueRef: MutableRef<string, StringOperation[]>
}) {
  const fieldID = useUniqueID("string");
  return <Field>
    {label && <FieldLabel htmlFor={fieldID}>{label}</FieldLabel>}
    <InputGroup className="mx-2 rounded-md">
      <InputString id={fieldID} placeholder={label} value={valueRef} />
    </InputGroup>
  </Field>
}