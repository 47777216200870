import {Color, HSLA, Point, Transform} from "common/types/index.ts";
import {Node, NodeId, Scene} from "common/legends/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {BackgroundShader} from "../common/shader/background-shader.tsx";
import {useDatabase} from "../../../routes/game/model/store-context.tsx";
import React, {useCallback, useEffect, useMemo} from "react";
import {getWalls} from "../../common/walls/walls.ts";
import {QLabDatabase} from "common/qlab/index.ts";
import {listIdentity} from "common/observable";
import {createValueRef, fromSignal, Ref} from "common/ref";
import {GridProvider} from "../common/context/grid-context.ts";
import {RootProvider} from "../common/context/root-context.ts";
import {AccessibilityProvider} from "../common/context/visibility-context.ts";
import {defaultLocalNode} from "common/legends/node/local-node.ts";
import {LightNode} from "common/legends/node/light/light-node.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {Vision, VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {VisionFramebuffer} from "./vision-framebuffer.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {computed} from "common/signal";

export type Line = {
  start: Point;
  end: Point;
  viewLeft: boolean;
  viewRight: boolean;
  blockMovementLeft: boolean;
  blockMovementRight: boolean;
  tint: HSLA;
};
export const WallLineFn = {
  toLines(points: Point[], viewLeft: boolean, viewRight: boolean, blockMovementLeft: boolean, blockMovementRight: boolean, tint: HSLA): Line[] {
    let lines: Line[] = [];
    for (let i = 0; i < points.length - 1; i ++) {
      lines.push({start: points[i], end: points[i + 1], viewLeft, viewRight, blockMovementLeft, blockMovementRight, tint});
    }
    return lines;
  },
  equals(a: Line, b: Line): boolean {
    return (
      Vector2.equals(a.start, b.start) &&
      Vector2.equals(a.end, b.end) &&
      a.viewRight === b.viewRight &&
      a.viewLeft === b.viewLeft &&
      a.blockMovementRight === b.blockMovementRight &&
      a.blockMovementLeft === b.blockMovementLeft
    );
  }
}

export function useElementWalls(nodesRef: Ref<Node[]>, isVisible: (element: Node) => boolean, isAccessible: (element: Node) => boolean): Ref<Line[]> {
  const databaseRef = useDatabase();
  return useMemo(() => fromSignal(computed(() => {
    return [...nodesRef.value].reverse().reduce((walls, child) => getWalls(walls, Transform.DEFAULT, databaseRef.value, child, isVisible, isAccessible), [] as Line[]);
  })), [databaseRef, nodesRef, isVisible, isAccessible])
}

export function getElementOrigin(nodeID: NodeId, nodes: Node[]): Point | undefined {
  for (const node of nodes) {
    const origin = getOrigin(nodeID, node)
    if (origin) return origin;
  }
  return undefined;
}

function getOrigin(nodeID: NodeId, node: Node): Point | undefined {
  if (node.data.id === nodeID) {
    if (node.type === "image" || node.type === "light") {
      return node.data.origin;
    } else {
      return node.data.transform.position;
    }
  }

  for (const child of node.data.children) {
    const origin = getOrigin(nodeID, child);
    if (origin !== undefined) {
      const nodeMatrix = Matrix4f.transform(node.data.transform);
      let newOrigin = Matrix4f.multiplyVector(
        nodeMatrix,
        [origin[0], origin[1], 0, 1]
      ).slice(0, 2);
      return newOrigin as Point;
    }
  }

  return undefined;
}

export function SceneView({origin, vision, scene}: {
  origin: Point | undefined,
  vision: Vision[];
  scene: Scene;
}) {
  const isElementAccessible = useCallback((element: Node) => vision.some(vision => VisionFn.canAccess(vision, element)), [vision]);
  const sceneLight = useMemo((): LightNode => ({
    ...defaultLocalNode(),
    accessMask: undefined,
    shape: {type: "global", data: scene.light},
    blinkLength: 1,
    blinkOffset: 0,
    blinkInterval: 1
  }), [scene.light]);

  const resetBlend = useCallback((context: WebGL2RenderingContext) => {
    context.blendEquation(WebGL2RenderingContext.FUNC_ADD);
    context.blendFunc(WebGL2RenderingContext.SRC_ALPHA, WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA);
  }, []);
  const backgroundColor = useMemo(() => [...scene.backgroundColor, 1] as HSLA, [scene.backgroundColor]);

  const childrenRef = useMemo(() => createValueRef(scene.children), []);
  useEffect(() => {
    childrenRef.apply(_ => scene.children);
  }, [childrenRef, scene.children])

  return (<AccessibilityProvider value={isElementAccessible}>
    <GridProvider value={scene.grid}>
      <RootProvider value={childrenRef}>
        <action onAction={resetBlend}/>
        <BackgroundShader color={Color.CLEAR}/>
        <BlendFuncSeperate
          srcRGB={WebGL2RenderingContext.SRC_ALPHA} dstRGB={WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA}
          srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.ONE_MINUS_SRC_ALPHA}>
          <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.FUNC_ADD}>
            {[...vision].reverse().map((vision, index) => <VisionFramebuffer key={index} vision={vision} origin={origin} children={scene.children} backgroundColor={backgroundColor} globalLighting={sceneLight} />)}
          </BlendEquationSeparate>
        </BlendFuncSeperate>
      </RootProvider>
    </GridProvider>
  </AccessibilityProvider>);
}
