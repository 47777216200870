import {resourceObservable} from "#lib/qlab/index.ts";
import {pipe} from "common/pipe";
import {Asset, AssetReference} from "common/legends/index.ts";
import {from, map, Observable, switchAll} from "common/observable";
import {Optional} from "common/types/index.ts";
import {MutableRef} from "common/ref";
import {QLabDatabase, QLabDatabaseOperation} from "common/qlab/q-lab-database.ts";

export function assetObservable(databaseRef: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
  return (assetReference: Observable<Optional<AssetReference>>): Observable<Optional<Asset>> => pipe(
    assetReference,
    map((assetReference): Observable<Optional<Asset>> => assetReference ? pipe(
      databaseRef.observe,
      resourceObservable("asset", assetReference.assetID)
    ) : from(undefined)),
    switchAll()
  );
}
