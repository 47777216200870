import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {SenseID} from "common/legends/game/sense/sense-i-d.ts";
import {ConstantOperation, Optional, ValueOperation} from "common/types/generic/index.ts";
import {InputSelectSense} from "#lib/components/input-mask/input-select-sense.tsx";

export function VisionField({value}: {
  value: MutableRef<Optional<SenseID>, ValueOperation<Optional<SenseID>, ConstantOperation>[]>;
}) {
  return (<Field>
    <FieldLabel onClick={(ev) => {ev.preventDefault(); return false}}>Vision</FieldLabel>
    <div className="mx-2">
      <InputSelectSense valueRef={value} />
    </div>
  </Field>)
}