import {Dnd5eCharacterOperation, DND_5E_SPELL_LEVEL, Sheet, SheetOperation} from "common/legends/index.ts";
import {pipe} from "common/pipe";
import {distinct, listIdentity, map} from "common/observable";
import {ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {useMemo} from "react";
import {Dnd5eStatBlockOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-stat-block/index.ts";
import {SheetReference, SheetReferenceFn} from "../../../../../common/sheet/sheet-reference.ts";
import {useSheetSignal} from "../../../../../common/sheet/use-sheet-signal.ts";
import {Dnd5eActionSource} from "../../../../nav/common/quick-access-menu/dnd-5e/dnd-5e-action-source.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useValueSignal} from "../../../../../common/use-value-signal.ts";
import {MutableRef, Ref} from "common/ref";
import {Dnd5eActionTemplateID} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template-i-d.ts";
import {Dnd5eActionTemplate, Dnd5eActionTemplateOperation} from "common/legends/asset/sheet/dnd-5e/dnd-5e-action-definition/template/dnd-5e-action-template.ts";

export function useSheetActions(sheet: MutableRef<Optional<Sheet>, SheetOperation[]>): Ref<Dnd5eActionTemplate[]> {
  return useMemo(() => sheet.map(sheet => {
    if (sheet?.type === "dnd-5e-character") {
      const character = sheet.data;
      const inventoryActions = character.inventory
        .filter(t => t.equipped)
        .flatMap(i => i.actions);
      const spellActions = character.spells
        .filter(spell => spell.prepared)
        .sort((a, b) => DND_5E_SPELL_LEVEL.indexOf(a.level) - DND_5E_SPELL_LEVEL.indexOf(b.level))
        .flatMap(spell => spell.actions);
      const raceActions = character.race.features
        .filter(f => f.enabled)
        .flatMap(f => f.actions);
      const backgroundActions = character.background.features
        .filter(f => f.enabled)
        .flatMap(f => f.actions);
      const classesActions = character.classes.flatMap(c => c.features.filter(f => f.level <= c.level).map(f => f.feature))
        .filter(f => f.enabled)
        .flatMap(f => f.actions);
      const featureActions = character.features
        .filter(f => f.enabled)
        .flatMap(f => f.actions);
      return [...raceActions, ...backgroundActions, ...classesActions, ...inventoryActions, ...spellActions, ...featureActions];
    } else if (sheet?.type === "dnd-5e-stat-block") {
      const character = sheet.data;
      const customActions = character.features.filter(feature => feature.enabled).flatMap(feature => feature.actions);
      const spellActions = character.spells.filter(spell => spell.prepared).flatMap(f => f.actions);

      return [...customActions, ...spellActions];
    } else {
      return [];
    }
  }).distinct(listIdentity), [sheet]);
}

export function useActionSourceReference(sheetReference: Optional<SheetReference>, actionTemplateID: Dnd5eActionTemplateID): Optional<Dnd5eActionSource> {
  const sheet = useSheetSignal(useValueSignal(sheetReference, SheetReferenceFn.equals));
  return useRefValue(useMemo((): Ref<Optional<Dnd5eActionSource>> => {
    const valueFn = (sheet: Optional<Sheet>): Optional<Dnd5eActionSource> => {
      if (sheet?.type === "dnd-5e-character") {
        const item = sheet.data.inventory.find(item => item.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (item) return ({type: "item", sheet: sheetReference!, itemID: item.itemID})
        const spell = sheet.data.spells.find(spell => spell.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (spell) return ({type: "spell", sheet: sheetReference!, spellID: spell.spellID});
        const raceFeature = sheet.data.race.features.find(feature => feature.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (raceFeature) return ({type: "race", sheet: sheetReference!, featureID: raceFeature.featureID});
        const backgroundFeature = sheet.data.background.features.find(feature => feature.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (backgroundFeature) return ({type: "background", sheet: sheetReference!, featureID: backgroundFeature.featureID});
        for (let classIndex = 0; classIndex < sheet.data.classes.length; classIndex++) {
          const c = sheet.data.classes[classIndex];
          const classFeature = c.features.find(feature => feature.feature.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
          if (classFeature) return ({type: "class", sheet: sheetReference!, classID: c.id, featureID: classFeature.feature.featureID});
        }
        const feature = sheet.data.features.find(feature => feature.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (feature) return ({type: "feature", sheet: sheetReference!, featureID: feature.featureID});
      } else if (sheet?.type === "dnd-5e-stat-block") {
        const spell = sheet.data.spells.find(spell => spell.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (spell) return ({type: "spell", sheet: sheetReference!, spellID: spell.spellID});
        const feature = sheet.data.features.find(feature => feature.actions.findIndex(action => action.data.actionTemplateID === actionTemplateID) !== -1);
        if (feature) return ({type: "feature", sheet: sheetReference!, featureID: feature.featureID});
      }
      return undefined;
    }
    return new MutableRef({
      value() {return valueFn(sheet.value)},
      observe: pipe(sheet.observe, map(valueFn), distinct()),
      apply: _ => {throw new Error("Unsupported")}
    });
  }, [sheet, sheetReference, actionTemplateID]));
}

export function getActionSignal(sheet: MutableRef<Optional<Sheet>, SheetOperation[]>, actionTemplateID: Dnd5eActionTemplateID): MutableRef<Optional<Dnd5eActionTemplate>, Dnd5eActionTemplateOperation[]> {
  const valueFn = (sheet: Optional<Sheet>, actionID: Dnd5eActionTemplateID): Optional<Dnd5eActionTemplate> => {
    if (sheet?.type === "dnd-5e-character") {
      const character = sheet.data;
      for (const item of character.inventory) {
        const actionIndex = item.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (actionIndex !== -1) return item.actions[actionIndex];
      }
      for (const spell of character.spells) {
        const i = spell.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (i !== -1) return spell.actions[i];
      }
      for (const f of character.race.features) {
        const i = f.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (i !== -1) return f.actions[i];
      }
      for (const f of character.background.features) {
        const i = f.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (i !== -1) return f.actions[i];
      }
      for (const c of character.classes) {
        for (const f of c.features) {
          const i = f.feature.actions.findIndex(a => a.data.actionTemplateID === actionID);
          if (i !== -1) return f.feature.actions[i];
        }
      }
      for (const f of character.features) {
        const i = f.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (i !== -1) return f.actions[i];
      }
      return undefined;
    } else if (sheet?.type === "dnd-5e-stat-block") {
      const statBlock = sheet.data;
      for (const feature of statBlock.features) {
        const actionIndex = feature.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (actionIndex !== -1) return feature.actions[actionIndex];
      }
      for (const spell of statBlock.spells) {
        const actionIndex = spell.actions.findIndex(a => a.data.actionTemplateID === actionID);
        if (actionIndex !== -1) return spell.actions[actionIndex];
      }
      throw new Error(`Could not find action template: ${actionID}`)
    } else {
      throw new Error(`Could not find action template: ${actionID}`)
    }
  };

  return new MutableRef({
    value(): Optional<Dnd5eActionTemplate> {
      return valueFn(sheet.value, actionTemplateID);
    },
    observe: pipe(
      sheet.observe,
      map(sheet => valueFn(sheet, actionTemplateID)),
      map(action => action as Optional<Dnd5eActionTemplate>),
      distinct()
    ),
    apply: fn => sheet.apply((prev): SheetOperation[] => {
      if (prev?.type === "dnd-5e-character") {
        const character = prev.data;
        for (const item of character.inventory) {
          const actionIndex = item.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          return [{
            type: "dnd-5e-character",
            operations: Dnd5eCharacterOperation.updateInventory(ListOperation.apply(character.inventory.indexOf(item),
              [{
                type: "update-actions", operations: ListOperation.apply(actionIndex,
                  fn(item.actions[actionIndex])
                )
              }])
            ) satisfies Dnd5eCharacterOperation[]
          }];
        }
        for (const spell of character.spells) {
          const actionIndex = spell.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          const spellIndex = character.spells.indexOf(spell);
          return [{
            type: "dnd-5e-character", operations: [{
              type: "update-spells", operations: ListOperation.apply(spellIndex,
                [{
                  type: "update-actions", operations: ListOperation.apply(actionIndex, typeof fn === "function"
                    ? fn(spell.actions[actionIndex])
                    : fn
                  )
                }]
              )
            }] satisfies Dnd5eCharacterOperation[]
          }];
        }
        for (const feature of character.race.features) {
          const actionIndex = feature.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          const featureIndex = character.race.features.indexOf(feature);
          return [{
            type: "dnd-5e-character", operations: [{
              type: "update-race", operations: ValueFn.apply([{
                type: "update-features", operations: ListOperation.apply(featureIndex, [{
                  type: "update-actions", operations: ListOperation.apply(actionIndex, typeof fn === "function"
                    ? fn(feature.actions[actionIndex])
                    : fn
                  )
                }])
              }])
            }] satisfies Dnd5eCharacterOperation[]
          }];
        }
        for (const feature of character.background.features) {
          const actionIndex = feature.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          const featureIndex = character.background.features.indexOf(feature);
          return [{
            type: "dnd-5e-character", operations: [{
              type: "update-background", operations: ValueFn.apply([{
                type: "update-features", operations: ListOperation.apply(featureIndex, [{
                  type: "update-actions",
                  operations: ListOperation.apply(actionIndex, fn(feature.actions[actionIndex]))
                }])
              }])
            }] satisfies Dnd5eCharacterOperation[]
          }];
        }
        for (const clazz of character.classes) {
          for (const feature of clazz.features) {
            const actionIndex = feature.feature.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
            if (actionIndex === -1) continue;
            const classIndex = character.classes.indexOf(clazz);
            const featureIndex = clazz.features.indexOf(feature);
            return [{
              type: "dnd-5e-character", operations: [{
                type: "update-classes", operations: ListOperation.apply(classIndex, [{
                  type: "update-features", operations: ListOperation.apply(featureIndex, [{
                    type: "update-feature", operations: [{
                      type: "update-actions",
                      operations: ListOperation.apply(actionIndex, fn(feature.feature.actions[actionIndex]))
                    }]}])
                }])
              }] satisfies Dnd5eCharacterOperation[]
            }];
          }
        }
        for (const [featureIndex, feature] of character.features.entries()) {
          const actionIndex = feature.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          return [{
            type: "dnd-5e-character", operations: [{
              type: "update-features", operations: ListOperation.apply(featureIndex, [{
                type: "update-actions",
                operations: ListOperation.apply(actionIndex, fn(feature.actions[actionIndex]))
              }])
            }] satisfies Dnd5eCharacterOperation[]
          }];
        }
        return [];
      } else if (prev?.type === "dnd-5e-stat-block") {
        const statBlock = prev.data;
        for (const feature of statBlock.features) {
          const actionIndex = feature.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          const featureIndex = statBlock.features.indexOf(feature);
          return [{
            type: "dnd-5e-stat-block", operations: [{
              type: "update-features", operations: ListOperation.apply(featureIndex, [{
                type: "update-actions", operations: ListOperation.apply(actionIndex, fn(feature.actions[actionIndex]))
              }])
            }]
          }];
        }
        for (const spell of statBlock.spells) {
          const actionIndex = spell.actions.findIndex(a => a.data.actionTemplateID === actionTemplateID);
          if (actionIndex === -1) continue;
          const spellIndex = statBlock.spells.indexOf(spell);
          return [{
            type: "dnd-5e-stat-block", operations: [{
              type: "update-spells", operations: ListOperation.apply(spellIndex, [{
                type: "update-actions", operations: ListOperation.apply(actionIndex, fn(spell.actions[actionIndex]))
              }])
            }] satisfies Dnd5eStatBlockOperation[]
          }];
        }
        return [];
      } else {
        return [];
      }
    }).then(sheet => valueFn(sheet, actionTemplateID)!)
  });
}
