import {AssetID, NodeId, SceneID, TokenID} from "#common/legends/index.ts";
import {Cache} from "../cache.ts";
import {computed, Signal} from "#common/signal";
import {QLabDatabaseSignal, QLabResourceID} from "#common/qlab/index.ts";
import {Optional, Tree, TreePath} from "#common/types/index.ts";

export type ElementPath =
  | {type: "scene-node", sceneID: SceneID, path: TreePath}
  | {type: "asset-node", assetID: AssetID, tokenID: TokenID, path: TreePath}
  ;

export type ElementPathCache = Cache<Optional<NodeId>, Signal<Optional<ElementPath>>>;
export function ElementPathCache(databaseRef: QLabDatabaseSignal): ElementPathCache {
  return (elementID: Optional<NodeId>) => computed(() => {
    if (elementID === undefined) return undefined;
    const database = databaseRef.value;
    for (const resourceID of (Object.keys(database.resources) as QLabResourceID[])) {
      const resource = database.resources[resourceID];
      if (resource?.type === "scene") {
        const path = Tree.getPath(resource.data.children, element => element.data.id === elementID);
        if (path) return {type: "scene-node", sceneID: resourceID as SceneID, path};
      } else if (resource?.type === "asset") {
        for (const [_, token] of Object.entries(resource.data.tokens)) {
          const path = Tree.getPath(token.children, element => element.data.id === elementID);
          if (path) return {type: "asset-node", assetID: resourceID as AssetID, tokenID: token.tokenID, path};
        }
      }
    }
    return undefined;
  });
}
