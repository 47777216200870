import {Node, NodeOperation, NodeTypes} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useTypedRef} from "../common/use-typed-ref.ts";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../panel/nav/editor/state/selection-ref.ts";

import {ImageElementProperties} from "./image/image-element-properties.tsx";
import {GroupElementProperties} from "./group/group-element-properties.tsx";
import {TokenElementProperties} from "./token/token-element-properties.tsx";
import {GridElementProperties} from "./grid/grid-element-properties.tsx";
import {WallElementProperties} from "./wall/wall-element-properties.tsx";
import {AreaElementProperties} from "./area/area-element-properties.tsx";
import {TextElementProperties} from "./text/text-element-properties.tsx";
import {ShapeNodeProperties} from "./shape/shape-node-properties.tsx";
import {ParallaxElementProperties} from "./parallax/parallax-element-properties.tsx";
import {VideoElementProperties} from "./video/video-element-properties.tsx";
import {CameraElementProperties} from "./camera/camera-element-properties.tsx";
import {LightElementProperties} from "./light/light-element-properties.tsx";

export function ElementProperties({value, reference, pinned}: {
  value: MutableRef<Node, NodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const [nodeType, typedNode] = useTypedRef<NodeTypes>(value);
  if (nodeType === "image") return <ImageElementProperties value={typedNode} reference={reference} pinned={pinned} />;
  else if (nodeType === "group") return <GroupElementProperties value={typedNode} reference={reference} pinned={pinned}  />;
  else if (nodeType === "token") return <TokenElementProperties value={typedNode} reference={reference} pinned={pinned} />;
  else if (nodeType === "grid") return <GridElementProperties value={typedNode} reference={reference} pinned={pinned} />;
  else if (nodeType === "wall") return <WallElementProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "area") return <AreaElementProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "shape") return <ShapeNodeProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "text") return <TextElementProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "parallax") return <ParallaxElementProperties value={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "light") return <LightElementProperties valueRef={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "video") return <VideoElementProperties valueRef={typedNode} reference={reference} pinned={pinned} />
  else if (nodeType === "camera") return <CameraElementProperties valueRef={typedNode} reference={reference} pinned={pinned} />
  else return <></>
}
