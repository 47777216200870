import {ButtonBar, ExpandableLabel, IconButton, InputGroup, useToggle} from "#lib/components/index.ts";
import {Field, FieldLabel, SectionHeader} from "#lib/components/panel-header.tsx";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import React, {useMemo} from "react";
import {useRefValue} from "#lib/signal/index.ts";
import {faTrash} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InputListItemProps, useDragListItem} from "#lib/components/list/input-list.tsx";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import {Stage, StageFn, StageOperation} from "common/legends/stage/stage.ts";

export const StageListItem = ({item, remove}: InputListItemProps<Stage, StageOperation>) => {
  const [expanded, toggleExpanded] = useToggle(false);
  const {nameRef} = useMemo(() => StageFn.expand(item), [item]);
  const [dragHandlerRef, dragRefPreview] = useDragListItem("legends/sense", item, remove);

  return (
    <div role="list-item" className="flex flex-col gap-0 bg-zinc-800/50">
      <ButtonBar ref={dragRefPreview}>
        <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
        <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded} className="flex flex-row gap-0.5">
          <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>
            {useRefValue(nameRef)}
          </SectionHeader>
        </ExpandableLabel>
        <IconButton variant="destructive" onClick={remove}>
          <FontAwesomeIcon icon={faTrash} />
        </IconButton>
      </ButtonBar>

      {expanded && <Fieldset>
        <Field>
          <FieldLabel>Name</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputString value={nameRef}/>
          </InputGroup>
        </Field>
      </Fieldset>}
    </div>
  );
}