import {
  Dnd5eActionTemplateCustom,
  Dnd5eActionTemplateCustomOperation,
  Dnd5eActionTemplateCustomSignals,
  dnd5eActionTemplateCustomType
} from "./custom/dnd-5e-action-template-custom.ts";
import {z} from "zod";
import {Dnd5eActionTemplateID, generateDnd5eActionTemplateID} from "./dnd-5e-action-template-i-d.ts";
import {MutableRef} from "#common/ref";
import {MultiType, RichTextFn, Type} from "../../../../../../types/index.ts";
import {Dnd5eActionTemplateModifier, Dnd5eActionTemplateModifierFn} from "../modifier/dnd-5e-action-template-modifier.ts";
import {generateDnd5eProcessID} from "../process/dnd-5e-process-i-d.ts";
import {MathExpression, MathExpressionFn} from "../../../../../../math/index.ts";
import {Dnd5eActionTemplateSegment, Dnd5eActionTemplateSegmentFn} from "../segment/dnd-5e-action-template-segment.ts";
import {generateDnd5eActionTemplateSegmentID} from "../segment/dnd-5e-action-template-segment-i-d.ts";
import {ulid} from "ulid";
import {DND_5E_DAMAGE_TYPES} from "../../dnd-5e-damage-type.ts";
import {Dnd5eActionTemplateEffectFn} from "../effect/dnd-5e-action-template-effect.ts";

export const Dnd5eActionTemplate = z.discriminatedUnion("type", [
  z.object({type: z.literal("custom"), data: Dnd5eActionTemplateCustom})
]);
export type Dnd5eActionTemplate = z.infer<typeof Dnd5eActionTemplate>;

export const Dnd5eActionTemplateOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("custom"), operations: z.array(Dnd5eActionTemplateCustomOperation)})
]);
export type Dnd5eActionTemplateOperation = z.infer<typeof Dnd5eActionTemplateOperation>;

export type Dnd5eActionTemplateTypes = {
  custom: Type<Dnd5eActionTemplateCustom, Dnd5eActionTemplateCustomOperation>
};
export const dnd5eActionTemplateType: Type<Dnd5eActionTemplate, Dnd5eActionTemplateOperation> = new MultiType<Dnd5eActionTemplateTypes>({
  custom: dnd5eActionTemplateCustomType
}, v => {
  if (v.actionID) {
    if (v["modifiers"] === undefined) {
      v.modifiers = [];
      for (const modifier of v["damageRollModifiers"]) {
        v.modifiers.push({
          type: "damage-roll",
          data: {
            modifierID: modifier.modifierID,
            damageType: DND_5E_DAMAGE_TYPES.includes(modifier.damageType) ? modifier.damageType : undefined,
            hitExpression: modifier.hitExpression,
            critExpression: modifier.critExpression
          }
        });
      }
      delete v["damageRollModifiers"];
      for (const modifier of v["attackRollModifiers"]) {
        v.modifiers.push({
          type: "attack-roll",
          data: {
            modifierID: modifier.modifierID,
            expression: modifier.expression
          }
        });
      }
      delete v["attackRollModifiers"];
      for (const modifier of v["dCModifiers"]) {
        v.modifiers.push({
          type: "difficulty-class",
          data: {
            modifierID: modifier.modifierID,
            expression: modifier.expression
          }
        });
      }
      delete v["dCModifiers"];
    }
    if (v["actionEffects"] === undefined) {
      v.actionEffects = [];
      for (const effect of v["actionModifiers"]) {
        let effectModifiers = [];
        for (const modifier of effect["attackRollModifiers"]) {
          effectModifiers.push({
            type: "attack-roll",
            data: {
              modifierID: modifier.modifierID,
              expression: modifier.expression
            }
          });
        }
        delete effect["attackRollModifiers"];
        for (const modifier of effect["dCModifiers"]) {
          effectModifiers.push({
            type: "difficulty-class",
            data: {
              modifierID: modifier.modifierID,
              expression: modifier.expression
            }
          });
        }
        delete effect["dCModifiers"];
        for (const modifier of effect["damageRollModifiers"]) {
          effectModifiers.push({
            type: "damage-roll",
            data: {
              modifierID: modifier.modifierID,
              damageType: DND_5E_DAMAGE_TYPES.includes(modifier.damageType) ? modifier.damageType : undefined,
              hitExpression: modifier.hitExpression,
              critExpression: modifier.critExpression
            }
          });
        }
        delete effect["damageRollModifiers"];
        v.actionEffects.push({
          actionEffectID: effect.modifierID,
          name: effect.name,
          enabled: effect.enabled,
          modifiers: effectModifiers
        });
      }
      delete v["actionModifiers"];
    }
    if (v.favorite === undefined) v.favorite = false;
    if (v.criticalRange === undefined || typeof v.criticalRange === "number") v.criticalRange = MathExpressionFn.assertMathExpression("20");

    const convertActionModifier = (v: any): Dnd5eActionTemplateModifier => {
      if (v.type === "attack-roll") {
        return ({
          type: "action::attack-roll::hit-modifier",
          data: {
            modifierID: v.data.modifierID,
            condition: undefined,
            expression: v.data.expression
          }
        });
      } else if (v.type === "difficulty-class") {
        return ({
          type: "action::saving-throw::difficulty-class",
          data: {
            modifierID: v.data.modifierID,
            condition: undefined,
            expression: v.data.expression
          }
        });
      } else if (v.type === "damage-roll") {
        return ({
          type: "action::roll::expression",
          data: {
            modifierID: v.data.modifierID,
            condition: undefined,
            rollType: undefined,
            expression: v.data.hitExpression
          }
        });
      } else if (v.type === "item-consumption") {
        return ({
          type: "action::trigger::activation",
          data: {
            modifierID: v.data.modifierID,
            processes: [{
              type: "consume-item",
              data: {
                processID: generateDnd5eProcessID(),
                item: v.data.itemName ?? ""
              }
            }]
          }
        });
      } else if (v.type === "resource-consumption") {
        return ({
          type: "action::trigger::activation",
          data: {
            modifierID: v.data.modifierID,
            processes: [{
              type: "adjust-custom-resource",
              data: {
                processID: generateDnd5eProcessID(),
                resource: v.data.resourceID ?? "",
                quantity: `-${v.data.qty}`
              }
            }]
          }
        });
      } else if (v.type === "spell-slot-consumption") {
        return ({
          type: "action::trigger::activation",
          data: {
            modifierID: v.data.modifierID,
            processes: [{
              type: "adjust-spell-slot",
              data: {
                processID: generateDnd5eProcessID(),
                level: v.data.level === "pact-slot" ? "{PACTLEVEL}" : `${v.data.level}`,
                quantity: MathExpression.parse("-1")
              }
            }]
          }
        });
      } else if (v.type === "variable-override") {
        return ({
          type: "variable",
          data: {
            modifierID: v.data.modifierID,
            name: v.data.name,
            expression: v.data.expression
          }
        });
      } else {
        throw new Error(`Unknown Type`);
      }
    };

    const convertAction = (v: any): Dnd5eActionTemplate => {
      const segments: Dnd5eActionTemplateSegment[] = [];
      if (!RichTextFn.isEmpty(v.description)) {
        segments.push({
          type: "text",
          data: {
            actionSegmentID: generateDnd5eActionTemplateSegmentID(),
            content: v.description
          }
        });
      }
      if (v.defense === "ac") {
        const hitExpressionModifiers = v.modifiers.flatMap((modifier: any) => modifier.type === "attack-roll" ? [modifier.data] : []);
        const hitExpression = hitExpressionModifiers.length > 0 ? hitExpressionModifiers.map((modifier: any) => modifier.expression).join("+").replaceAll("+-", "-") : "0";
        const damageExpressions = v.modifiers.flatMap((modifier: any) => modifier.type === "damage-roll" ? [modifier.data] : []);
        segments.push({
          type: "attack-roll",
          data: {
            actionSegmentID: generateDnd5eActionTemplateSegmentID(),
            proficient: v.proficient,
            attribute: v.offense,
            criticalRange: v.criticalRange,
            expression: hitExpression,
            modifiers: [],
            onHit: [
              ...(damageExpressions.length > 0 ? [{
                type: "roll",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  modifiers: [],
                  expressions: damageExpressions.map((expression: any) => ({
                    rollID: ulid(),
                    rollType: expression.damageType,
                    expression: expression.hitExpression
                  }))
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
              ...(v.onHit !== undefined && v.onHit !== "" ? [{
                type: "text",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  content: [{children: [{text: v.onHit}]}]
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
            ],
            onCriticalHit: [
              ...(damageExpressions.length > 0 ? [{
                type: "roll",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  modifiers: [],
                  expressions: damageExpressions.map((expression: any) => ({
                    rollID: ulid(),
                    rollType: expression.damageType,
                    expression: expression.critExpression
                  }))
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
              ...(v.onCrit !== undefined && v.onCrit !== "" ? [{
                type: "text",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  content: [{children: [{text: v.onCrit}]}]
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
            ],
            onMiss: [
              ...(v.onMiss !== undefined && v.onMiss !== "" ? [{
                type: "text",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  content: [{children: [{text: v.onMiss}]}]
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
            ]
          }
        });
      } else if (v.defense !== undefined) {
        const savingThrowExpressionModifiers = v.modifiers.flatMap((modifier: any) => modifier.type === "difficulty-class" ? [modifier.data] : []);
        const savingThrowExpression = savingThrowExpressionModifiers.length > 0 ? savingThrowExpressionModifiers.map((modifier: any) => modifier.expression).join("+").replaceAll("+-", "-") : "0";
        const damageExpressions = v.modifiers.flatMap((modifier: any) => modifier.type === "damage-roll" ? [modifier.data] : []);

        segments.push({
          type: "saving-throw",
          data: {
            actionSegmentID: generateDnd5eActionTemplateSegmentID(),
            modifiers: [],
            proficient: v.proficient,
            attribute: v.offense,
            expression: savingThrowExpression,
            onFailure: [
              ...(damageExpressions.length > 0 ? [{
                type: "roll",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  modifiers: [],
                  expressions: damageExpressions.map((expression: any) => ({
                    rollID: ulid(),
                    rollType: expression.damageType,
                    expression: expression.hitExpression
                  }))
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
              ...(v.onHit !== undefined && v.onHit !== "" ? [{
                type: "text",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  content: [{children: [{text: v.onHit}]}]
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
            ],
            onSuccess: [
              ...(v.onMiss !== undefined && v.onMiss !== "" ? [{
                type: "text",
                data: {
                  actionSegmentID: generateDnd5eActionTemplateSegmentID(),
                  content: [{children: [{text: v.onMiss}]}]
                }
              }] : []) satisfies Dnd5eActionTemplateSegment[],
            ],
            savingThrowTypes: [v.defense],
          }
        })
      } else {
        const damageModifiers = v.modifiers.filter((modifier: any) => modifier.type === "damage-roll");
        if (damageModifiers.length > 0) {
          segments.push({
            type: "roll",
            data: {
              actionSegmentID: generateDnd5eActionTemplateSegmentID(),
              modifiers: [],
              expressions: damageModifiers.map((modifier: any) => ({
                rollID: modifier.data.modifierID,
                rollType: modifier.data.damageType,
                expression: modifier.data.hitExpression
              }))
            }
          });
        }
      }

      return {
        type: "custom",
        data: {
          actionTemplateID: v.actionID,
          actionType: v.actionType,
          favorite: v.favorite,
          name: v.label,
          abbreviation: "",
          modifiers: v.modifiers
            .filter((modifier: any) => modifier.type !== "damage-roll" && modifier.type !== "attack-roll" && modifier.type !== "difficulty-class")
            .map(convertActionModifier),
          effects: v.actionEffects.map((effect: any) => ({
            actionEffectID: effect.actionEffectID,
            enabled: effect.enabled,
            name: effect.name,
            abbreviation: "",
            modifiers: effect.modifiers.map(convertActionModifier)
          })),
          segments: segments
        }
      };
    }
    return convertAction(v);
  }

  return v;
});

export const Dnd5eActionTemplateFn = {
  getActionTemplateID(value: Dnd5eActionTemplate): Dnd5eActionTemplateID {
    return value.data.actionTemplateID;
  },
  getTitle(value: Dnd5eActionTemplate): string {
    const name = value.data.name;
    return name.substring(name.lastIndexOf("/") + 1).trim();
  },
  getAbbreviation(value: Dnd5eActionTemplate): string {
    return (value.data.abbreviation === "") ? value.data.name : value.data.abbreviation;
  },
  copyActionTemplate(value: Dnd5eActionTemplate): Dnd5eActionTemplate {
    return ({
      ...value,
      data: {
        ...value.data,
        actionTemplateID: generateDnd5eActionTemplateID(),
        segments: value.data.segments.map(Dnd5eActionTemplateSegmentFn.copyActionSegment),
        effects: value.data.effects.map(Dnd5eActionTemplateEffectFn.copyActionEffect),
        modifiers: value.data.modifiers.map(Dnd5eActionTemplateModifierFn.copyDnd5eActionTemplateModifier)
      }
    });
  }
}



export function Dnd5eActionTemplateSignals(value: MutableRef<Dnd5eActionTemplate, Dnd5eActionTemplateOperation[]>) {
  return Dnd5eActionTemplateCustomSignals(value.map(
    () => value.value.data,
    (_, operations) => {
      if (operations.length === 0) return [];
      return [{type: "custom", operations}];
    }
  ));
}