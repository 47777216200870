import {useCallback} from "react";
import {InteractionAction} from "common/legends/node/interaction/action/interaction-action.ts";
import {MapFn, Optional, ValueFn} from "common/types/index.ts";
import {TokenReference} from "common/legends/asset/token/token-reference.ts";
import {Grid, Node, NodeId, NodeOperation, TokenID, TokenNodeOperation} from "common/legends/index.ts";
import {useApplyToNode} from "./use-apply-to-node.ts";
import {useDatabase, useStoreID} from "../../../routes/game/model/store-context.tsx";
import {NodePath} from "../common/context/get-nodes-at-point.ts";
import {useAttachNode} from "../../common/node/use-attach-node.ts";
import {useDetachNode} from "../../common/node/use-detach-node.ts";
import {teleportToNode} from "../../common/node/use-teleport-to-node.ts";
import {getPlayerCharacterTokens} from "../../common/legends/get-player-characters.ts";
import {getGrid} from "../../common/legends/get-grid.ts";
import {getNodeByPath} from "../../common/node/use-get-node.ts";
import {useGoToNode} from "../../panel/nav/editor/use-go-to-node.ts";
import {getAncestorNodeIDs} from "../../panel/nav/editor/editor-context-menu/token-context-menu.tsx";

export function useSetToken() {
  const database = useDatabase();
  const applyToNode = useApplyToNode();
  return useCallback((nodePath: NodePath, tokenID: TokenID) => applyToNode(nodePath, (node: Node) => {
    if (node.type !== "token") return [];

    // Do we need to copy the sheet?
    let updateTokenSheetOperations: TokenNodeOperation[] = [];
    if (node.data.tokenSheets[tokenID] === undefined) {
      const resource = database.value.resources[node.data.tokenReference.assetID];
      if (resource?.type === "asset") {
        const asset = resource.data;
        const token = asset.tokens.find(token => token.tokenID === tokenID);
        if (token?.sheetPolicy === "copy" && token.sheetId !== undefined) {
          const item = asset.sheets[token.sheetId];
          if (item) {
            updateTokenSheetOperations.push({
              type: "update-token-sheets",
              operations: MapFn.put(tokenID, {type: "copy", data: item})
            });
          }
        } else if (token?.sheetPolicy === "link" && token.sheetId !== undefined) {
          updateTokenSheetOperations.push({
            type: "update-token-sheets",
            operations: MapFn.put(tokenID, {type: "link", data: token.sheetId})
          });
        }
      }
    }

    const setTokenReferenceOperations: TokenNodeOperation[] = [{
      type: "update-token-reference", operations: ValueFn.set(
        node.data.tokenReference,
        {assetID: node.data.tokenReference.assetID, tokenID} satisfies TokenReference
      )
    }];

    return [{type: "token", operations: [
      ...updateTokenSheetOperations,
      ...setTokenReferenceOperations
    ]}] satisfies NodeOperation[];
  }), [applyToNode])
}

export type TriggerContext = {
  triggerPath: NodePath[],
  triggerNode: Node,
  action: InteractionAction,
  activeNodeID: Optional<NodeId>,
};

export function useApplyInteractionAction() {
  const databaseRef = useDatabase();
  const setToken = useSetToken();
  const gotoNode = useGoToNode();
  const attachNode = useAttachNode();
  const detachNode = useDetachNode();
  return useCallback(async ({action, triggerPath, activeNodeID}: TriggerContext) => {
    if (action.type === "set-token" && action.data.tokenID !== undefined) {
      if (triggerPath.length > 1) {
        setToken(triggerPath[triggerPath.length - 2], action.data.tokenID);
      }
    } else if (action.type === "teleport" && action.data.nodeID) {
      if (action.data.target === "source" && activeNodeID !== undefined) {
        await teleportToNode(databaseRef, activeNodeID, action.data.nodeID, {position: action.data.offset, scale: 1, rotation: 0});
        await gotoNode(activeNodeID);
      } else if (action.data.target === "party") {
        const database = databaseRef.value;
        const tokens = getPlayerCharacterTokens(database);
        const grid = getGrid(database, action.data.nodeID);
        const positions = Grid.spiral(grid, action.data.offset);
        for (const token of tokens) {
          if (token.data.id === action.data.nodeID) continue;
          if (getAncestorNodeIDs(database, token.data.id).some(ancestor => tokens.map(c => c.data.id).includes(ancestor))) continue;
          const position = positions.next().value;
          await teleportToNode(databaseRef, token.data.id, action.data.nodeID, {position, scale: 1, rotation: 0});
        }
      }
    } else if (action.type === "focus" && action.data.nodeID !== undefined) {
      await gotoNode(action.data.nodeID);
    } else if (action.type === "mount" && activeNodeID) {
      const nodePaths = [...triggerPath].reverse().filter(path => path.type === "scene");
      if (nodePaths.length === 0) return;
      const node = getNodeByPath(databaseRef.value, nodePaths[0]);
      if (!node) return;
      await attachNode(node.data.id, activeNodeID);
    } else if (action.type === "dismount" && activeNodeID) {
      const nodePaths = [...triggerPath].reverse().filter(path => path.type === "scene");
      if (nodePaths.length === 0) return;
      const node = getNodeByPath(databaseRef.value, nodePaths[0]);
      if (!node) return;
      await detachNode(node.data.id, activeNodeID);
    }
  }, [databaseRef, setToken, gotoNode, attachNode, detachNode]);
}
