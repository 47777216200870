import {effect, MutableSignal} from "#common/signal";
import {MutableRef} from "./ref.ts";

export function fromSignal<Value, Action>(signal: MutableSignal<Value, Action>): MutableRef<Value, Action> {
  return new MutableRef({
    value() {
      return signal.value;
    },
    observe: observer => {
      return effect(() => observer.next(signal.value));
    },
    apply: async (fn) => {
      signal.apply(fn);
      return signal.value;
    }
  })
}