import {Node, NodeId, UserID} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {useNode} from "../../viewport/token/use-node.ts";
import {useComputedValue} from "#lib/signal/index.ts";
import {StageID} from "common/legends/stage/stage-i-d.ts";

export function isPartyNode(node: Optional<Node>) {
  if (node?.type !== "token") return false;
  return node.data.ownerIDs.length > 0;
}
export function isTokenElementOwner(node: Optional<Node>, userID: UserID, stage: StageID) {
  if (node?.type !== "token") return false;

  if (
    !node.data.ownerIDs.includes("GLOBAL" as UserID) &&
    !node.data.ownerIDs.includes(userID)
  ) {
    return false;
  }

  if (!node.data.stageIDs.includes("ALL") && !node.data.stageIDs.includes(stage)) {
    return false;
  }

  return true;
}

export function useIsPartyNode(nodeID: Optional<NodeId>): boolean {
  return useComputedValue(useNode(nodeID), isPartyNode);
}
