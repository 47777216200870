import React, {ForwardedRef} from "react";
import {WebGL2Canvas, WebGL2CanvasProps} from "#lib/gl-react/index.ts";
import {InstanceProvider, useInstance} from "#lib/qlab/index.ts";
import {ImageTextureManagerProvider} from "#lib/qlab/react/hooks/asset/image-texture-manager.tsx";
import {DatabaseProvider, useAccess, useDatabase, useStoreID} from "../../model/store-context.tsx";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";
import {GameProvider, useGame} from "../../model/index.ts";
import {ProgramManagerProvider} from "#lib/qlab/react/hooks/asset/program-manager.tsx";
import {ShaderManagerProvider} from "#lib/qlab/react/hooks/asset/shader-manager.tsx";
import {VideoTextureManagerProvider} from "#lib/qlab/react/hooks/asset/video-texture-manager.tsx";

export const LegendsWebGL2Canvas = React.forwardRef(function LegendsWebGL2Canvas({children, ...props}: WebGL2CanvasProps, ref: ForwardedRef<HTMLCanvasElement>): JSX.Element {
  const instance = useInstance();
  const storeID = useStoreID();
  const database = useDatabase();
  const access = useAccess();
  return (<ErrorBoundary>
    <WebGL2Canvas ref={ref} {...props}>
      <InstanceProvider value={instance}>
        <ProgramManagerProvider>
          <ShaderManagerProvider>
            <ImageTextureManagerProvider>
              <VideoTextureManagerProvider>
                <DatabaseProvider storeID={storeID} database={database} access={access}>
                  <GameProvider value={useGame()}>
                    {children}
                  </GameProvider>
                </DatabaseProvider>
              </VideoTextureManagerProvider>
            </ImageTextureManagerProvider>
          </ShaderManagerProvider>
        </ProgramManagerProvider>
      </InstanceProvider>
    </WebGL2Canvas>
  </ErrorBoundary>)
});
