import {computed} from "#common/signal";
import {OptionalSceneSignal, SceneID} from "#common/legends/index.ts";
import {Cache} from "../../cache.ts";
import {ResourceCache} from "#common/legends/access/resource/resource-cache.ts";
import {WeakCache} from "#common/legends/access/weak-cache.ts";
import {Optional} from "#common/types/index.js";

export type SceneCache = Cache<Optional<SceneID>, OptionalSceneSignal>;
export function SceneCache(resourceCache: ResourceCache): Cache<Optional<SceneID>, OptionalSceneSignal> {
  const cache = new WeakCache<SceneID, OptionalSceneSignal>(sceneID => {
    const scene = resourceCache(sceneID);
    return computed(
      () => {
        const resource = scene.value;
        return (resource?.type === "scene") ? resource.data : undefined;
      },
      operations => scene.apply(_ => [{type: "scene", operations}])
    )
  });
  return (sceneID: Optional<SceneID>) => sceneID ? cache.get(sceneID) : computed(() => undefined, () => {});
}
