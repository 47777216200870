import {MessageOperation, MessageValue, QLabDatabaseSignal, QLabMessageID} from "#common/qlab/index.ts";
import {Optional, ValueFn} from "#common/types/index.ts";
import {Cache} from "#common/legends/access/cache.ts";
import {computed, MutableSignal} from "#common/signal";
import {WeakCache} from "#common/legends/access/weak-cache.ts";

type OptionalMessageSignal = MutableSignal<Optional<MessageValue>, MessageOperation[]>;
type MessageCache = Cache<QLabMessageID, OptionalMessageSignal>;
export function MessageCache(databaseRef: QLabDatabaseSignal): MessageCache {
  const cache = new WeakCache<Optional<QLabMessageID>, OptionalMessageSignal>((messageID) => computed(
    () => {
      if (!messageID) return undefined;
      return databaseRef.value.messages[messageID];
    },
    operations => {
      if (!messageID) return;
      databaseRef.apply(_ => [{type: "message", messageID, operations: ValueFn.apply(operations)}]);
    }
  ));
  return (messageID) => cache.get(messageID);
}
