import {Point, Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {ElementRenderPass} from "../../viewport/common/render-pass/element";
import {ParallaxNode} from "common/legends/node/parallax-node.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {useView, ViewProvider} from "../../viewport/common/context/pvm-context.ts";

export function ParallaxElementRenderPass({value}: {
  value: ParallaxNode;
}) {
  const view = useView();
  const viewParallax = useMemo((): Transform => {
    const scale = Math.pow(view.scale, 1 / value.transform.scale);
    const offset: Point = Vector2.divide(view.position, value.transform.scale);
    return {
      ...view,
      scale: scale,
      position: offset
    };
  }, [view, value.transform]);

  return (<binder>
    <ViewProvider value={viewParallax}>
      {[...value.children].reverse().map((element) => <ElementRenderPass key={element.data.id} value={element} />)}
    </ViewProvider>
  </binder>);
}