import {Mask, MaskOperation} from "common/types/generic/mask/mask.ts";
import {MutableRef} from "common/ref";
import {useGameSenses} from "../../../legends/common/game/use-game-senses.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {ButtonBar, ButtonBarProps, Checkbox, IconButton, InputGroup} from "#lib/components/index.ts";
import {ListOperation, SetFn} from "common/types/generic/index.ts";
import {twMerge} from "tailwind-merge";
import {Sense, SenseFn} from "common/legends/game/sense/sense.ts";
import {SenseID} from "common/legends/game/sense/sense-i-d.ts";
import {OverlayPanel} from "primereact/overlaypanel";
import React, {useRef, useState} from "react";
import {FaEllipsisVertical, FaPencil} from "react-icons/fa6";
import {CreateNewSenseButton} from "#lib/components/input-mask/create-sense-button.tsx";
import {Section, useUniqueID} from "#lib/components/panel-header.tsx";
import {FaTrash} from "react-icons/fa";
import {EditSenseOverlay, useSenseRef} from "#lib/components/input-mask/edit-sense-overlay.tsx";

export function InputMultiSelectSense({valueRef, ...props}: {
  valueRef: MutableRef<Mask, MaskOperation[]>;
} & ButtonBarProps) {
  const value = useRefValue(valueRef);
  const sensesRef = useGameSenses();
  const senses: Sense[] = [
    ...SenseFn.DEFAULT_SENSES,
    ...useRefValue(sensesRef)
  ];
  const setSense = (visionID: SenseID, checked: boolean) => {
    if (visionID === "ALL") {
      valueRef.apply(prev => {
        return checked ? SetFn.set(prev, ["ALL"]) : SetFn.set(prev, [...sensesRef.value.map(sense => sense.senseID), "GM"]);
      });
    } else {
      valueRef.apply(prev => {
        if (prev.includes("ALL")) {
          if (checked) return [];
          const all = ["GM", ...sensesRef.value.map(vision => vision.senseID)];
          return SetFn.set(prev, all.filter(id => id !== visionID))
        }
        return checked ? SetFn.insert(visionID) : SetFn.delete(visionID)
      });
    }
  };
  const uniqueID = useUniqueID("sense");
  const [editSenseID, setEditSenseID] = useState<SenseID | undefined>(undefined);

  const editOverlayRef = useRef<OverlayPanel>(null);
  const editSenseRef = useSenseRef(editSenseID);

  const op = useRef<OverlayPanel>(null);
  return <ButtonBar {...props} className={twMerge("px-0 rounded-md overflow-hidden w-fit flex flex-row gap-0.5", props.className)}>
    {senses.map(vision => <InputGroup key={vision.senseID} size="small" className="px-3" title={vision.name} style={{backgroundColor: `hsla(${vision.color[0] * 360}, ${vision.color[1] * 100}%, ${vision.color[2] * 100}%, 25%)`}}>
      <Checkbox size="small" checked={value.includes(vision.senseID) || value.includes("ALL")} onChange={ev => setSense(vision.senseID, ev.target.checked)}/>
    </InputGroup>)}
    <IconButton size="small" type="button" onClick={(e) => op.current?.toggle(e)}>
      <FaEllipsisVertical />
    </IconButton>

    <OverlayPanel dismissable ref={op}>
      <Section open title="Senses" pt={{
        root: "m-0 min-w-96 bg-zinc-800/80 backdrop-blur shadow-lg pb-0",
        header: "",
        body: "pb-0 flex flex-col gap-0.5"
      }} options={
        <CreateNewSenseButton onCreate={(e, sense) => {
          setSense(sense.senseID, true);
          setEditSenseID(sense.senseID);
          editOverlayRef.current?.toggle(e);
        }}/>
      }>
        {senses.map(sense => <div key={sense.senseID} className="flex flex-row gap-2 items-center"
                                            style={{backgroundColor: `hsla(${sense.color[0] * 360}, ${sense.color[1] * 100}%, ${sense.color[2] * 100}%, 25%)`}}>
          <InputGroup size="small">
            <Checkbox id={`${uniqueID}-${sense.senseID}`} size="small" checked={value.includes(sense.senseID) || value.includes("ALL")} onChange={ev => setSense(sense.senseID, ev.target.checked)}
                      style={{backgroundColor: `hsl(${sense.color[0]}, ${sense.color[1]}, ${sense.color[2]})`}}/>
          </InputGroup>
          <label htmlFor={`${uniqueID}-${sense.senseID}`} className="text-sm font-bold flex-1">
            {sense.name}
          </label>
          <ButtonBar>
            <IconButton size="small" disabled={sense.senseID === "ALL" || sense.senseID === "GM"} onClick={(e) => {
              setEditSenseID(sense.senseID);
              editOverlayRef.current?.toggle(e)
            }}>
              <FaPencil />
            </IconButton>
            <IconButton size="small" title="Delete Sense" variant="destructive" disabled={sense.senseID === "ALL" || sense.senseID === "GM"} onClick={(e) => {
              sensesRef.apply(prev => {
                const senseIndex = prev.findIndex(s => s.senseID === sense.senseID);
                if (senseIndex === -1) return [];
                return ListOperation.delete(senseIndex, prev[senseIndex]);
              });
              e.preventDefault();
              return false;
            }}>
              <FaTrash />
            </IconButton>
          </ButtonBar>
        </div>)}
      </Section>
    </OverlayPanel>
    <EditSenseOverlay valueRef={editSenseRef} overlayRef={editOverlayRef} />
  </ButtonBar>;
}
