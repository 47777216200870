import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {ImageNode, ImageNodeOperation, ImageNodeSignals} from "common/legends/index.ts";
import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {SizeField} from "../../field/size-field.tsx";
import {useMemo} from "react";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {ConditionsSection} from "../../panel/properties/node/conditions/conditions-section.tsx";
import {MountableField} from "../../field/mountable-field.tsx";
import {AttachableField} from "../../panel/properties/attachable-field.tsx";
import {MutableRef} from "common/ref";
import {PivotField} from "../../field/pivot-field.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {RepeatField} from "../../panel/properties/node/input-repeat.tsx";

export function ImageElementProperties({value, reference, pinned}: {
  value: MutableRef<ImageNode, ImageNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {name, fileRef, normalRef, sizeRef, opacityRef, selectionMaskRef, transformRef, originRef, pivotRef, mountableRef, attachableRef, visibilityLayerRef, repeatXRef, repeatYRef, conditionsRef} = useMemo(() => ImageNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Image Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <Field>
          <FieldLabel>Image</FieldLabel>
          <InputFile className="flex-1" value={fileRef} />
        </Field>
        <Field>
          <FieldLabel>Normal</FieldLabel>
          <InputFile className="flex-1" value={normalRef} />
        </Field>
        <VisibleField value={visibilityLayerRef} />
        <SelectionField value={selectionMaskRef} />
        <OpacityField value={opacityRef} />
        <TransformField value={transformRef} />
        <OriginField valueRef={originRef} />
        <PivotField valueRef={pivotRef} />
        <SizeField valueRef={sizeRef} />
        <RepeatField repeatXRef={repeatXRef} repeatYRef={repeatYRef} />
        <MountableField value={mountableRef}/>
        <AttachableField value={attachableRef}/>
      </Section>
      <ConditionsSection value={conditionsRef}/>
    </Panel>
  </div>
}