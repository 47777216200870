import {Invitation, InvitationOperation, invitationType} from "./invitation/index.ts";
import {
  BooleanOperation,
  booleanType,
  Color,
  ConstantOperation,
  constantType,
  ListOperation,
  ListPropertyRef,
  ListType,
  MapOperation,
  MapPropertyRef,
  MapType,
  ObjectType,
  Optional,
  StringOperation,
  stringType,
  TreeOperation,
  Type,
  ValueFn,
  ValueOperation,
  ValuePropertyRef,
  ValueType
} from "../../types/index.ts";
import {Player, PlayerOperation, playerType} from "./player/index.ts";
import {BotConfig, BotConfigOperation, botConfigType} from "../bot-config/index.ts";
import {Owner, OwnerOperation, ownerType} from "./owner/index.ts";
import {UserID} from "../user/user-id.ts";
import {BotID} from "../bot/bot-id.ts";
import {AssetTreeItem, AssetTreeItemOperation, assetTreeType} from "./character-tree/index.ts";
import {SceneTreeItem, SceneTreeItemOperation, sceneTreeType} from "./scene-tree/index.ts";
import {TurnTracker, TurnTrackerOperation, turnTrackerType} from "./turn-tracker/index.ts";
import {GameSystems, GameSystemsOperation, gameSystemsType} from "#common/legends/game/system/game-systems.ts";
import {QLabDatabase, QLabDatabaseOperation} from "#common/qlab/index.ts";
import {MutableRef} from "#common/ref";
import {PropertyRef} from "#common/types/generic/object/property-ref.ts";
import {SceneID} from "#common/legends/index.ts";
import {Sense, SenseOperation, senseSettingType} from "./sense/sense.ts";
import {Stage, StageOperation, stageType} from "#common/legends/stage/stage.ts";
import {StageID} from "#common/legends/stage/stage-i-d.ts";
import {SenseID} from "#common/legends/game/sense/sense-i-d.ts";

export type Game = {
  owner: Owner;
  name: string;
  defaultSceneID: Optional<SceneID>;
  scenes: SceneTreeItem[];
  assets: AssetTreeItem[];
  invitations: Invitation[];
  players: {[playerId: UserID]: Player};
  bots: {[botId: BotID]: BotConfig};
  systems: GameSystems;
  turnTracker: TurnTracker;
  xCard: boolean;
  senses: Sense[];
  globalStageID: StageID;
  stages: Stage[];
};
export type GameOperation =
  | {type: "update-owner", operations: ValueOperation<Owner, OwnerOperation>[]}
  | {type: "update-default-scene-i-d", operations: ValueOperation<Optional<SceneID>, ConstantOperation>[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-scenes", operations: TreeOperation<SceneTreeItem, SceneTreeItemOperation>[]}
  | {type: "update-assets", operations: TreeOperation<AssetTreeItem, AssetTreeItemOperation>[]}
  | {type: "update-invitations", operations: ListOperation<Invitation, InvitationOperation>[]}
  | {type: "update-players", operations: MapOperation<UserID, Player, PlayerOperation>[]}
  | {type: "update-bots", operations: MapOperation<BotID, BotConfig, BotConfigOperation>[]}
  | {type: "update-systems", operations: GameSystemsOperation[]}
  | {type: "update-turn-tracker", operations: ValueOperation<TurnTracker, TurnTrackerOperation>[]}
  | {type: "update-x-card", operations: BooleanOperation[]}
  | {type: "update-senses", operations: ListOperation<Sense, SenseOperation>[]}
  | {type: "update-global-stage-i-d", operations: ValueOperation<StageID, ConstantOperation>[]}
  | {type: "update-stages", operations: ListOperation<Stage, StageOperation>[]}
  ;

export const gameType: Type<Game, GameOperation> = new ObjectType({
  owner: new ValueType(ownerType),
  name: stringType,
  defaultSceneID: new ValueType(constantType),
  scenes: sceneTreeType,
  assets: assetTreeType,
  invitations: new ListType(invitationType),
  players: new MapType<UserID, Player, PlayerOperation>(playerType),
  bots: new MapType<BotID, BotConfig, BotConfigOperation>(botConfigType),
  systems: gameSystemsType,
  turnTracker: new ValueType(turnTrackerType),
  xCard: booleanType,
  globalStageID: new ValueType<SenseID, ConstantOperation>(constantType),
  senses: new ListType(senseSettingType),
  stages: new ListType(stageType)
}, (value: any) => {
  if (value.systems === undefined) value.systems = {};
  if (value["characters"] !== undefined) {
    value["assets"] = value["characters"];
    delete value["characters"];
  }
  if (value["senses"] === undefined) {
    value["senses"] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(id => ({
      senseID: `${id}`,
      name: `Sense ${id + 1}`,
      color: Color.WHITE
    }) satisfies Sense)
  }
  if (value["globalStageID"] === undefined) value["globalStageID"] = "ALL";
  if (value["stages"] === undefined) {
    value["stages"] = [];
  }
  return value;
});

declare module "../../qlab/store/store.ts" {
  export interface StoreTypes {
    "game": Type<Game, GameOperation>;
  }
}

export function GameSignals(signal: MutableRef<Game, GameOperation[]>) {
  return ({
    name: PropertyRef<Game, GameOperation, string, StringOperation>(
      value => value.name,
      (operations) => [{type: "update-name", operations}]
    )(signal),
    assets: PropertyRef<Game, GameOperation, AssetTreeItem[], TreeOperation<AssetTreeItem, AssetTreeItemOperation>>(
      value => value.assets,
      operations => [{type: "update-assets", operations}]
    )(signal),
    defaultSceneID: ValuePropertyRef<Game, GameOperation, Optional<SceneID>, ConstantOperation>(
      value => value.defaultSceneID,
      operations => [{type: "update-default-scene-i-d", operations}]
    )(signal),
    scenesRef: PropertyRef<Game, GameOperation, SceneTreeItem[], TreeOperation<SceneTreeItem, SceneTreeItemOperation>>(
      value => value.scenes,
      operations => [{type: "update-scenes", operations}]
    )(signal),
    owner: ValuePropertyRef<Game, GameOperation, Owner, OwnerOperation>(
      value => value.owner,
      operations => [{type: "update-owner", operations}]
    )(signal),
    turnTracker: PropertyRef<Game, GameOperation, TurnTracker, ValueOperation<TurnTracker, TurnTrackerOperation>>(
      value => value.turnTracker,
      operations => [{type: "update-turn-tracker", operations}]
    )(signal),
    players: MapPropertyRef<Game, GameOperation, UserID, Player, PlayerOperation>(
      value => value.players,
      operations => [{type: "update-players", operations}]
    )(signal),
    systems: PropertyRef<Game, GameOperation, GameSystems, GameSystemsOperation>(
      value => value.systems,
      operations => [{type: "update-systems", operations}]
    )(signal),
    invitations: ListPropertyRef<Game, GameOperation, Invitation, InvitationOperation>(
      value => value.invitations,
      operations => [{type: "update-invitations", operations}]
    )(signal),
    xCard: PropertyRef<Game, GameOperation, boolean, BooleanOperation>(
      value => value.xCard,
      operations => [{type: "update-x-card", operations}]
    )(signal),
    sensesRef: signal.map<Sense[], ListOperation<Sense, SenseOperation>[]>(
      value => value.senses,
      (_, operations) => [{type: "update-senses", operations}]
    ),
    globalStageIDRef: signal.map<StageID, ValueOperation<StageID, ConstantOperation>[]>(
      value => value.globalStageID,
      (_, operations) => [{type: "update-global-stage-i-d", operations}]
    ),
    stagesRef: signal.map<Stage[], ListOperation<Stage, StageOperation>[]>(
      value => value.stages,
      (_, operations) => [{type: "update-stages", operations}]
    )
  })
}

export function GameSignal(store: MutableRef<QLabDatabase, QLabDatabaseOperation[]>) {
  return store
    .map<Optional<Game>, GameOperation[]>(
      value => {
        if (value.store?.type !== "game") return undefined;
        return value.store.data;
      },
      (prev, operations) => {
        if (prev.store?.type !== "game") return [];
        return [{type: "store", operations: ValueFn.apply([{type: "game", operations}])}];
      }
    )
    .distinct();
}
