import {Size, SizeOperation, SizeSignals} from "common/types/index.ts";
import {InputGroup, InputNumber} from "#lib/components/index.ts";
import {useMemo} from "react";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";

export type SizeInputProps = {
  valueRef: MutableRef<Size, SizeOperation[]>;
};

export function SizeField({valueRef}: SizeInputProps) {
  const {width, height} = useMemo(() => SizeSignals(valueRef), [valueRef]);

  return (<Field>
    <FieldLabel>Size</FieldLabel>
    <div className="flex flex-row gap-0.5 mx-2 rounded-md overflow-hidden">
      <InputGroup className="flex-1">
        <InputGroupIcon>W</InputGroupIcon>
        <InputNumber value={width} />
      </InputGroup>
      <InputGroup className="flex-1">
        <InputGroupIcon>H</InputGroupIcon>
        <InputNumber size="small" value={height} />
      </InputGroup>
    </div>
  </Field>)
}