import {z} from "zod";
import {SenseID} from "../../../legends/game/sense/sense-i-d.ts";
import {SetOperation, SetType} from "../set/index.ts";

export const Mask = z.array(SenseID);
export type Mask = z.infer<typeof Mask>;

export const MaskOperation = SetOperation(SenseID);
export type MaskOperation = z.infer<typeof MaskOperation>;


class MaskType extends SetType<SenseID> {
  override migrateValue(value: any): SenseID[] {
    if (typeof value === "number") {
      if ((value & 1) > 0) return ["ALL", "GM"];
      if ((value & 2) > 0) return ["GM"];

      let values = [];
      let i = 0;
      let r = 1;
      while ((value ^ r) !== 0) {
        if ((value & r) !== 0) {
          values.push(`${i}`)
          value -= r;
        }
        i ++;
        r *= 2;
      }
      if (values.length === 10) return ["ALL"];
      return values;
    }
    return super.migrateValue(value);
  }
}

export const maskType = new MaskType();
