import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ShapeNode, ShapeNodeOperation, ShapeNodeSignals} from "common/legends/node/index.ts";
import {MutableRef} from "common/ref";
import {SelectionField} from "../../field/selection-field.tsx";
import {ShapeField} from "../../panel/properties/node/shape-field.tsx";
import {ColorAlphaField} from "#lib/components/input/color-alpha-field.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {ConditionsSection} from "../../panel/properties/node/conditions/conditions-section.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Panel, Section} from "#lib/components/panel-header.tsx";

export type ShapeNodePropertiesProps = {
  value: MutableRef<ShapeNode, ShapeNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function ShapeNodeProperties({value, reference, pinned}: ShapeNodePropertiesProps) {
  const {name, originRef, pivotRef, visibilityLayerRef, transformRef, selectionMaskRef, shapeRef, fillColorRef, conditionsRef} = useMemo(() => ShapeNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Shape Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <VisibleField value={visibilityLayerRef} />
        <SelectionField value={selectionMaskRef} />
        <TransformField value={transformRef} />
        <OriginField valueRef={originRef} />
        <PivotField valueRef={pivotRef} />
        <ShapeField valueRef={shapeRef} />
        <ColorAlphaField label="Color" valueRef={fillColorRef} />
      </Section>
      <ConditionsSection value={conditionsRef} />
    </Panel>
  </div>
}