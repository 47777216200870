export function KeyCache<Key extends WeakKey>(
  toString: (key: Key) => string = (key) => JSON.stringify(key)
) {
  const cache = new Map<string, WeakRef<Key>>();
  return (key: Key): Key => {
    const keyAsString = toString(key);
    if (cache.has(keyAsString)) {
      const value = cache.get(keyAsString)?.deref();
      if (value) return value;
    }

    cache.set(keyAsString, new WeakRef<Key>(key));
    return key;
  };
}
