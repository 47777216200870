import {
  applyAll,
  ListOperation,
  NumberFn,
  Optional,
  PointFn,
  SetOperation,
  Transform,
  TransformFn,
  TransformOperation,
  transformType,
  Tree,
  TreeOperation
} from "common/types/index.ts";
import {useGetAsset} from "#lib/qlab/index.ts";
import {KeyboardEvent, useCallback} from "react";
import {AssetOperationFn, Grid, Node, NodeId, NodeOperation, TokenOperation} from "common/legends/index.ts";
import {MutableRef, Ref} from "common/ref";
import {Vector2} from "common/math/vector/vector2.ts";
import {useNodePosToWorldPos} from "./use-node-pos-to-world-pos.ts";
import {AssetTokenSelectionRef, NodeSelectionRef, SceneSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useGoToNode} from "../../panel/nav/editor/use-go-to-node.ts";
import {useMoveElement} from "./use-move-element.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {useGetGrid} from "../../panel/properties/use-get-grid.ts";

export function onMovementHandlers(
  rootSelectionRef: SceneSelectionRef | AssetTokenSelectionRef,
  activeNodeIdRef: Ref<Optional<NodeId>>,
  nodeSelectionRef: MutableRef<NodeSelectionRef[], SetOperation<NodeSelectionRef>[]>,
  grid: Grid,
  nodesRef: MutableRef<Node[], TreeOperation<Node, NodeOperation>[]>,
  isAccessible: (node: Node) => boolean,
  isVisible: (node: Node) => boolean,
  viewRef: MutableRef<Transform, TransformOperation[]>
) {
  const access = useAccess();
  const getGrid = useGetGrid();
  const deleteSelectedNodes = useCallback(async () => {
    const nodes = nodeSelectionRef.value;
    for (const node of nodes) {
      if (node.type === "scene-node") {
        access.resource(node.resourceId).apply(prev => {
          if (prev?.type !== "scene") return [];
          const nodePath = Tree.getPath(prev.data.children, n => n.data.id === node.nodeId);
          if (nodePath === undefined) return [];
          return [{type: "scene", operations: [{type: "update-children", operations: TreeOperation.delete(
            nodePath,
            Tree.getNode(prev.data.children, nodePath)
          )}]}];
        })
      } else if (node.type === "asset-token-node") {
        access.resource(node.resourceId).apply(prev => {
          if (prev?.type !== "asset") return [];
          const tokenIndex = prev.data.tokens.findIndex(token => token.tokenID === node.tokenId);
          if (tokenIndex === -1) return [];
          const token = prev.data.tokens[tokenIndex];
          const nodePath = Tree.getPath(token.children, n => n.data.id === node.nodeId);
          if (nodePath === undefined) return [];
          return [{type: "asset", operations: AssetOperationFn.updateTokens(ListOperation.apply(
            tokenIndex,
            TokenOperation.updateChildren(TreeOperation.delete(
              nodePath,
              Tree.getNode(token.children, nodePath)
            ))
          ))}];
        });
      }
    }
  }, [access, nodeSelectionRef]);

  const nodePosToWorldPos = useNodePosToWorldPos(nodesRef);

  const moveElement = useMoveElement(
    nodesRef,
    rootSelectionRef,
    activeNodeIdRef,
    isVisible,
    isAccessible
  );

  const moveNode = useCallback(async (fn: (node: Node, grid: Grid) => TransformOperation[]) => {
    const selectedNodeRefs = nodeSelectionRef.value;
    const view = viewRef.value;
    const nodes = nodesRef.value;
    for (const nodeRef of selectedNodeRefs) {
      if (nodeRef.type !== "scene-node") continue;
      const grid = getGrid(nodeRef);
      const node = Tree.getItemById(nodes, nodeRef.nodeId);
      if (node === undefined) continue;
      const prev = node.data.transform;
      const next = applyAll(transformType, node.data.transform, fn(node, grid));
      moveElement(
        [nodeRef],
        view,
        [
          nodePosToWorldPos(node.data.id, prev.position),
          nodePosToWorldPos(node.data.id, next.position)
        ],
        true
      );
    }
  }, [nodeSelectionRef, isAccessible, isVisible, nodePosToWorldPos, nodesRef, viewRef, moveElement]);

  const rotate = useCallback(async (fn: (node: Node, transform: Transform, grid: Grid) => TransformOperation[]) => {
    const selectedNodeRefs = nodeSelectionRef.value;
    for (const nodeRef of selectedNodeRefs) {
      nodesRef.apply(nodes => {
        if (nodeRef.type !== "scene-node") return [];
        const nodePath = Tree.getPath(nodes, node => node.data.id === nodeRef.nodeId);
        if (nodePath === undefined) return [];
        const grid = getGrid(nodeRef);

        const node = Tree.getNode(nodes, nodePath);
        return TreeOperation.apply(nodePath, [{
          type: node.type,
          operations: [{
            type: "update-transform",
            operations: fn(node, node.data.transform, grid)
          }]
        }]);
      });
    }
  }, [nodeSelectionRef, isAccessible, isVisible, nodePosToWorldPos, nodesRef, viewRef, moveElement, getGrid]);

  const gotoNode = useGoToNode();
  const getAsset = useGetAsset();

  const onMovementKeyUp = useCallback((event: KeyboardEvent): boolean => {
    if (event.key === "Delete" || event.key === "Backspace") {
      deleteSelectedNodes();
      return false;
    } else if (event.key == "ArrowUp" || event.key === "w" || event.key === "W") {
      moveNode((_node, grid) => {
        return TransformFn.updatePosition([
          {type: "update-y", operations: NumberFn.increment(grid.height)}
        ])
      });
      return false;
    } else if (event.key === "ArrowDown" || event.key === "s" || event.key === "S") {
      moveNode((_node, grid) => {
        return TransformFn.updatePosition([
          {type: "update-y", operations: NumberFn.decrement(grid.height)}
        ])
      });
      return false;
    } else if (event.key === "ArrowLeft" || event.key === "a" || event.key === "A") {
      moveNode((_node, grid) => {
        return TransformFn.updatePosition([
          {type: "update-x", operations: NumberFn.decrement(grid.width)},
        ])
      });
      return false;
    } else if (event.key === "ArrowRight" || event.key === "d" || event.key === "D") {
      moveNode((_node, grid) => {
        return TransformFn.updatePosition([
          {type: "update-x", operations: NumberFn.increment(grid.width)},
        ])
      });
      return false;
    } else if (event.key === "q" || event.key === "Q") {
      rotate((node, _, grid) => {
        let {pivot, origin} = node.data;
        if (node.type === "token") {
          const asset = getAsset(node.data.tokenReference.assetID);
          const token = asset?.tokens.find(token => token.tokenID === node.data.tokenReference.tokenID);
          if (!token) return [];
          pivot = token.pivot;
          origin = token.origin;
        }

        const rotation = (grid.shape === "hexagon-vertical" || grid.shape === "hexagon-horizontal") ? 180 / 12 : 180 / 8 ;
        const prevPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          node.data.transform.rotation * Math.PI / 180
        );
        const nextPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          (node.data.transform.rotation + rotation) * Math.PI / 180
        );
        return [
          ...TransformFn.updatePosition(PointFn.set(node.data.transform.position, Vector2.add(
            node.data.transform.position,
            Vector2.subtract(prevPivot, nextPivot)
          ))),
          ...TransformFn.updateRotation(NumberFn.increment(rotation))
        ];
      });
      return false;
    } else if (event.key === "e" || event.key === "E") {
      rotate((node, _, grid) => {
        let {pivot, origin} = node.data;
        if (node.type === "token") {
          const asset = getAsset(node.data.tokenReference.assetID);
          const token = asset?.tokens.find(token => token.tokenID === node.data.tokenReference.tokenID);
          if (!token) return [];
          pivot = token.pivot;
          origin = token.origin;
        }

        const rotation = (grid.shape === "hexagon-vertical" || grid.shape === "hexagon-horizontal") ? -180 / 12 : -180 / 8 ;
        const prevPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          node.data.transform.rotation * Math.PI / 180
        );
        const nextPivot = Vector2.rotate(
          Vector2.multiply(Vector2.subtract(pivot, origin), node.data.transform.scale),
          (node.data.transform.rotation + rotation) * Math.PI / 180
        );
        return [
          ...TransformFn.updatePosition(PointFn.set(node.data.transform.position, Vector2.add(
            node.data.transform.position,
            Vector2.subtract(prevPivot, nextPivot)
          ))),
          ...TransformFn.updateRotation(NumberFn.increment(rotation))
        ];
      });
      return false;
    } else if (event.code === "Space") {
      const activeNodeId = activeNodeIdRef.value;
      if (activeNodeId) {
        gotoNode(activeNodeId);
      } else {
        const nodes = nodeSelectionRef.value;
        if (nodes.length > 0) {
          gotoNode(nodes[0].nodeId);
        }
      }
      return false;
    } else {
      return true;
    }
  }, [deleteSelectedNodes, moveNode, gotoNode, nodeSelectionRef, activeNodeIdRef, grid, moveElement, rotate]);

  return {onMovementKeyUp};
}
