import {MutableRef} from "common/ref";
import {StageID} from "common/legends/stage/stage-i-d.ts";
import {BooleanOperation, ConstantOperation, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useGame} from "../../../routes/game";
import {useMemo} from "react";
import {GameSignals} from "common/legends/game/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {Field} from "#lib/components/panel-header.tsx";
import {Stage} from "common/legends/stage/stage.ts";

function StageIDRadioListItem({
  stage,
  valueRef
}: {
  stage: Stage,
  valueRef: MutableRef<StageID, ValueOperation<StageID, ConstantOperation>[]>
}) {
  const itemRef = useMemo(() => valueRef.map<boolean, BooleanOperation[]>(
    value => value.includes(stage.stageID),
    (value, _) => ValueFn.set(value, stage.stageID)
  ), [valueRef, stage.stageID]);

  return <InputGroup key={stage.stageID} className="pl-0">
    <InputCheckbox type="radio" value={itemRef} />
    <InputGroupLabel>{stage.name}</InputGroupLabel>
  </InputGroup>
}

export function StageIDField({valueRef}: {valueRef: MutableRef<StageID, ValueOperation<StageID, ConstantOperation>[]>}) {
  const gameRef = useGame();
  const {stagesRef} = useMemo(() => GameSignals(gameRef), [gameRef]);
  const stages = useRefValue(stagesRef);

  const allRef = useMemo(() => valueRef.map<boolean, BooleanOperation[]>(
    value => value.includes("ALL"),
    (value, _) => ValueFn.set(value, "ALL")
  ), [valueRef]);

  return <>
    <Field className="mx-2 rounded-md overflow-hidden gap-0.5">
      <InputGroup className="pl-0">
        <InputCheckbox type="radio" value={allRef} />
        <InputGroupLabel>All</InputGroupLabel>
      </InputGroup>
      {stages.map(stage => <StageIDRadioListItem key={stage.stageID} stage={stage} valueRef={valueRef} />)}
    </Field>
  </>
}