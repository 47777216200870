import {DialogID, Layout, LayoutFn, LayoutOperation, LayoutPath, Stack, StackFn, StackId, StackItem, StackItemFn} from "../../modal/index.ts";
import {StackTabView} from "./stack-tab-view.tsx";
import {CSSProperties, Suspense, useCallback, useMemo} from "react";
import {ApplyAction} from "#lib/qlab/index.ts";
import {StackContentView} from "./stack-content-view.tsx";
import "./stack-view.css";
import {ListOperation, Optional, ValueFn} from "common/types/index.ts";
import {StackTabs} from "#lib/container/react/stack/stack-tabs.tsx";
import {Stacks} from "#lib/container/react/stack/stacks.ts";
import {ErrorBoundary} from "#lib/components/error-boundary.tsx";

export type StackViewProps<Value, Operation> = {
  style?: CSSProperties;
  dialogId?: DialogID;
  layoutPath: LayoutPath;
  StackContentView: StackContentView<Value, Operation>;
  StackTabView: StackTabView<Value>;
  Stacks: Stacks<Value>,
  value: Stack<Value>;
  apply: (action: ApplyAction<Optional<Layout<Value>>, LayoutOperation<Value, Operation>[]>) => Promise<Optional<Layout<Value>>>;
};

export function StackView<Value, Operation>({style, dialogId, layoutPath, value, apply, StackContentView, StackTabView, Stacks}: StackViewProps<Value, Operation>) {
  const items = value.items;
  const activeId = value.activeId;
  const selectedStackItem = useMemo<StackItem<Value> | undefined>(() => {
    for (const item of items) {
      if (item.id === activeId) return item;
    }
    return undefined;
  }, [items, activeId]);

  const activeIndex = items.findIndex(item => item.id === activeId);

  const selectedStackItemApply = useCallback(async (fn: ApplyAction<Optional<Value>, Operation[]>): Promise<Optional<Value>> => {
    const valueFn = (value: Optional<Layout<Value>>, layoutPath: LayoutPath, activeId: StackId | undefined): Optional<Value> => {
      if (value?.type === "column") {
        return valueFn(value.items[layoutPath[0]].content, layoutPath.slice(1), activeId);
      } else if (value?.type === "row") {
        return valueFn(value.items[layoutPath[0]].content, layoutPath.slice(1), activeId);
      }
      if (value?.type !== "stack") return undefined;
      for (const item of value.items) {
        if (item.id === activeId) return item.content;
      }
      return undefined;
    };
    return apply(prev => {
      if (!prev) return [];
      const content = valueFn(prev, layoutPath, activeId);
      if (content === undefined) return [];
      const operations = fn(content);
      if (operations.length == 0) return [];
      return LayoutFn.applyToStack(
        layoutPath,
        StackFn.updateItems(ListOperation.apply(
          activeIndex,
          StackItemFn.updateContent(ValueFn.apply(operations))
        ))
      );
    }).then(value => valueFn(value, layoutPath, activeId));
  }, [apply, layoutPath, activeId])

  return (<div className={`stack-view ${!!dialogId ? "stack-view-dialog" : "stack-view-layout"}`} style={style}>
    <div className="bg-zinc-900/50">
      <StackTabs dialogId={dialogId} layoutPath={layoutPath} value={value} apply={apply} StackTabView={StackTabView} Stacks={Stacks} />
    </div>
    {selectedStackItem && <ErrorBoundary><Suspense fallback={<></>}>
      <StackContentView key={selectedStackItem.id} value={selectedStackItem.content} apply={selectedStackItemApply} />
    </Suspense></ErrorBoundary>}
  </div>)
}