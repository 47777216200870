import {Transform} from "common/types/index.ts";
import React, {useMemo} from "react";
import {WallNode} from "common/legends/node/wall-node.ts";
import {ModelProvider, useModel} from "../../context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../context/opacity-context.ts";
import {getLinesFromWallGraph} from "../../../../common/walls/walls.ts";
import {WallLineShader} from "../../shader/wall-line-shader.tsx";
import {useIsSelected} from "../../context/use-is-selected.ts";
import {useIsTokenController} from "../../context/use-is-token-controller.ts";
import {ElementHUDPass} from "./element-h-u-d-pass.tsx";

export function WallElementHUDPass({value}: {
  value: WallNode;
}) {
  const model = useModel();
  const isSelected = useIsSelected(value.id);
  const isTokenController = useIsTokenController();
  const valueOpacity = value.opacity * useOpacity();
  let valueModel = Transform.divide(value.transform, model);
  const walls = useMemo(() => getLinesFromWallGraph(value.graph, p => p), [value.graph]);

  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {!isTokenController && isSelected && <WallLineShader origin={value.origin} lines={walls} scale={1} color={value.color} opacity={valueOpacity} editMode={isSelected}/>}
        {[...value.children].reverse().map((element) => <ElementHUDPass key={element.data.id} value={element} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}