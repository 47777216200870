import {MutableRef} from "common/ref";
import {StageID} from "common/legends/stage/stage-i-d.ts";
import {ConstantOperation, ValueFn, ValueOperation} from "common/types/generic/index.ts";
import {useCallback, useMemo} from "react";
import {GameSignals} from "common/legends/game/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {useGame} from "../../routes/game";
import {InputDropdown} from "#lib/components/dropdown/input-dropdown.tsx";

export function CurrentStageField({valueRef}: {valueRef: MutableRef<StageID, ValueOperation<StageID, ConstantOperation>[]>}) {
  const gameRef = useGame();
  const {stagesRef} = useMemo(() => GameSignals(gameRef), [gameRef]);
  const stages = useRefValue(stagesRef);

  const value = useRefValue(valueRef);

  const setValue = useCallback((stageID: StageID) => {
    valueRef.apply(prev => ValueFn.set(prev, stageID));
  }, [valueRef]);

  return (<Field>
    <FieldLabel>Current Stage</FieldLabel>
    <InputDropdown
      className="mx-2 rounded-md overflow-hidden"
      value={value}
      options={[
      {
        value: "ALL",
        label: "None"
      },
      ...stages.map(stage => ({
        value: stage.stageID,
        label: stage.name
      }))]}
      onChange={e => setValue(e.target.value as StageID)}
    />
  </Field>);
}
