import {useMemo} from "react";
import {SceneFn} from "common/legends/index.ts";
import {Outliner, OutlinerOperation, OutlinerSignals} from "../../common/outliner/outliner.ts";
import {OutlinerView} from "../../common/outliner/outliner-view.tsx";
import {SceneReference} from "common/legends/scene/scene-reference.ts";
import {useEditorSelectedNodeIDs} from "../../viewport/character";
import {MutableRef} from "common/ref";
import {useSuspendPresent} from "../../common/use-optional-signal.ts";
import {useSceneViewportProperties} from "../../viewport/scene/use-scene-viewport-properties.ts";
import {SceneViewportPropertiesSignals} from "../../viewport/scene";
import {useScene} from "../../viewport/token/use-scene.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {SceneSelectionRef, SelectionRef} from "../nav/editor/state/selection-ref.ts";

export function SceneOutlinerView({valueRef}: {
  valueRef: MutableRef<Outliner, OutlinerOperation[]>;
}) {
  const sceneViewportPropertiesRef = useSuspendPresent(useSceneViewportProperties());
  const {sceneReference} = useMemo(() => SceneViewportPropertiesSignals(sceneViewportPropertiesRef), [sceneViewportPropertiesRef]);
  const scene = useSuspendPresent(useScene(useRefValue(sceneReference).sceneID));

  const {expanded} = useMemo(() => OutlinerSignals(valueRef), [valueRef]);
  const {children} = useMemo(() => SceneFn.expand(scene), [scene]);

  const rootSelectionRef = useRefValue(useMemo(() => sceneReference
      .map((sceneReference: SceneReference): SceneSelectionRef => ({type: "scene", resourceId: sceneReference.sceneID}))
      .distinct(SelectionRef.equals), [sceneReference]));

  const selectedNodeIDsRef = useEditorSelectedNodeIDs();

  return (<OutlinerView
    rootSelectionRef={rootSelectionRef}
    valueRef={children}
    expanded={expanded}
    selectedNodeIdsRef={selectedNodeIDsRef}
  />);
}
