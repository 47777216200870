import {Asset, AssetID, AssetOperation, Token, TokenID, TokenOperation} from "common/legends/index.ts";
import {ListOperation, Optional} from "common/types/index.ts";
import {useMemo} from "react";
import {fromSignal, MutableRef} from "common/ref";
import {useAccess} from "../../../routes/game/model/store-context.tsx";

export function useAsset(assetID: Optional<AssetID>): MutableRef<Optional<Asset>, AssetOperation[]> {
  const access = useAccess();
  return useMemo(() => fromSignal(access.asset(assetID)), [assetID]);
}

export function useAssetToken(character: MutableRef<Asset, AssetOperation[]>, tokenID: TokenID): MutableRef<Optional<Token>, TokenOperation[]> {
  return useMemo(() => {
    const valueFn = (value: Asset): Optional<Token> => {
      return value.tokens.find(token => token.tokenID === tokenID);
    };
    const mapFn = (prev: Optional<Asset>, operations: TokenOperation[]): AssetOperation[] => {
      const tokenIndex = prev ? prev.tokens.findIndex(token => token.tokenID === tokenID) : -1;
      if (tokenIndex === -1) return [];
      return [{type: "update-tokens", operations: ListOperation.apply(tokenIndex, operations)}];
    };
    return character.map(valueFn, mapFn);
  }, [tokenID]);
}
