import {Node, NodeOperation} from "common/legends/index.ts";
import {ConstantOperation, Optional, Tree, TreeOperation, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {NodeProperties} from "../node/node-properties.tsx";
import {useDatabase} from "../../../../routes/game/model/store-context.tsx";
import {QLabDatabase} from "common/qlab/index.ts";
import {usePresent} from "../../../common/use-optional-signal.ts";
import {MutableRef} from "common/ref";
import {SceneNodeSelectionRef, SelectionRef} from "../../nav/editor/state/selection-ref.ts";

function useSceneNode(reference: SceneNodeSelectionRef): MutableRef<Optional<Node>, NodeOperation[]> {
  const database = useDatabase();
  return useMemo((): MutableRef<Optional<Node>, NodeOperation[]> => {
    return database.map(
      (value: QLabDatabase) => {
        const resource = value.resources[reference.resourceId];
        if (resource?.type !== "scene") return undefined;
        return Tree.getItemById(resource.data.children, reference.nodeId);
      },
      (prev, operations: NodeOperation[]) => {
        const resource = prev.resources[reference.resourceId];
        if (resource?.type !== "scene") return [];
        const path = Tree.getPath(resource.data.children, node => node.data.id === reference.nodeId);
        if (path === undefined) return [];
        return [{
          type: "resource",
          resourceID: reference.resourceId,
          operations: [{type: "apply", operations: [{type: "scene", operations: [{
                type: "update-children", operations: TreeOperation.apply(path, operations)
              }]}]}]
        }];
      }
    ).distinct()
  }, [database, reference.resourceId, reference.nodeId]);
}

export type NodeFieldProps = {
  reference: SceneNodeSelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};
export function SceneNodePropertiesView({reference, pinned}: NodeFieldProps) {
  const sceneNode = useSceneNode(reference);
  const node = usePresent(sceneNode);
  if (!node) return <></>;
  return <NodeProperties key={reference.nodeId} value={node} reference={reference} pinned={pinned} />
}