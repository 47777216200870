import {QLabResourceID, ResourceType, ResourceTypes} from "common/qlab/index.ts";
import {ExtractValue} from "common/types/index.ts";
import {useCallback} from "react";
import {filter, map, toPromise} from "common/observable";
import {pipe} from "common/pipe";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";
import {Asset, AssetID} from "common/legends/asset/index.ts";

export function useGetResource() {
  const databaseRef = useDatabase();
  return useCallback(<Type extends ResourceType, Value = ExtractValue<ResourceTypes[Type]>>(type: Type, resourceId: QLabResourceID): Promise<Value> => {
    return pipe(
      databaseRef.observe,
      filter(store => {
        const resource = store.resources[resourceId];
        return resource?.type === type;
      }),
      map(store => store.resources[resourceId]!.data as unknown as Value),
      toPromise
    );
  }, [databaseRef]);
}

export function useGetAsset() {
  const databaseRef = useDatabase();
  return useCallback((assetID: AssetID): Asset | undefined => {
    const database = databaseRef.value;
    const resource = database.resources[assetID];
    if (resource?.type === "asset") return resource.data;
    return undefined;
  }, [databaseRef]);
}
