import {computed, MutableSignal} from "#common/signal";
import {AssetID, Token, TokenID, TokenOperation} from "#common/legends/index.ts";
import {WeakCache} from "../../weak-cache.ts";
import {AssetCache} from "./asset-cache.ts";
import {Optional} from "#common/types/index.ts";
import {KeyCache} from "#common/legends/access/key-cache.ts";

type AssetTokenReference = {assetID: AssetID, tokenID: TokenID};
const AssetTokenReferenceKeyCache = KeyCache<AssetTokenReference>(({assetID, tokenID}) => `${assetID}-${tokenID}`);

export type OptionalTokenSignal = MutableSignal<Optional<Token>, TokenOperation[]>;
export type AssetTokenCache = (assetID: AssetID, tokenID: TokenID) => OptionalTokenSignal;
export function AssetTokenCache(assetCache: AssetCache): AssetTokenCache {
  const cache = new WeakCache<AssetTokenReference, OptionalTokenSignal>(({assetID, tokenID}) => {
    const asset = assetCache(assetID);
    return computed(
      (): Optional<Token> => {
        if (asset.value) {
          return asset.value.tokens.find(token => token.tokenID === tokenID);
        } else {
          return undefined;
        }
      },
      (operations: TokenOperation[]) => asset.apply(asset => {
        if (asset === undefined) return [];
        const tokenIndex = asset.tokens.findIndex(token => token.tokenID === tokenID);
        if (tokenIndex === -1) return [];
        return [{type: "update-tokens", operations: [{
            type: "apply",
            index: tokenIndex,
            operations
        }]}]
      })
    )
  });

  return (assetID, tokenID) => cache.get(AssetTokenReferenceKeyCache({assetID, tokenID}));
}
