import {TextNode} from "common/legends/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {SelectionIndicator} from "../../viewport/common/hud-pass/element/selection-indicator.tsx";
import {getTextHeight, getTextOrigin, getTextWidth} from "#lib/gl-react/component/font-helper.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useIsSelected} from "../../viewport/common/context/use-is-selected.ts";

export function TextElementHUDPass({value: {id, children, origin, transform, pivot, text, size, hTextAlign, vTextAlign}}: {
  value: TextNode;
}) {
  const {projection, view, model} = usePVM();
  const valueModel = Transform.divide(transform, model);
  const isSelected = useIsSelected(id);

  const width = getTextWidth(text, size);
  const height = getTextHeight(text, size);
  const textOrigin = getTextOrigin(text, size, hTextAlign, vTextAlign);

  return (<binder>
    <ModelProvider value={valueModel}>
      {[...children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
      {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={textOrigin} size={[width, height]} color={Color.WHITE} repeatX={1} repeatY={1} />}
      {isSelected && <SelectionIndicator projection={projection} view={view} model={valueModel} origin={[origin[0] - pivot[0] + 2, origin[1] - pivot[1] + 2]} size={[4, 4]} color={Color.GREEN} repeatX={1} repeatY={1} />}
    </ModelProvider>
  </binder>);
}