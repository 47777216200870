import React, {PropsWithChildren, useCallback, useState} from "react";
import {TextureMaskShader} from "../shader/texture-mask-shader.tsx";
import {useRenderPassTexture} from "../context/render-pass-texture-context.ts";
import {Framebuffer} from "../shader/framebuffer.tsx";
import {BlendFuncSeperate} from "#lib/gl-react/component/opengl/blend-func-seperate.tsx";
import {BlendEquationSeparate} from "#lib/gl-react/component/opengl/blend-equation-separate.tsx";

export function LightTextureShader({children}: PropsWithChildren<{}>) {
  const [renderingTexture] = useRenderPassTexture();
  const clear = useCallback((context: WebGL2RenderingContext) => {
    context.clearColor(0, 0, 0, 0);
    context.clear(WebGL2RenderingContext.COLOR_BUFFER_BIT);
  }, []);

  const [texture, setTexture] = useState<WebGLTexture | null>(null);
  return <>
    <Framebuffer ref={setTexture}>
      <action onAction={clear} />
      <BlendFuncSeperate
        srcRGB={WebGL2RenderingContext.SRC_COLOR} dstRGB={WebGL2RenderingContext.DST_COLOR}
        srcAlpha={WebGL2RenderingContext.SRC_ALPHA} dstAlpha={WebGL2RenderingContext.DST_ALPHA}>
        <BlendEquationSeparate rgb={WebGL2RenderingContext.FUNC_ADD} alpha={WebGL2RenderingContext.MAX}>
          {children}
        </BlendEquationSeparate>
      </BlendFuncSeperate>
    </Framebuffer>
    {texture && <binder>
      <BlendFuncSeperate
        srcRGB={WebGL2RenderingContext.SRC_ALPHA} dstRGB={WebGL2RenderingContext.ONE}
        srcAlpha={WebGL2RenderingContext.ONE} dstAlpha={WebGL2RenderingContext.ONE}>
        <TextureMaskShader texture={renderingTexture} mask={texture}/>
      </BlendFuncSeperate>
    </binder>}
  </>
}