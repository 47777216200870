import {HSLA, Transform} from "common/types/index.ts";
import React from "react";
import {AreaNode} from "common/legends/node/area-node.ts";
import {ModelProvider, useModel} from "../../viewport/common/context/pvm-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {AreaShader} from "../../viewport/common/shader/shape/area-shader.tsx";
import {useIsTokenController} from "../../viewport/common/context/use-is-token-controller.ts";

export function AreaElementHUDPass({value}: {
  value: AreaNode;
}) {
  const isTokenController = useIsTokenController();
  const valueOpacity = value.opacity * useOpacity();
  const model = useModel();
  let valueModel = Transform.divide(value.transform, model);
  return (<binder>
    <OpacityProvider value={valueOpacity}>
      <ModelProvider value={valueModel}>
        {!isTokenController && value.areas.map((area, index) =>
          <AreaShader key={index} origin={value.origin} spline={area} scale={1} color={[value.color[0], value.color[1], value.color[2], valueOpacity] as HSLA} />
        )}
        {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
      </ModelProvider>
    </OpacityProvider>
  </binder>);
}