import {Player, PlayerOperation, UserID} from "#common/legends/index.ts";
import {QLabDatabaseOperation, QLabDatabaseSignal} from "#common/qlab/index.ts";
import {computed, MutableSignal} from "#common/signal";
import {WeakCache} from "#common/legends/access/weak-cache.ts";
import {Optional, ValueFn} from "#common/types/index.ts";
import {OwnerOperation} from "#common/legends/game/owner/index.ts";

export type PlayerSignal = MutableSignal<Optional<Player>, PlayerOperation[]>;

export function PlayersCache(databaseRef: QLabDatabaseSignal) {
  const cache = new WeakCache<UserID, PlayerSignal>((playerID) => computed(
    (): Optional<Player> => {
      const store = databaseRef.value.store;
      if (store?.type === "game") {
        if (store.data.owner.id === playerID) {
          return {
            ...store.data.owner,
            gameMaster: true
          };
        } else {
          return store.data.players[playerID];
        }
      }
    },
    (operations: PlayerOperation[]) => databaseRef.apply((database): QLabDatabaseOperation[] => {
      if (database.store?.type !== "game") return [];

      if (database.store.data.owner.id === playerID) {
        return [{
          type: "store",
          operations: [{
            type: "apply",
            operations: [{
              type: "game",
              operations: [{
                type: "update-owner",
                operations: ValueFn.apply(operations.flatMap(operation => {
                  if (operation.type === "update-game-master") return [];
                  return [operation]
                }) as OwnerOperation[])
              }]
            }]
          }]
        }];
      } else if (database.store.data.players[playerID]) {
        return [{
          type: "store",
          operations: [{
            type: "apply",
            operations: [{
              type: "game",
              operations: [{
                type: "update-players",
                operations: [{
                  type: "apply",
                  key: playerID,
                  operations
                }]
              }]
            }]
          }]
        }];
      } else return [];
    }))
  );

  return (playerID: UserID) => cache.get(playerID);
}