import {GameID, Node, NodeId, PlayerRef, Scene, SceneFn, SceneID, SceneOperation, Token} from "common/legends/index.ts";
import {HSLA, Optional, Point, PointFn} from "common/types/index.ts";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {TokenViewportPropertiesFn} from "./token-viewport-properties.ts";
import {useInstance, useObservable} from "#lib/qlab/index.ts";
import {Matrix4f} from "#lib/math/index.ts";
import {pipe} from "common/pipe";
import {distinct, listIdentity, map, subscribe} from "common/observable";
import {measurementType} from "common/legends/measurement/index.ts";
import {userIDRef, useUserID} from "#lib/auth/use-get-user-id.ts";
import {PlayerControllerProvider, usePlayerController} from "../../panel/nav/player/player-controller-provider.ts";
import {useActiveControllerReferenceNode} from "./use-active-controller-node-reference.ts";
import {useNode} from "./use-node.ts";
import {useNodeTreeIDs} from "./use-node-tree-i-ds.ts";
import {NodeDropZone, useTempRef} from "#lib/components/index.ts";
import {contextMenuType} from "../../../routes/game/context-menu/context-menu.ts";
import {usePlayer} from "../../../routes/game/use-player.ts";
import {useToolHandlers} from "../../../routes/game/use-tool-handlers.ts";
import {usePlayerToolMode} from "../../panel/nav/player/use-player-tool-mode.ts";
import {useSceneFocus} from "./use-scene-focus.ts";
import {LegendsWebGL2Canvas} from "../../../routes/game/index.ts";
import {SelectedNodeIDsProvider} from "../common/context/selected-node-i-ds.ts";
import {ClearCanvas} from "../../../routes/game/view/main/clear-canvas.tsx";
import {getElementOrigin, SceneView} from "../scene/scene-view.tsx";
import {ScenePingView} from "../common/hud-pass/scene-ping.tsx";
import {MeasurementView} from "../common/hud-pass/measurement/measurement-view.tsx";
import {SceneMeasurementView} from "../common/hud-pass/scene-measurement.tsx";
import {useGetNode} from "../../common/node/use-get-node.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {ToolView} from "../common/hud-pass/tool/tool-view.tsx";
import {MutableRef, Ref} from "common/ref";
import {CurrentSceneProvider} from "../scene/current-scene-provider.ts";
import {isTokenElementOwner} from "../../common/node/use-is-party-node.ts";
import {GridProvider} from "../common/context/grid-context.ts";
import {SceneSelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useGoToNode} from "../../panel/nav/editor/use-go-to-node.ts";
import {EditorContextMenu} from "../../panel/nav/editor/editor-context-menu/editor-context-menu.tsx";
import {ProjectionProvider, ViewProvider} from "../common/context/pvm-context.ts";
import {Vision, VisionFn} from "common/legends/asset/token/vision/vision.ts";
import {useToken} from "./use-token.ts";
import {ControllerNodeIDProvider} from "../common/context/controller-node-context.ts";
import {AccessMaskFn} from "common/legends/visibility/index.ts";
import {useActiveControllerView} from "./use-active-controller-view.ts";
import {useCurrentStage} from "./use-current-stage.ts";

export function TokenViewport({storeId, sceneID, scene}: {
  storeId: GameID;
  sceneID: SceneID,
  scene: MutableRef<Scene, SceneOperation[]>
}) {
  const {children} = useMemo(() => SceneFn.expand(scene), [scene]);
  const sceneValue = useRefValue(scene);

  const [size, setSize] = useState<[number, number]>([0, 0]);
  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const element = divRef?.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setSize([element.clientWidth, element.clientHeight]);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.disconnect();
  }, [setSize, divRef]);

  const playerController = usePlayerController();
  const {activeController, selectedControllerNodeIds, toolMode} = useMemo(() => TokenViewportPropertiesFn.expand(playerController.state), [playerController.state]);

  const viewRef = useActiveControllerView();

  const getNode = useGetNode();
  const userID = useUserID()!;

  const activeTokenNode = useActiveControllerReferenceNode();

  // Automatically switch characters when selecting owned node when stage is active
  const stageID = useCurrentStage(userID);
  useEffect(() => {
    return pipe(
      selectedControllerNodeIds.observe,
      subscribe(async (selectedNodeIDs) => {
        if (selectedNodeIDs.length === 1) {
          const nodeRef = selectedNodeIDs[0];
          const node = getNode(nodeRef.nodeId);
          if (node?.type === "token" && isTokenElementOwner(node, userID, stageID)) {
            if (activeTokenNode.value === nodeRef.nodeId) return;
            activeTokenNode.apply(prevValue => {
              if (prevValue === nodeRef.nodeId) return [];
              return [{type: "set", prevValue, nextValue: nodeRef.nodeId}];
            });
          }
        }
      })
    );
  }, [selectedControllerNodeIds, getNode, activeTokenNode, stageID]);

  // If the scene is newly loaded, go to controller node!
  const goToNode = useGoToNode();
  const activeNodeID = useRefValue(activeTokenNode);
  useEffect(() => {
    // wait a tick to ensure databaseRef is updated
    setTimeout(() => {
      if (activeNodeID) goToNode(activeNodeID);
    }, 0);
  }, [scene, goToNode]);

  const activeNodeIdRef = useMemo((): Ref<Optional<NodeId>> => new MutableRef({
    value() {
      return activeController.value?.controllerNodeID;
    },
    observe: pipe(
      activeController.observe,
      map(value => value?.controllerNodeID),
      distinct()
    ),
    apply: () => Promise.reject("Unsupported")
  }), [activeController]);

  const rootSelectionRef = useMemo<SceneSelectionRef>(() => ({type: "scene", storeId, resourceId: sceneID}), [storeId, sceneID]);

  const instance = useInstance();
  const ping = useCallback((p: Point, focus: boolean, color: HSLA) => {
    instance.stream(storeId, {type: "ping", data: {
      type: "scene",
      sceneID: sceneID,
      position: p,
      focus,
      color
    }}).catch(console.error);
  }, [instance, storeId, sceneID]);

  const nodeID = useRefValue(activeTokenNode);
  const node = useNode(nodeID);
  const tokenReferenceRef = useMemo(() => node.map(node => {
    if (node?.type === "token") {
      return node.data.tokenReference;
    } else {
      return undefined;
    }
  }), [node]);
  const tokenRef = useToken(tokenReferenceRef);
  const visibleNodeIDsRef = useNodeTreeIDs(activeTokenNode);
  const visionRef = useMemo((): Ref<Vision[]> => {
    const valueFn = (n: Optional<Token>, visibilityNodeIDs: NodeId[]): Vision[] => {
      if (!n) return [{
        offset: [0, 0],
        accessMask: ["ALL"],
        visibilityNodeIDs: visibilityNodeIDs,
        accessibleNodeIDs: [],
        grayscale: false,
        limit: undefined
      }];

      return n.vision.map(vision => ({
        offset: vision.offset,
        accessMask: vision.senseID ? ["ALL", vision.senseID] : ["ALL"],
        visibilityNodeIDs: visibilityNodeIDs,
        accessibleNodeIDs: [],
        grayscale: vision.grayscale,
        limit: vision.limit
      }));
    }
    return MutableRef
      .all(tokenRef, visibleNodeIDsRef)
      .map(([token, visibilityNodeIDs]) => valueFn(token, visibilityNodeIDs))
      .distinct((a, b) => listIdentity(a, b, VisionFn.equals));
  }, [tokenRef, visibleNodeIDsRef]);

  const vision = useRefValue(visionRef);
  const measurement = useTempRef(measurementType, undefined);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const contextMenu = useTempRef(contextMenuType, undefined);

  const player = usePlayer(useUserID()!);
  const {colorRef} = useMemo(() => PlayerRef(player), [player]);

  const isAccessible = useCallback((node: Node) => {
    return node.type === "wall" || node.type === "area" || visionRef.value.some(vision => AccessMaskFn.canSee(vision.accessMask, node.data.selectionMask)) || activeTokenNode.value === node.data.id || isTokenElementOwner(node, userIDRef.value!, stageID);
  }, [visionRef, activeTokenNode, stageID]);
  const isVisible = useCallback((node: Node) => vision.some(vision => VisionFn.canSee(vision, node)), [vision]);
  const {onDrop, onWheel, onTouchStart, onTouchMove, onTouchEnd, ...toolHandlers} = useToolHandlers(
    canvasRef,
    rootSelectionRef,
    viewRef,
    measurement,
    sceneValue.grid,
    children,
    selectedControllerNodeIds,
    activeNodeIdRef,
    usePlayerToolMode(),
    ping,
    useObservable(colorRef.observe, [0, 1, 1, 1] as HSLA, [colorRef]),
    contextMenu,
    isAccessible,
    isVisible
  );

  useSceneFocus(storeId, sceneID, viewRef.apply);

  const [width, height] = size;
  const projection = useMemo<Matrix4f>(() => Matrix4f.orthogonal(
    -width/2, width/2,
    height/2, -height/2,
    -100, 100
  ), [width, height]);

  useEffect(() => {
    const onBodyWheel = (ev: WheelEvent) => {
      if (ev.ctrlKey) return onWheel(ev);
      return true;
    };
    document.body.addEventListener("wheel", onBodyWheel, {passive: false});
    canvasRef.current?.addEventListener("wheel", onWheel, {passive: false});
    canvasRef.current?.addEventListener("touchstart", onTouchStart, {passive: false});
    canvasRef.current?.addEventListener("touchend", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchcancel", onTouchEnd, {passive: false});
    canvasRef.current?.addEventListener("touchmove", onTouchMove, {passive: false});
    return () => {
      document.body.removeEventListener("wheel", onBodyWheel);
      canvasRef.current?.removeEventListener("wheel", onWheel);
      canvasRef.current?.removeEventListener("touchstart", onTouchStart);
      canvasRef.current?.removeEventListener("touchend", onTouchEnd);
      canvasRef.current?.removeEventListener("touchmove", onTouchMove);
      canvasRef.current?.removeEventListener("touchcancel", onTouchEnd);
    }
  }, [canvasRef, onWheel, onTouchStart, onTouchMove, onTouchEnd]);

  const origin = (activeNodeID ? getElementOrigin(activeNodeID, sceneValue.children) : null ) ?? PointFn.ZERO;

  const view = useRefValue(viewRef);
  return (<div className="relative flex-1 overflow-hidden" ref={divRef}>
    <CurrentSceneProvider value={sceneID}>
      <NodeDropZone onDrop={onDrop} contextMenu={contextMenu}>
        <LegendsWebGL2Canvas ref={canvasRef} width={size[0]} height={size[1]} tabIndex={0} {...toolHandlers}>
          <PlayerControllerProvider value={usePlayerController()}>
            <ControllerNodeIDProvider value={nodeID}>
              <SelectedNodeIDsProvider value={selectedControllerNodeIds}>
                <ProjectionProvider value={projection}>
                  <ViewProvider value={view}>
                    <GridProvider value={sceneValue.grid}>
                      <ClearCanvas width={size[0]} height={size[1]}/>
                      <SceneView origin={origin} vision={vision} scene={sceneValue} />
                      <ScenePingView gameID={storeId} sceneID={sceneID} />
                      <MeasurementView measurementRef={measurement} rootRef={rootSelectionRef} />
                      <SceneMeasurementView gameID={storeId} sceneID={sceneID} scene={sceneValue} vision={vision} />
                      <ToolView value={toolMode} nodes={children} />
                    </GridProvider>
                  </ViewProvider>
                </ProjectionProvider>
              </SelectedNodeIDsProvider>
            </ControllerNodeIDProvider>
          </PlayerControllerProvider>
        </LegendsWebGL2Canvas>
      </NodeDropZone>
      <EditorContextMenu value={contextMenu} onDrop={onDrop} />
    </CurrentSceneProvider>
  </div>);
}
