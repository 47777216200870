import {InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {InputCheckbox} from "#lib/components/input/input-checkbox.tsx";
import {BooleanOperation} from "common/types/generic/index.ts";
import {MutableRef} from "common/ref";

export function BooleanField({valueRef, label}: {
  label: string,
  valueRef: MutableRef<boolean, BooleanOperation[]>
}) {
  return (
    <InputGroup className="pl-0 mx-2 rounded-md overflow-hidden">
      <InputCheckbox value={valueRef} />
      <InputGroupLabel>{label}</InputGroupLabel>
    </InputGroup>
  );
}
