import {
  BooleanOperation,
  booleanType,
  ColorOperation,
  colorType,
  ConstantOperation, constantType,
  ConstantType,
  FileReference,
  FileReferenceOperation,
  fileReferenceType,
  HSLA,
  ObjectType,
  PropertyRef,
  StringOperation,
  stringType,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {PublicKey} from "#common/crypto/index.ts";
import {MutableRef} from "#common/ref";
import {StageID} from "#common/legends/stage/stage-i-d.ts";

const COLORS: HSLA[] = [
  [0.0000, 0.75, 0.75, 1] as HSLA,
  [0.0625, 0.75, 0.75, 1] as HSLA,
  [0.1250, 0.75, 0.75, 1] as HSLA,
  [0.1875, 0.75, 0.75, 1] as HSLA,
  [0.2500, 0.75, 0.75, 1] as HSLA,
  [0.3125, 0.75, 0.75, 1] as HSLA,
  [0.3750, 0.75, 0.75, 1] as HSLA,
  [0.4375, 0.75, 0.75, 1] as HSLA,
  [0.5000, 0.75, 0.75, 1] as HSLA,
  [0.5625, 0.75, 0.75, 1] as HSLA,
  [0.6250, 0.75, 0.75, 1] as HSLA,
  [0.6875, 0.75, 0.75, 1] as HSLA,
  [0.7500, 0.75, 0.75, 1] as HSLA,
  [0.8125, 0.75, 0.75, 1] as HSLA,
  [0.8750, 0.75, 0.75, 1] as HSLA,
  [0.9375, 0.75, 0.75, 1] as HSLA,
];

export type Player = {
  publicKey: PublicKey;
  name: string;
  color: HSLA;
  icon: FileReference;
  stageID: StageID | undefined;
  gameMaster: boolean;
};
export type PlayerOperation =
  | {type: "update-public-key", operations: ValueOperation<PublicKey, ConstantOperation>[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-color", operations: ColorOperation[]}
  | {type: "update-icon", operations: FileReferenceOperation[]}
  | {type: "update-game-master", operations: BooleanOperation[]}
  | {type: "update-stage-i-d", operations: ValueOperation<StageID | undefined, ConstantOperation>[]}
  ;
export const PlayerOperation = {
  updateName: (operations: StringOperation[]): PlayerOperation[] => [{type: "update-name", operations}]
};

export const playerType: Type<Player, PlayerOperation> = new ObjectType({
  publicKey: new ValueType(new ConstantType<PublicKey>()),
  icon: fileReferenceType,
  color: colorType,
  name: stringType,
  stageID: new ValueType(constantType),
  gameMaster: booleanType
}, (value: any) => {
  if (value.color === undefined) value.color = COLORS[Math.floor(COLORS.length * Math.random())];
  if (value.gameMaster === undefined) value.gameMaster = false;

  return value;
});

export function PlayerRef(ref: MutableRef<Player, PlayerOperation[]>) {
  return {
    nameRef: PropertyRef<Player, PlayerOperation, string, StringOperation>(
      value => value.name,
      operations => [{type: "update-name", operations}]
    )(ref),
    iconRef: PropertyRef<Player, PlayerOperation, FileReference, FileReferenceOperation>(
      value => value.icon,
      operations => [{type: "update-icon", operations}]
    )(ref),
    colorRef: PropertyRef<Player, PlayerOperation, HSLA, ColorOperation>(
      value => value.color,
      operations => [{type: "update-color", operations}]
    )(ref),
    stageIDRef: ref.map<StageID | undefined, ValueOperation<StageID | undefined, ConstantOperation>[]>(
      value => value.stageID,
      (_, operations) => [{type: "update-stage-i-d", operations}]
    ),
    gameMasterRef: PropertyRef<Player, PlayerOperation, boolean, BooleanOperation>(
      value => value.gameMaster,
      operations => [{type: "update-game-master", operations}]
    )(ref)
  };
}
export const PlayerFn = {
  NOOP: {
    name: "",
    color: [0, 1, 1, 1] as HSLA,
    stageID: undefined,
    icon: undefined,
    publicKey: ["", ""] as PublicKey,
    gameMaster: false
  } satisfies Player
}

