import {QLabDatabaseSignal, QLabStoreID} from "common/qlab/index.ts";
import {useInstance} from "#lib/qlab/index.ts";
import {useEffect, useState} from "react";
import {Loader} from "common/loader";
import {createQLabDatabase} from "./create-q-lab-database.ts";

export function useQLabSignal(storeID: QLabStoreID): QLabDatabaseSignal | undefined {
  const qlabInstance = useInstance();
  const [loader, setLoader] = useState<Loader<QLabDatabaseSignal>>(Loader.loading);
  useEffect(() => {
    setLoader(Loader.loading);
    createQLabDatabase(qlabInstance, storeID)
      .then(signal => setLoader(Loader.loaded(signal)));
    return () => {};
  }, [qlabInstance, storeID]);
  return loader.isLoading ? undefined : loader.data;
}
