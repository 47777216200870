import {z} from "zod";
import {generateStageID, StageID} from "#common/legends/stage/stage-i-d.ts";
import {ConstantOperation, constantType, ObjectType, StringOperation, stringType} from "#common/types/index.ts";
import {MutableRef} from "#common/ref";

export const Stage = z.object({
  stageID: StageID,
  name: z.string()
});
export type Stage = z.infer<typeof Stage>;

export const StageOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-stage-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)})
]);
export type StageOperation = z.infer<typeof StageOperation>;


export const stageType = new ObjectType({
  stageID: constantType,
  name: stringType
});

export const StageFn = {
  getStageID(stage: Stage) {
    return stage.stageID;
  },
  copyStage(stage: Stage) {
    return ({
      ...stage,
      stageID: generateStageID()
    })
  },
  expand(signal: MutableRef<Stage, StageOperation[]>) {
    return ({
      nameRef: signal.map<string, StringOperation[]>(
        value => value.name,
        (_, operations) => [{type: "update-name", operations}]
      )
    });
  }
} as const;