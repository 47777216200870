export function IdentityCache<Key extends WeakKey, Value>(
  equals: (a: Value, b: Value) => boolean
) {
  const cache = new WeakMap<Key, Value>();
  return (key: Key, fn: () => Value): Value => {
    const nextValue = fn();
    if (cache.has(key)) {
      const lastValue = cache.get(key);
      if (lastValue !== undefined && equals(lastValue, nextValue)) {
        return lastValue;
      }
    }
    cache.set(key, nextValue);
    return nextValue;
  };
}
