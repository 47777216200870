import {GridNode, GridNodeOperation, GridNodeSignals} from "common/legends/index.ts";
import {ConstantOperation, Optional, OptionalOperation, Size, SizeOperation, ValueFn, ValueOperation} from "common/types/index.ts";
import {useMemo} from "react";
import {ButtonBar, ExpandableCheckbox, InputGroup, InputGroupRange} from "#lib/components/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {pipe} from "common/pipe";
import {distinct, map} from "common/observable";
import {useObservable} from "#lib/qlab/index.ts";
import {NameField} from "../name-field.tsx";
import {VisibilityLayerField} from "../visibility-layer-field.tsx";
import {InputTransform} from "./transform/input-transform.tsx";
import {SizeField} from "../size-field.tsx";
import {MutableRef} from "common/ref";
import {ColorAlphaField} from "#lib/components/input/color-alpha-field.tsx";
import {SelectionRef} from "../../nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section, SectionHeader} from "#lib/components/panel-header.tsx";

export type GridNodePropertiesProps = {
  value: MutableRef<GridNode, GridNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
};

export function GridNodeProperties({value, reference, pinned}: GridNodePropertiesProps) {
  const {name, visibilityLayer, color, size, thickness, noise, transform} = useMemo(() => GridNodeSignals(value), [value]);

  return (<div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Grid Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />

        <VisibilityLayerField value={visibilityLayer} />

        <ColorAlphaField label="Color" valueRef={color} />

        <InputTransform value={transform} />

        <LimitsField value={size} />

        <Field>
          <FieldLabel>Thickness</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputGroupRange min={0} max={1} step={1/32} value={thickness} />
          </InputGroup>
        </Field>
        <Field>
          <FieldLabel>Noise</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputGroupRange min={0} max={1} step={1/32} value={noise} />
          </InputGroup>
        </Field>
      </Section>
    </Panel>
  </div>);
}

export type LimitsFieldProps = {
  value: MutableRef<Optional<Size>, ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>[]>;
};

export function LimitsField({value}: LimitsFieldProps) {
  const size = useMemo((): MutableRef<Size, SizeOperation[]> =>
    value.map(
      value => value || [0, 0],
      (prev: Optional<Size>, operations: SizeOperation[]): ValueOperation<Optional<Size>, OptionalOperation<SizeOperation>>[] => {
        if (prev === undefined || operations.length === 0) return [];
        return ValueFn.apply(operations);
    }), [value]);
  const expanded = useObservable(pipe(
    value.observe,
    map(v => v !== undefined),
    distinct()
  ), false, [value.observe]);
  const toggleExpanded = () => {
    value.apply(prev => {
      if (prev === undefined) return ValueFn.set(prev, [64, 64])
      return ValueFn.set(prev, undefined);
    });
  };

  return (<div className="flex flex-col mx-2 rounded-md overflow-hidden bg-zinc-800/50">
    <ButtonBar>
      <ExpandableCheckbox expanded={expanded} toggleExpand={toggleExpanded}>
        <SectionHeader className="flex-1 bg-zinc-800/50" onClick={toggleExpanded}>Limit Size?</SectionHeader>
      </ExpandableCheckbox>
    </ButtonBar>
    {expanded && <Fieldset>
      <SizeField className="mx-0 rounded-none" value={size} />
    </Fieldset>}
  </div>)
}