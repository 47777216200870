import {TokenNode} from "common/legends/index.ts";
import {useObservable} from "#lib/qlab/index.ts";
import React from "react";
import {Color, Transform} from "common/types/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {ElementHUDPass} from "../element-h-u-d-pass.tsx";
import {HealthIndicatorView} from "../../viewport/common/hud-pass/element/health-indicator-view.tsx";
import {StatusIndicatorsView} from "../../viewport/common/hud-pass/element/status-indicators-view.tsx";
import {SelectionIndicator} from "../../viewport/common/hud-pass/element/selection-indicator.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {Vector2} from "common/math/vector/vector2.ts";
import {ModelProvider, usePVM} from "../../viewport/common/context/pvm-context.ts";
import {useAsset} from "../../common/character/use-asset.ts";
import {useIsNodeTurn} from "../../common/turn-tracker/use-is-current-turn.ts";
import {SheetReferenceProvider} from "../../common/sheet/sheet-reference-context.ts";
import {OpacityProvider, useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {useTokenSheet, useTokenSheetReference} from "../../viewport/common/context/use-token-sheet.ts";
import {useIsSelected} from "../../viewport/common/context/use-is-selected.ts";
import {CurrentTokenNodeProvider} from "../../viewport/common/context/token-node-context.ts";
import {CircleShader} from "../../viewport/common/shader/circle-shader.tsx";

export function TokenElementHUDPass({value}: {
  value: TokenNode;
}) {
  const {projection, view, model} = usePVM();
  const asset = useRefValue(useAsset(value.tokenReference.assetID));

  const sheetReference = useTokenSheetReference(value);
  const sheet = useTokenSheet(value);

  const isNodeTurn = useObservable(useIsNodeTurn(value.id), false, [value.id]);

  const isSelected = useIsSelected(value.id);
  const token = !asset ? undefined : asset.tokens.find(token => token.tokenID === value.tokenReference.tokenID);
  const valueOpacity = value.opacity * useOpacity();
  let valueModel = Transform.divide(value.transform, model);
  const interfaceScale = (token?.interfaceScale ?? 1);
  const interfaceModel = {...valueModel, scale: valueModel.scale * interfaceScale};

  return (<CurrentTokenNodeProvider value={value}>
    <SheetReferenceProvider value={sheetReference}>
      <ModelProvider value={valueModel}>
        <OpacityProvider value={valueOpacity}>
          {token && <React.Fragment key={value.id}>
            {token.children.map((child) => <ElementHUDPass key={child.data.id} value={child} />)}

            {value.displayHealthBar && <HealthIndicatorView
              projection={projection} model={interfaceModel} view={view}
              sheetRef={sheet}
              origin={Vector2.multiply(token.origin, 1 / interfaceScale)}
              size={Vector2.multiply(token.size, 1 / interfaceScale)}
              displayNumbers={value.displayHealthNumbers}
              />}

            <StatusIndicatorsView
              projection={projection} model={interfaceModel} view={view}
              value={sheet}
              opacity={valueOpacity}
              origin={Vector2.multiply(token.origin, 1 / interfaceScale)}
              size={Vector2.multiply(token.size, 1 / interfaceScale)} />

            {(isSelected || isNodeTurn) && <SelectionIndicator
                projection={projection} view={view} model={interfaceModel}
                origin={Vector2.multiply(token.origin, 1 / interfaceScale)}
                size={Vector2.multiply(token.size, 1 / interfaceScale)}
                repeatX={1} repeatY={1}
                color={isSelected ? Color.WHITE : Color.GREEN}/>}
            {isSelected && <>
              <CircleShader
                  origin={Vector2.add(
                    [token.origin[0] - token.pivot[0], token.origin[1] - token.pivot[1]],
                    Vector2.multiply([2, 2], interfaceScale)
                  )}
                  size={Vector2.multiply([4, 4], interfaceScale)}
                  color={Color.BLACK75} />
              <CircleShader
                  origin={Vector2.add(
                    [token.origin[0] - token.pivot[0], token.origin[1] - token.pivot[1]],
                    Vector2.multiply([1, 1], interfaceScale)
                  )}
                  size={Vector2.multiply([2, 2], interfaceScale)}
                  color={Color.GREEN} />
            </>}

            {value.displayName && <TextShader projection={projection} view={view} model={Transform.divide({
              position: [
                ((token.size[0] || 0)/2 - (token.origin[0] || 0)),
                -(token.origin[1] || 32)-2
              ],
              scale: interfaceScale,
              rotation: 0
            }, valueModel)} size={22} hTextAlign="center" vTextAlign="top" value={value.name} />}
          </React.Fragment>}
          {[...value.children].reverse().map((child) => <ElementHUDPass key={child.data.id} value={child} />)}
        </OpacityProvider>
      </ModelProvider>
    </SheetReferenceProvider>
  </CurrentTokenNodeProvider>);
}
