import {z} from "zod";
import {Color, ColorOperation, colorType, ConstantOperation, constantType, HSLA, ObjectType, StringOperation, stringType} from "../../../types/index.ts";
import {MutableRef} from "#common/ref";
import {SenseID, SenseIDFn} from "./sense-i-d.ts";

export const Sense = z.object({
  senseID: SenseID,
  name: z.string(),
  color: HSLA
});
export type Sense = z.infer<typeof Sense>;

export const SenseOperation = z.discriminatedUnion("type", [
  z.object({type: z.literal("update-sense-i-d"), operations: z.array(ConstantOperation)}),
  z.object({type: z.literal("update-name"), operations: z.array(StringOperation)}),
  z.object({type: z.literal("update-color"), operations: z.array(ColorOperation)})
]);
export type SenseOperation = z.infer<typeof SenseOperation>;

export const senseSettingType = new ObjectType({
  senseID: constantType,
  name: stringType,
  color: colorType
});

export const SenseFn = {
  DEFAULT: {
    name: "",
    color: Color.WHITE
  } as Omit<Sense, "senseID">,
  DEFAULT_SENSES: [
    {
      senseID: "ALL",
      name: "Everyone",
      color: Color.WHITE
    },
    {
      senseID: "GM",
      name: "Game Master(s)",
      color: Color.BLACK
    }
  ] as const satisfies Sense[],
  itemKey: (value: Sense) => value.senseID,
  expand: (valueRef: MutableRef<Sense, SenseOperation[]>) => {
    return ({
      IDRef: valueRef.map(value => value.senseID),
      nameRef: valueRef.map<string, StringOperation[]>(value => value.name, (_, operations) => [{type: "update-name", operations}]),
      colorRef: valueRef.map<HSLA, ColorOperation[]>(value => value.color, (_, operations) => [{type: "update-color", operations}])
    })
  },
  copySense: (value: Sense): Sense => {
    return ({
      ...value,
      senseID: SenseIDFn.generate(),
      name: `${value.name} (Copy)`
    });
  }
}