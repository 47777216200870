import React, {RefObject, Suspense, useMemo} from "react";
import {OverlayPanel} from "primereact/overlaypanel";
import {MutableRef} from "common/ref";
import {ListOperation, Optional} from "common/types/generic/index.ts";
import {Sense, SenseFn, SenseOperation} from "common/legends/game/sense/sense.ts";
import {useSuspendPresent} from "../../../legends/common/use-optional-signal.ts";
import {Panel} from "#lib/components/panel-header.tsx";
import {StringField} from "#lib/components/input/string-field.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {useGameSenses} from "../../../legends/common/game/use-game-senses.ts";

export function useSenseRef(senseID?: string): MutableRef<Optional<Sense>, SenseOperation[]> {
  const gameSensesRef = useGameSenses();
  return useMemo(() => gameSensesRef.map(
    value => value.find(sense => sense.senseID === senseID),
    (prev, op) => {
      const senseIndex = prev.findIndex(sense => sense.senseID === senseID);
      if (senseIndex === -1) return [];
      return ListOperation.apply(senseIndex, op)
    }
  ), [gameSensesRef, senseID]);
}

function SensePanel({valueRef}: {
  valueRef: MutableRef<Optional<Sense>, SenseOperation[]>
}) {
  const senseRef = useSuspendPresent(valueRef);
  const {nameRef, colorRef} = useMemo(() => SenseFn.expand(senseRef), [senseRef]);
  return <Panel title="Sense" className="m-0 min-w-96">
    <StringField label="Name" valueRef={nameRef} />
    <ColorField label="Color" value={colorRef} />
  </Panel>;
}

export function EditSenseOverlay({valueRef, overlayRef}: {
  valueRef: MutableRef<Optional<Sense>, SenseOperation[]>;
  overlayRef: RefObject<OverlayPanel>
}) {
  return <Suspense fallback={<></>}>
    <OverlayPanel ref={overlayRef} pt={{
      root: {
        className: "absolute left-5 top-0"
      }
    }}>
      <SensePanel valueRef={valueRef} />
    </OverlayPanel>
  </Suspense>
}