import {Scene, SceneID, SceneOperation} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import {fromSignal, MutableRef} from "common/ref";
import {useMemo} from "react";
import {useAccess} from "../../../routes/game/model/store-context.tsx";

export function useScene(sceneID: Optional<SceneID>): MutableRef<Optional<Scene>, SceneOperation[]> {
  const access = useAccess();
  return useMemo(() => fromSignal(access.scene(sceneID)), [access, sceneID]);
}
