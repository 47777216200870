import {computed} from "#common/signal";
import {OptionalResourceSignal, QLabDatabaseSignal, QLabResourceID} from "#common/qlab/index.ts";
import {Cache} from "#common/legends/access/cache.ts";
import {WeakCache} from "#common/legends/access/weak-cache.ts";

export type ResourceCache = Cache<QLabResourceID, OptionalResourceSignal>;
export function ResourceCache(database: QLabDatabaseSignal): ResourceCache {
  const cache = new WeakCache<QLabResourceID, OptionalResourceSignal>(resourceID => computed(
    () => database.value.resources[resourceID],
    (operations) => database.apply(_ => [{type: "resource", resourceID: resourceID, operations: [{type: "apply", operations}]}])
  ));
  return (resourceID) => cache.get(resourceID);
}

