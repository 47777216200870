import {Matrix4f} from "#lib/math/index.ts";
import {Optional, Point, Size, Transform} from "common/types/index.ts";
import {
  useArrayBuffer,
  useAttribLocation,
  useBindVertexArribArray,
  useElementArrayBuffer,
  useProgram,
  useShader,
  useUniformLocation,
  useVertexBuffer
} from "#lib/gl-react/index.ts";
import {useMemo} from "react";
import {Sheet, SheetOperation} from "common/legends/index.ts";
import {TextShader} from "#lib/gl-react/component/text-shader.tsx";
import {useRefValue} from "#lib/signal/index.ts";
import {MutableRef} from "common/ref";
import {useSheetHitPointsSignals} from "../../../../panel/sheet/editor/dnd-5e-character/dnd-5e-action/use-sheet-hit-points.ts";

const vertexShader = `#version 300 es
precision highp float;

in vec2 a_position;
in vec2 a_tex_coord;

uniform mat4 u_projection;
uniform mat4 u_view;
uniform mat4 u_model;

out vec2 fragCoord;
out vec2 v_tex_coord;

void main()
{
  gl_Position = u_projection * u_view * u_model * vec4(a_position, 0, 1);
  v_tex_coord = a_tex_coord;
}
`;

const fragmentShader = `#version 300 es
precision highp float;
precision highp sampler2DArray;

uniform vec2 u_screen;
uniform vec4 u_color;

in vec2 v_tex_coord;

out vec4 outColor;

void main() {
  vec2 d = vec2(
    min(v_tex_coord.x, u_screen.x - v_tex_coord.x),
    min(v_tex_coord.y, u_screen.y - v_tex_coord.y)
  );

  if (d.x > 4. && (d.y < 1. || d.y > 7.) || (
    d.x < 4. && pow(d.y - 4., 2.) + pow(d.x - 4., 2.) > pow(3., 2.)
    && pow(d.y - 4., 2.) + pow(d.x - 4., 2.) < pow(4., 2.)
  )) {
    outColor = vec4(0., 0., 0., 0.8);
  } else if (d.x > 4. || pow(d.y - 4., 2.) + pow(d.x - 4., 2.) < pow(4.,2.)) {
    outColor = u_color;
  } else {
    outColor = vec4(0.);
  }
}
`;


export type HealthIndicatorProps = {
  projection: Matrix4f;
  view: Transform;
  model: Transform;

  sheetRef: MutableRef<Optional<Sheet>, SheetOperation[]>;

  displayNumbers: boolean;

  origin: Point;
  size: Size;
}

export function HealthIndicatorView({projection, view, model, origin, size, sheetRef, displayNumbers}: HealthIndicatorProps) {
  const healthIndicator = useRefValue(useSheetHitPointsSignals(sheetRef));

  const program = useProgram(
    useShader(WebGL2RenderingContext.VERTEX_SHADER, vertexShader),
    useShader(WebGL2RenderingContext.FRAGMENT_SHADER, fragmentShader)
  );
  const projectionLocation = useUniformLocation(program, "u_projection");
  const viewLocation = useUniformLocation(program, "u_view");
  const modelLocation = useUniformLocation(program, "u_model");
  const screenLocation = useUniformLocation(program, "u_screen");
  const colorLocation = useUniformLocation(program, "u_color");

  const totalVbo = useArrayBuffer(useMemo(() => {
    if (!healthIndicator) return new Float32Array([]);
    const totalHealth = healthIndicator.max + healthIndicator.temp;
    const tempTotal = healthIndicator.temp / totalHealth;
    const hpTotal = Math.min(healthIndicator.current, healthIndicator.max) / totalHealth;
    const [x, y] = origin;
    const [w, h] = size;
    return new Float32Array([
      w*(tempTotal+hpTotal) - x, h+ 2 - y, w*(tempTotal+hpTotal), 0,
      w - x                    , h+ 2 - y, w                    , 0,
      w - x                    , h+10 - y, w                    , 8,
      w*(tempTotal+hpTotal) - x, h+10 - y, w*(tempTotal+hpTotal), 8
    ])
  }, [size, origin, healthIndicator]));
  const totalVao = useVertexBuffer();
  useBindVertexArribArray(totalVao, useAttribLocation(program, "a_position"), totalVbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(totalVao, useAttribLocation(program, "a_tex_coord"), totalVbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const hpVbo = useArrayBuffer(useMemo(() => {
    if (!healthIndicator) return new Float32Array([]);
    const totalHealth = healthIndicator.max + healthIndicator.temp;
    const hpTotal = Math.min(healthIndicator.current, healthIndicator.max) / totalHealth;

    const [x, y] = origin;
    const [w, h] = size;
    return new Float32Array([
      0 - x        , h+ 2 - y, 0          , 0,
      w*hpTotal - x, h+ 2 - y, w*(hpTotal), 0,
      w*hpTotal - x, h+10 - y, w*(hpTotal), 8,
      0 - x        , h+10 - y, 0          , 8
    ])
  }, [size, origin, healthIndicator]));
  const hpVao = useVertexBuffer();
  useBindVertexArribArray(hpVao, useAttribLocation(program, "a_position"), hpVbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(hpVao, useAttribLocation(program, "a_tex_coord"), hpVbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const tempVbo = useArrayBuffer(useMemo(() => {
    if (!healthIndicator) return new Float32Array([]);
    const totalHealth = healthIndicator.max + healthIndicator.temp;
    const tempTotal = healthIndicator.temp / totalHealth;
    const hpTotal = Math.min(healthIndicator.current, healthIndicator.max) / totalHealth;
    const [x, y] = origin;
    const [w, h] = size;
    return new Float32Array([
      w*hpTotal - x              , h+ 2 - y, w*hpTotal            , 0,
      w*hpTotal + w*tempTotal - x, h+ 2 - y, w*(hpTotal+tempTotal), 0,
      w*hpTotal + w*tempTotal - x, h+10 - y, w*(hpTotal+tempTotal), 8,
      w*hpTotal - x              , h+10 - y, w*hpTotal            , 8
    ])
  }, [size, origin, healthIndicator]));
  const tempVao = useVertexBuffer();
  useBindVertexArribArray(tempVao, useAttribLocation(program, "a_position"), tempVbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 0);
  useBindVertexArribArray(tempVao, useAttribLocation(program, "a_tex_coord"), tempVbo, 2, WebGL2RenderingContext.FLOAT, false, 4 * 4, 2 * 4);

  const ebo = useElementArrayBuffer(useMemo(() => new Uint16Array([
    0, 1, 2,
    2, 3, 0
  ]), []));

  const projectionMatrix4f = useMemo(() => new Float32Array(projection), [projection]);
  const viewMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(view)), [view]);
  const modelMatrix4f = useMemo(() => new Float32Array(Matrix4f.transform(model)), [model]);

  const screen2f = useMemo(() => new Float32Array(size), [size]);
  const hpColor = useMemo(() => new Float32Array([0.7, 0.1, 0.1, 0.9]), []);
  const blueColor = useMemo(() => new Float32Array([0.3, 0.3, 0.8, 0.9]), []);
  const blackColor = useMemo(() => new Float32Array([0, 0, 0, 0.5]), []);
  const whiteColor = useMemo(() => new Float32Array([0, 0, 0, 0.8]), []);

  if (healthIndicator.max === 0) return <></>;
  const hpLabel = `${healthIndicator?.current}/${healthIndicator.max}${healthIndicator?.temp || 0 > 0 ? `+${healthIndicator?.temp}` : ""}`;
  return (<>
      <program value={program}>
        <uniformMat4fv location={projectionLocation} transpose data={projectionMatrix4f} />
        <uniformMat4fv location={viewLocation} transpose data={viewMatrix4f} />
        <uniformMat4fv location={modelLocation} transpose data={modelMatrix4f} />
        <uniform2fv location={screenLocation} data={screen2f} />

        {(healthIndicator.current !== 0 || healthIndicator.temp !== 0) && <uniform4fv location={colorLocation} data={blackColor} />}
        {!(healthIndicator.current !== 0 || healthIndicator.temp !== 0) && <uniform4fv location={colorLocation} data={whiteColor} />}
        <vertexArray value={totalVao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
          </elementArrayBuffer>
        </vertexArray>

        <uniform4fv location={colorLocation} data={hpColor} />
        {healthIndicator.current !== 0 && <vertexArray value={hpVao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
          </elementArrayBuffer>
        </vertexArray>}

        <uniform4fv location={colorLocation} data={blueColor} />
        {healthIndicator.temp !== 0 && <vertexArray value={tempVao}>
          <elementArrayBuffer value={ebo}>
            <drawElements mode={WebGL2RenderingContext.TRIANGLES} type={WebGL2RenderingContext.UNSIGNED_SHORT} offset={0} count={6} />
          </elementArrayBuffer>
        </vertexArray>}
      </program>

      {displayNumbers && <TextShader projection={projection} view={view} model={Transform.divide({
        position: [size[0]/2-origin[0], size[1]-origin[1]+6],
        scale: 1,
        rotation: 0
      }, model)} size={12} hTextAlign="center" vTextAlign="middle" value={hpLabel} borderThickness={0.25} opacity={0.9} />}
    </>
  );
}
