import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {Suspense, useMemo} from "react";
import {LightNode, LightNodeOperation, LightNodeRef} from "common/legends/node/light/light-node.ts";
import {MutableRef} from "common/ref";
import {TransformField} from "../../field/transform-field.tsx";
import {VisionField} from "../../field/vision-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {LightShapeButtonBar} from "../../panel/properties/node/image/light-shape-button-bar.tsx";
import {InputLightShape} from "../../panel/properties/node/image/input-light-shape.tsx";
import {useComputedValue} from "#lib/signal/index.ts";
import {ConditionsSection} from "../../panel/properties/node/conditions/conditions-section.tsx";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {InputGroup, InputNumber} from "#lib/components/index.ts";

export function LightElementProperties({valueRef, reference, pinned}: {
  valueRef: MutableRef<LightNode, LightNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const nodeID = useComputedValue(valueRef, value => value.id);
  const {name, originRef, pivotRef, visibilityLayerRef, selectionMaskRef, accessMaskRef, transformRef, shapeRef, blinkIntervalRef, blinkLengthRef, blinkOffsetRef, conditionsRef} = useMemo(() => LightNodeRef(valueRef), [valueRef]);
  const isShapeSelected = useComputedValue(shapeRef, shape => shape !== undefined);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Light Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <VisionField value={accessMaskRef} />
        <VisibleField value={visibilityLayerRef} />
        <SelectionField value={selectionMaskRef} />
        <TransformField value={transformRef} />
        <OriginField valueRef={originRef} />
        <PivotField valueRef={pivotRef} />
        <Field>
          <FieldLabel>Light</FieldLabel>
          <div className="mx-2 rounded-md overflow-hidden bg-zinc-800/50">
            <LightShapeButtonBar valueRef={shapeRef}/>
            {isShapeSelected && <Suspense fallback={<></>}>
                <InputLightShape nodeID={nodeID} valueRef={shapeRef}/>
            </Suspense>}
          </div>
        </Field>
        <Field>
          <FieldLabel>Blink Length</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputNumber value={blinkLengthRef} />
          </InputGroup>
        </Field>
        <Field>
          <FieldLabel>Blink Interval</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputNumber min={0} max={1} value={blinkIntervalRef} />
          </InputGroup>
        </Field>
        <Field>
          <FieldLabel>Blink Offset</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputNumber min={0} max={1} value={blinkOffsetRef} />
          </InputGroup>
        </Field>
      </Section>
      <ConditionsSection value={conditionsRef}/>
    </Panel>
  </div>
}