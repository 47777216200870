import {ValueFn, ValueOperation, ValueType} from "../value/index.ts";
import {HSL, HSLA} from "./RGBA.ts";
import {ConstantOperation, constantType, ConstantType} from "../constant/index.ts";
import {MutableRef} from "#common/ref";
import {applyAll} from "../../type/index.ts";
import {NumberOperation, numberType} from "../number/index.ts";
import {z} from "zod";

export const ColorOperation = ValueOperation(HSLA, ConstantOperation);
export type ColorOperation = z.infer<typeof ColorOperation>;

export const colorType = new ValueType(new ConstantType<HSLA>());

export function ColorRef(valueRef: MutableRef<HSLA, ColorOperation[]>) {
  return ({
    colorRef: valueRef.map(
      value => [value[0], value[1], value[2]] as HSL,
      (prev: HSLA, operations: ValueOperation<HSL, ConstantOperation>[]) => {
        const hsl = [prev[0], prev[1], prev[2]] as HSL;
        const next = applyAll(new ValueType(constantType), hsl, operations);
        return ValueFn.set(prev, [next[0], next[1], next[2], prev[3]] as HSLA);
      }),
    alphaRef: valueRef.map(
      value => value[3] * 100,
      (prev, operations: NumberOperation[]) => {
        const next = applyAll(numberType, prev[3], operations) / 100;
        return ValueFn.set(prev, [prev[0], prev[1], prev[2], next] as HSLA);
      })
  });
}
