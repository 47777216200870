import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {TokenNode, TokenNodeOperation, TokenNodeSignals} from "common/legends/index.ts";
import {ActiveTokenField} from "../../panel/properties/node/active-token-field.tsx";
import {useCallback, useMemo} from "react";
import {OwnersField} from "../../field/owners-field.tsx";
import {MountableField} from "../../field/mountable-field.tsx";
import {AttachableField} from "../../panel/properties/attachable-field.tsx";
import {MutableRef} from "common/ref";
import {Button} from "#lib/components/index.ts";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {useNavigateToAssetEditor} from "../../viewport/character";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap} from "@awesome.me/kit-c9bc6845cd/icons/classic/solid";
import {Panel, Section} from "#lib/components/panel-header.tsx";
import {StagesField} from "../../field/stages-field.tsx";
import {BooleanField} from "../../field/boolean-field.tsx";

export function TokenElementProperties({value, reference, pinned}: {
  value: MutableRef<TokenNode, TokenNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {name, visibilityLayerRef, ownerIDsRef, transformRef, opacityRef, selectionMaskRef, mountableRef, attachableRef, tokenReferenceRef, stageIDsRef,
    displayHealthBarRef,
    displayHealthNumbersRef,
    displayNameRef,
    displayIconRef
  } = useMemo(() => TokenNodeSignals(value), [value]);
  const navigateToAsset = useNavigateToAssetEditor();
  const navigateToToken = useCallback(() => {
    const tokenReference = tokenReferenceRef.value;
    if (!tokenReference) return;
    return navigateToAsset(tokenReference.assetID, tokenReference.tokenID);
  }, [tokenReferenceRef, navigateToAsset]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Token Properties">
      <Section>
        <NameField value={name} reference={reference} pinned={pinned} />
        <Button title="Go to Token Editor" onClick={navigateToToken} className="mx-2 rounded-md overflow-hidden">
          <FontAwesomeIcon icon={faMap} />
          Navigate to Token Editor
        </Button>
        <VisibleField value={visibilityLayerRef} />
        <SelectionField value={selectionMaskRef} />
        <OpacityField value={opacityRef} />
        <TransformField value={transformRef} />
        <MountableField value={mountableRef} />
        <AttachableField value={attachableRef} />
        <ActiveTokenField valueRef={value} />
        <BooleanField label="Display Name" valueRef={displayNameRef} />
        <BooleanField label="Display Health Bar" valueRef={displayHealthBarRef} />
        <BooleanField label="Display Health Numbers" valueRef={displayHealthNumbersRef} />
        <BooleanField label="Display Icon" valueRef={displayIconRef} />
      </Section>
      <Section title="Owners">
        <OwnersField value={ownerIDsRef} />
      </Section>
      <Section title="Stages">
        <StagesField valueRef={stageIDsRef} />
      </Section>
    </Panel>
  </div>
}