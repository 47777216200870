import {QLabDatabase, QLabDatabaseOperation, QLabDatabaseSignal, QLabStoreID} from "common/qlab/index.ts";
import {subscribe} from "common/observable";
import {QLabInstance} from "#lib/qlab/index.ts";
import {computed, valueSignal} from "common/signal";

export function createQLabDatabase(qlabInstance: QLabInstance, storeId: QLabStoreID): Promise<QLabDatabaseSignal> {
  return new Promise<QLabDatabaseSignal>((resolve, reject) => {
    const signal = valueSignal<QLabDatabase | undefined>(undefined);
    const mutableSignal = computed(
      () => signal.value!,
      (operations: QLabDatabaseOperation[]) => {
        for (const operation of operations) {
          // This should cause the observe to change on it's own
          if (operation.type === "store") {
            qlabInstance.applyToStore(storeId, _ => operation.operations);
          } else if (operation.type === "message") {
            qlabInstance.applyToMessage(storeId, operation.messageID, _ => operation.operations);
          } else if (operation.type === "resource") {
            qlabInstance.applyToResource(storeId, operation.resourceID, _ => operation.operations);
          }
        }
      }
    );
    subscribe((value: QLabDatabase) => {
      signal.apply(_ => value);
      resolve(mutableSignal);
    }, reject)(qlabInstance.observe(storeId));
  });
}
