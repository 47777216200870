import {Mask, MaskOperation} from "common/types/generic/mask/mask.ts";
import {InputMultiSelectSense} from "#lib/components/input-mask/input-multi-select-sense.tsx";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";

export function SelectionField({value}: {
  value: MutableRef<Mask, MaskOperation[]>;
}) {
  return (<Field>
    <FieldLabel>Selection</FieldLabel>
    <div className="mx-2">
      <InputMultiSelectSense valueRef={value}/>
    </div>
  </Field>)
}