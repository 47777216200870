import {
  BooleanOperation,
  booleanType,
  ConstantOperation,
  constantType,
  Embeddable,
  EmbeddableOperation,
  EmbeddableType,
  MapOperation,
  MapType,
  NumberOperation,
  numberType,
  ObjectType,
  PropertyRef,
  SetOperation,
  SetPropertySignal,
  SetType,
  Transform,
  TransformOperation,
  Type,
  ValueOperation,
  ValueType
} from "#common/types/index.ts";
import {Sheet, SheetID, SheetOperation, sheetType, TokenID, UserID} from "#common/legends/index.ts";
import {AssetID} from "../asset/index.ts";
import {z} from "zod";
import {MutableRef} from "#common/ref";
import {LocalNode, LocalNodeOperation, LocalNodeSignals, localNodeTypePropTypes, localNodeUpdater} from "./local-node.ts";
import {StageID} from "#common/legends/stage/stage-i-d.ts";

export const TokenReference = z.object({
  assetID: AssetID,
  tokenID: TokenID
});
export type TokenReference = z.infer<typeof TokenReference>;

export type TokenNode = LocalNode & {
  opacity: number;
  tokenReference: TokenReference;
  mountable: boolean;
  attachable: boolean;
  tokenSheets: Record<TokenID, Embeddable<SheetID, Sheet>>;
  ownerIDs: UserID[];
  stageIDs: StageID[];
};

export type TokenNodeOperation =
  | LocalNodeOperation
  | {type: "update-opacity", operations: NumberOperation[]}
  | {type: "update-mountable", operations: BooleanOperation[]}
  | {type: "update-attachable", operations: BooleanOperation[]}
  | {type: "update-token-reference", operations: ValueOperation<TokenReference, ConstantOperation>[]}
  | {type: "update-token-sheets", operations: MapOperation<TokenID, Embeddable<SheetID, Sheet>, EmbeddableOperation<SheetOperation>>[]}
  | {type: "update-owner-i-ds", operations: SetOperation<UserID>[]}
  | {type: "update-stage-i-ds", operations: SetOperation<StageID>[]}
  ;

export const tokenNodeType: Type<TokenNode, TokenNodeOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  opacity: numberType,
  mountable: booleanType,
  attachable: booleanType,
  tokenReference: new ValueType(constantType),
  tokenSheets: new MapType<TokenID, Embeddable<SheetID, Sheet>, EmbeddableOperation<SheetOperation>>(new EmbeddableType(sheetType)),
  ownerIDs: new SetType<UserID>(),
  stageIDs: new SetType<StageID>()
}), (value) => {
  value = localNodeUpdater(value);
  let v = {...value};
  if (!v["conditions"]) v["conditions"] = [];
  if (v["visibilityMask"]) {
    delete value["visibilityMask"];
  }
  if (v["selectable"]) {
    v["selectionMask"] = v["selectable"] ? 1 : 0;
    delete value["selectable"];
  }
  if (!v["selectionMask"]) v["selectionMask"] = 0;
  if (v["mountable"] === undefined || v["mountable"] === null) v["mountable"] = false;
  if (v["tokenReference"] && v["tokenReference"]["characterID"]) {
    v["tokenReference"]["assetID"] = v["tokenReference"]["characterID"];
    delete v["tokenReference"]["characterID"];
  }
  if (v["attachable"] === undefined) v["attachable"] = v["mountable"];
  if (!v["origin"]) v["origin"] = [0, 0];
  if (v["interfaceScale"] !== undefined) delete v["interfaceScale"];
  if (v["accessMask"]) delete v["accessMask"];
  if (v["stageIDs"] === undefined) v["stageIDs"] = ["ALL"];
  return v;
});

export const TokenNodeOperationUtil = {
  getOpacity: (value: TokenNode) => value.opacity,
  updateOpacity: (operations: NumberOperation[]): TokenNodeOperation[] => [{type: "update-opacity", operations}],
  getTransform: (value: TokenNode) => value.transform,
  updateTransform: (operations: TransformOperation[]): TokenNodeOperation[] => [{type: "update-transform", operations}],
  updateTokenReference: (operations: ValueOperation<TokenReference, ConstantOperation>[]): TokenNodeOperation[] => [{type: "update-token-reference", operations}],
  updateTokenSheets: (operations: MapOperation<TokenID, Embeddable<SheetID, Sheet>, EmbeddableOperation<SheetOperation>>[]): TokenNodeOperation[] => [{type: "update-token-sheets", operations}],
  getOwnerIDs: (value: TokenNode) => value.ownerIDs,
  updateOwnerIDs: (operations: SetOperation<UserID>[]): TokenNodeOperation[] => [{type: "update-owner-i-ds", operations}],
};

export function TokenNodeSignals(value: MutableRef<TokenNode, TokenNodeOperation[]>) {
  return {
    ...LocalNodeSignals(value),
    opacityRef: PropertyRef<TokenNode, TokenNodeOperation, number, NumberOperation>(TokenNodeOperationUtil.getOpacity, TokenNodeOperationUtil.updateOpacity)(value),
    ownerIDsRef: SetPropertySignal<TokenNode, TokenNodeOperation, UserID>(TokenNodeOperationUtil.getOwnerIDs, TokenNodeOperationUtil.updateOwnerIDs)(value),
    transformRef: PropertyRef<TokenNode, TokenNodeOperation, Transform, TransformOperation>(TokenNodeOperationUtil.getTransform, TokenNodeOperationUtil.updateTransform)(value),
    tokenReferenceRef: value.map<TokenReference, ValueOperation<TokenReference, ConstantOperation>[]>(value => value.tokenReference, (_, operations) => [{type: "update-token-reference", operations}]),
    mountableRef: PropertyRef<TokenNode, TokenNodeOperation, boolean, BooleanOperation>(value => value.mountable, operations => [{type: "update-mountable", operations}])(value),
    attachableRef: PropertyRef<TokenNode, TokenNodeOperation, boolean, BooleanOperation>(value => value.attachable, operations => [{type: "update-attachable", operations}])(value),
    stageIDsRef: SetPropertySignal<TokenNode, TokenNodeOperation, StageID>(value => value.stageIDs, operations => [{type: "update-stage-i-ds", operations}])(value)
  };
}
