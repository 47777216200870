import {Grid, GridOperation, gridType} from "./grid/index.ts";
import {
  Color,
  ColorOperation,
  colorType,
  ConstantOperation,
  constantType,
  HSLA,
  ObjectType,
  Optional,
  StringOperation,
  stringType,
  TreeOperation,
  TreeType,
  Type
} from "../../types/index.ts";
import {Node, NodeOperation, nodeType} from "#common/legends/index.ts";
import {MutableRef} from "#common/ref";
import {LightShapeGlobal, LightShapeGlobalOperation, lightShapeGlobalType} from "../node/light/light-shape-global.ts";
import {defaultGridNode, NodeFn} from "../node/index.ts";
import {MutableSignal} from "#common/signal";

export type Scene = {
  version: 1;
  name: string;
  grid: Grid;
  children: Node[];
  backgroundColor: HSLA;
  light: LightShapeGlobal;
};

export type SceneOperation =
  | {type: "update-version", operations: ConstantOperation[]}
  | {type: "update-name", operations: StringOperation[]}
  | {type: "update-grid", operations: GridOperation[]}
  | {type: "update-children", operations: TreeOperation<Node, NodeOperation>[]}
  | {type: "update-background-color", operations: ColorOperation[]}
  | {type: "update-light", operations: LightShapeGlobalOperation[]}
  ;

export const sceneType: Type<Scene, SceneOperation> = new ObjectType({
  version: constantType,
  name: stringType,
  grid: gridType,
  backgroundColor: colorType,
  children: new TreeType<Node, NodeOperation>(nodeType),
  light: lightShapeGlobalType
}, v => {
  if (v.backgroundColor.length === 3) v.backgroundColor = [...v.backgroundColor, 1];
  if (v.light === undefined) v.light = {
    intensity: 1,
    color: Color.WHITE
  } satisfies LightShapeGlobal;

  if (v.version === undefined) {
    const reverseChildren = (nodes: Node[]): Node[] => [...nodes].reverse().map((node: Node): Node => ({...node, data: {...node.data, children: reverseChildren(node.data.children)}}) as Node);
    v.children = reverseChildren(v.children);
    v.version = 1;
  }

  return v;
});
export type OptionalSceneSignal = MutableSignal<Optional<Scene>, SceneOperation[]>;
export type SceneSignal = MutableSignal<Scene, SceneOperation[]>;

declare module "../../qlab/resource/resource.ts" {
  export interface ResourceTypes {
    "scene": typeof sceneType
  }
}

export const SceneFn = {
  DEFAULT: {
    version: 1,
    name: "Scene",
    grid: Grid.DEFAULT,
    children: [{type: "grid", data: defaultGridNode()}],
    backgroundColor: [0, 0, 0.5, 1] as HSLA,
    light: {intensity: 1, color: Color.WHITE}
  } satisfies Scene,
  expand(value: MutableRef<Scene, SceneOperation[]>) {
    return {
      name: value.map<string, StringOperation[]>(value => value.name, (_, operations) => [{type: "update-name", operations}]),
      children: value.map<Node[], TreeOperation<Node, NodeOperation>[]>(value => value.children, (_, operations) => [{type: "update-children", operations}]),
      grid: value.map<Grid, GridOperation[]>(value => value.grid, (_, operations) => [{type: "update-grid", operations}]),
      backgroundColor: value.map<HSLA, ColorOperation[]>(value => value.backgroundColor, (_, operations) => [{type: "update-background-color", operations}]),
      lightRef: value.map<LightShapeGlobal, LightShapeGlobalOperation[]>(value => value.light, (_, operations) => [{type: "update-light", operations}])
    } as const;
  },
  copyScene(scene: Scene): Scene {
    return ({
      ...scene,
      children: scene.children.map(NodeFn.copyElement)
    });
  }
};
