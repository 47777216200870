import {Button} from "#lib/components/index.ts";
import React, {MouseEvent} from "react";
import {ListOperation} from "common/types/generic/index.ts";
import {useGameSenses} from "../../../legends/common/game/use-game-senses.ts";
import {SenseIDFn} from "common/legends/game/sense/sense-i-d.ts";
import {Sense, SenseFn} from "common/legends/game/sense/sense.ts";
import {FaPlus} from "react-icons/fa";


export function CreateNewSenseButton({onCreate}: {onCreate?: (event: MouseEvent<HTMLElement>, sense: Sense) => void}) {
  const sensesRef = useGameSenses();
  const createSense = (): Promise<Sense> => {
    const senseID = SenseIDFn.generate();
    return sensesRef.apply(prev => {
      return ListOperation.insert(prev.length, {
        senseID: senseID,
        ...SenseFn.DEFAULT
      });
    }).then(value => value.find(sense => sense.senseID === senseID)!);
  };

  return <>
    <Button size="small" className="rounded-md" onClick={e => {
      createSense().then(sense => onCreate && onCreate(e, sense));
    }}><FaPlus /> Add Sense</Button>
  </>
}