import React, {PropsWithChildren, useContext, useMemo} from "react";
import {QLabDatabase, QLabDatabaseOperation, QLabStoreID} from "common/qlab/index.ts";
import {MutableRef} from "common/ref";
import {GameID} from "common/legends/game/game-i-d.ts";
import {LegendsAccess} from "common/access";

type LegendsContext = {
  storeID: QLabStoreID,
  access: LegendsAccess,
  database: MutableRef<QLabDatabase, QLabDatabaseOperation[]>
};

const DatabaseContext = React.createContext<LegendsContext | undefined>(undefined);
export type GameSignalProviderProps = PropsWithChildren<LegendsContext>;
export function DatabaseProvider({storeID, database, access, children}: GameSignalProviderProps) {
  const value = useMemo((): LegendsContext => ({storeID, access, database}), [storeID, database]);
  return (<DatabaseContext.Provider value={value}>
    {children}
  </DatabaseContext.Provider>)
}

export function useDatabase() {
  return useContext(DatabaseContext)!.database;
}

export function useAccess() {
  return useContext(DatabaseContext)!.access;
}

export function useStoreID() {
  return useContext(DatabaseContext)!.storeID as GameID;
}
