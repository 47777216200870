import React, {useMemo, useState} from "react";
import {HSLA, Point} from "common/types/generic/index.ts";
import {LightShapeSprite} from "common/legends/node/light/light-shape-sprite.ts";
import {ImageLightShader} from "../../viewport/common/shader/light/image-light-shader.tsx";
import {useOpacity} from "../../viewport/common/context/opacity-context.ts";
import {useImageTexture} from "../../viewport/common/context/use-image-texture.ts";
import {WallTintShader} from "../../viewport/common/shader/wall-tint-shader.tsx";
import {WallMaskFramebuffer} from "../../viewport/common/shader/wall-mask-framebuffer.tsx";
import {TextureMaskShader} from "../../viewport/common/shader/texture-mask-shader.tsx";
import {Framebuffer} from "../../viewport/common/shader/framebuffer.tsx";
import {LightTextureShader} from "../../viewport/common/light-pass/light-texture-shader.tsx";
import {Line} from "../../viewport/scene/scene-view.tsx";

export function LightShapeSpriteLightPass({value, origin, walls}: {
  value: LightShapeSprite,
  origin: Point,
  walls: Line[]
}) {
  const [loaded, texture] = useImageTexture(value.file);
  const opacity = useOpacity();
  const tint = useMemo(() => [value.color[0], value.color[1], value.color[2], value.color[3] * opacity * value.intensity] as HSLA, [value.color, value.intensity, opacity]);
  const [maskTexture, setMaskTexture] = useState<WebGLTexture | null>(null);
  const [lightTexture, setLightTexture] = useState<WebGLTexture | null>(null);
  if (!loaded) return <></>;
  return (<binder>
    <Framebuffer ref={setLightTexture}>
      <ImageLightShader size={value.size} origin={origin} texture={texture} tint={tint}/>
      <WallTintShader origin={[0, 0]} walls={walls} />
    </Framebuffer>

    <WallMaskFramebuffer ref={setMaskTexture} origin={[0, 0]} walls={walls} />

    {lightTexture && maskTexture && <LightTextureShader>
      <TextureMaskShader texture={lightTexture} mask={maskTexture} />
    </LightTextureShader>}
  </binder>);
}

