import {useCallback} from "react";
import {Node} from "common/legends/node/index.ts";
import {TreeOperation} from "common/types/generic/index.ts";
import {useAccess} from "../../routes/game/model/store-context.tsx";
import {ScenePath} from "common/legends/reference/scene-path.ts";
import {AssetTokenPath} from "common/legends/reference/asset-token-path.ts";

export function useAddElement() {
  const access = useAccess();
  return useCallback((reference: ScenePath | AssetTokenPath, node: Node) => {
    if (reference.type === "scene") {
      access.scene(reference.data.sceneID).apply(scene => {
        if (!scene) return [];
        return [{type: "update-children", operations: TreeOperation.insert([0], node)}];
      });
    } else if (reference.type === "asset-token") {
      access.assetToken(reference.data.assetID, reference.data.tokenID).apply(token => {
        if (!token) return [];
        return [{type: "update-children", operations: TreeOperation.insert([0], node)}];
      });
    }
  }, [access])
}
