import {Panel, Section} from "#lib/components/panel-header.tsx";
import {NameField} from "../../field/name-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {MutableRef} from "common/ref";
import {CameraElement, CameraElementFn, CameraElementOperation} from "common/legends/node/camera-element.ts";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";
import {ConstantOperation, ValueOperation} from "common/types/generic/index.ts";
import {useMemo} from "react";
import {SizeField} from "../../field/size-field.tsx";
import {OriginField} from "../../field/origin-field.tsx";
import {PivotField} from "../../field/pivot-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";

export function CameraElementProperties({
  valueRef,
  pinned, reference
}: {
  valueRef: MutableRef<CameraElement, CameraElementOperation[]>,
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {nameRef, sizeRef, originRef, selectionMaskRef, pivotRef, transformRef} = useMemo(() => CameraElementFn.expand(valueRef), [valueRef]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <Panel title="Camera Properties">
      <Section>
        <NameField value={nameRef} reference={reference} pinned={pinned} />
        <SelectionField value={selectionMaskRef} />
        <TransformField value={transformRef} />
        <OriginField valueRef={originRef} />
        <PivotField valueRef={pivotRef} />
        <SizeField valueRef={sizeRef} />
      </Section>
    </Panel>
  </div>
}