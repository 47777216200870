import {VisibilityLayer, VisibilityLayerOperation} from "common/legends/visibility/index.ts";
import {MutableRef} from "common/ref";
import {Field, FieldLabel} from "#lib/components/panel-header.tsx";
import {InputMultiSelectSense} from "#lib/components/input-mask/index.ts";

export function VisibleField({value}: {
  value: MutableRef<VisibilityLayer, VisibilityLayerOperation[]>;
}) {
  return (<Field>
    <FieldLabel onClick={(ev) => {ev.preventDefault(); return false;}}>Visible</FieldLabel>
    <div className="mx-2">
      <InputMultiSelectSense valueRef={value} />
    </div>
  </Field>)
}