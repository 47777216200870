import React, {PropsWithChildren} from "react";
import {FaTimes} from "react-icons/fa";
import * as Sentry from "@sentry/react";

const DefaultFallback = <div className="flex flex-row gap-2 px-4 py-4 items-center justify-center bg-white w-full h-full">
  <FaTimes className="text-red-700"/>
  <span className="text-sm italic text-black">An error has occurred.</span>
</div>;

export const ErrorBoundary = function ({fallback = DefaultFallback, children}: PropsWithChildren<{
  fallback?: React.JSX.Element;
}>) {
  return <Sentry.ErrorBoundary onError={import.meta.env.PROD ? undefined : console.error} fallback={fallback}>
    {children}
  </Sentry.ErrorBoundary>
}
