import {ExtractOperation, ExtractValue, Optional} from "common/types/index.ts";
import {SheetReference} from "./sheet-reference.ts";
import {Sheet, SheetOperation, SheetType, SheetTypes} from "common/legends/index.ts";
import {useMemo} from "react";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {fromSignal, MutableRef, Ref} from "common/ref";
import {useRefValue} from "#lib/signal/index.ts";

export function useSheetSignal(sheetReferenceRef: Ref<Optional<SheetReference>>): MutableRef<Optional<Sheet>, SheetOperation[]> {
  const access = useAccess();
  const sheetReference = useRefValue(sheetReferenceRef);
  return useMemo(() => {
    return fromSignal(access.sheet(sheetReference))
  }, [access, sheetReference]);
}

export function useTypedSheetSignal<Type extends SheetType>(type: Type, sheetReference: Ref<Optional<SheetReference>>): MutableRef<Optional<ExtractValue<SheetTypes[Type]>>, ExtractOperation<SheetTypes[Type]>[]> {
  const sheetRef = useSheetSignal(sheetReference);
  return useMemo(() => {
    const valueFn = (a: Optional<Sheet>) => a?.type === type ? a.data as ExtractValue<SheetTypes[Type]> : undefined;
    const updateFn = (_: Optional<Sheet>, operations: ExtractOperation<SheetTypes[Type]>[]) =>
      operations.length > 0 ? [{type, operations}] as SheetOperation[] : [];
    return sheetRef.map(valueFn, updateFn).distinct();
  }, [sheetRef, type]);
}
