import {GroupNode, GroupNodeOperation, GroupNodeSignals} from "common/legends/index.ts";
import {ConstantOperation, ValueOperation} from "common/types/index.ts";
import {NameField} from "../../field/name-field.tsx";
import {VisibleField} from "../../field/visible-field.tsx";
import {OpacityField} from "../../field/opacity-field.tsx";
import {SelectionField} from "../../field/selection-field.tsx";
import {TransformField} from "../../field/transform-field.tsx";
import {useMemo} from "react";
import {OriginField} from "../../field/origin-field.tsx";
import {SizeField} from "../../field/size-field.tsx";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {MutableRef} from "common/ref";
import {SelectionRef} from "../../panel/nav/editor/state/selection-ref.ts";

export function GroupElementProperties({value, reference, pinned}: {
  value: MutableRef<GroupNode, GroupNodeOperation[]>;
  reference: SelectionRef;
  pinned: MutableRef<SelectionRef, ValueOperation<SelectionRef, ConstantOperation>[]>;
}) {
  const {name, transform, size, origin, visibilityLayer, selectionMask, opacity} = useMemo(() => GroupNodeSignals(value), [value]);

  return <div className="tab-content flex flex-col py-2 gap-1">
    <BaseComponent><NameField value={name} reference={reference} pinned={pinned} /></BaseComponent>
    <BaseComponent><VisibleField value={visibilityLayer} /></BaseComponent>
    <BaseComponent><SelectionField value={selectionMask} /></BaseComponent>
    <BaseComponent><OpacityField value={opacity} /></BaseComponent>
    <BaseComponent><TransformField value={transform} /></BaseComponent>
    <BaseComponent><OriginField valueRef={origin} /></BaseComponent>
    <BaseComponent><SizeField valueRef={size} /></BaseComponent>
  </div>
}