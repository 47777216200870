import {Dnd5eFeature, Sheet} from "common/legends/index.ts";
import {Optional} from "common/types/index.ts";
import React, {useMemo} from "react";
import {getDnd5eSheetActiveModifiers} from "./dnd-5e/common/dnd-5e-sheet-active-modifiers.ts";
import {Dnd5eDamageType, DND_5E_DAMAGE_TYPES} from "common/legends/asset/sheet/dnd-5e/dnd-5e-damage-type.ts";
import {useGlobalFeatures} from "./dnd-5e-character/dnd-5e-action/use-global-features.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {useSheetSignal} from "../../../common/sheet/use-sheet-signal.ts";
import {useSheetReference} from "../../../common/sheet/sheet-reference-context.ts";
import {BaseComponent} from "#lib/components/BaseComponent.tsx";
import {Dnd5eDamageTypeTag} from "./dnd-5e/dnd-5e-modifier/dnd-5e-damage-type-view.tsx";
import {ButtonBar, InputGroup, InputGroupLabel} from "#lib/components/index.ts";
import {InputGroupIcon} from "#lib/components/input/input-group-icon.tsx";
import {MutableRef, Ref} from "common/ref";

function getSheetDamageImmunities(sheet: Ref<Optional<Sheet>>, globalFeatures: Ref<Dnd5eFeature[]>): Ref<Dnd5eDamageType[]> {
  return MutableRef.all(sheet, globalFeatures).map(
    ([value, globalFeatures]): Dnd5eDamageType[] => {
      const damageTypes = getDnd5eSheetActiveModifiers(value, globalFeatures)
        .flatMap(modifier => modifier.type === "damage-immunity" ? modifier.data.damageTypes : []);
      return DND_5E_DAMAGE_TYPES.filter(type => damageTypes.includes(type));
    }
  );
}


export type Dnd5eDamageImmunitiesViewProps = {};
export function Dnd5eDamageImmunitiesView({}: Dnd5eDamageImmunitiesViewProps) {
  const sheet = useSheetSignal(useSheetReference());
  const globalEffects = useGlobalFeatures();
  const damageTypes = useRefValue(useMemo(() => getSheetDamageImmunities(sheet, globalEffects), [sheet, globalEffects]));

  if (damageTypes.length === 0) return <></>;
  return <BaseComponent>
    <ButtonBar className="gap-1 items-start">
      <InputGroup>
        <InputGroupIcon />
        <InputGroupLabel>Damage Immunity</InputGroupLabel>
      </InputGroup>
      <div className="flex flex-row gap-x-1 gap-y-1 py-2 flex-wrap">
        {damageTypes.map(damageType => <Dnd5eDamageTypeTag key={damageType} type={damageType}/>)}
      </div>
    </ButtonBar>
  </BaseComponent>
}
