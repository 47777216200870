import {
  CloudResponse,
  QLabMessage,
  QLabMessageChangeset,
  QLabMessageID,
  qlabMessageType,
  QLabResource,
  QLabResourceChangeset,
  QLabResourceID,
  qlabResourceType,
  QLabStore,
  QLabStoreChangeset,
  QLabStoreID,
  qlabStoreType
} from "common/qlab/index.ts";
import {Type} from "common/types/index.ts";
import {QLabServiceHandler, QLabServiceSocket} from "./socket/index.ts";
import {QLabServiceDatastore} from "./q-lab-service-datastore.ts";
import {QLabServiceListener} from "./q-lab-service-listener.ts";
import {RequestMessagesParams} from "../socket/index.ts";
import {Unsubscribe} from "common/subscription";
import {QLabStream} from "common/qlab/stream/q-lab-stream.ts";

const DEFAULT_STORE: TypedState<QLabStore, QLabStoreChangeset> = reduceInitialized({value: undefined, revision: 0});
const DEFAULT_RESOURCE: TypedState<QLabResource, QLabResourceChangeset> = reduceInitialized({value: undefined, revision: 0});
const DEFAULT_MESSAGE: TypedState<QLabMessage, QLabMessageChangeset> = reduceInitialized({value: undefined, revision: 0});

type TypedState<Value, Changeset> = {
  service: Value;
  unprocessedChangesets: Changeset[];
};

type ServiceState = {
  connected: boolean;
  store: TypedState<QLabStore, QLabStoreChangeset>;
  resources: {[resourceId: QLabResourceID]: TypedState<QLabResource, QLabResourceChangeset>};
  messages: {[messageId: QLabMessageID]: TypedState<QLabMessage, QLabMessageChangeset>};
};

function reduceInitialized<Value, Changeset>(value: Value): TypedState<Value, Changeset> {
  return {service: value, unprocessedChangesets: []};
}
function reduceChangesetApplied<Value extends {revision: number}, Changeset extends {revision: number}>(type: Type<Value, Changeset>, value: TypedState<Value, Changeset>, changesets: Changeset[]): [TypedState<Value, Changeset>, Changeset[]] {
  let {service, unprocessedChangesets} = value;
  unprocessedChangesets = [...unprocessedChangesets, ...changesets];
  unprocessedChangesets.sort((a, b) => a.revision < b.revision ? -1 : 1);
  const processedChangesets: Changeset[] = [];
  while (unprocessedChangesets.length > 0) {
    if (unprocessedChangesets[0].revision < service.revision) {
      unprocessedChangesets.shift();
    } else if (unprocessedChangesets[0].revision === service.revision) {
      const changeset = unprocessedChangesets.shift()!;
      service = type.apply(service, changeset);
      processedChangesets.push(changeset);
    } else {
      break;
    }
  }
  return [{service, unprocessedChangesets}, processedChangesets];
}

export class QLabServiceObserver {
  constructor(
    private readonly serviceSocket: QLabServiceSocket,
    private readonly datastore: QLabServiceDatastore,
    private readonly storeId: QLabStoreID
  ) {
  }

  private serviceState: ServiceState | undefined;

  private close: Unsubscribe | undefined = undefined;
  private listeners: QLabServiceListener[] = [];
  subscribe(listener: QLabServiceListener): Unsubscribe {
    if (this.listeners.length === 0) {
      this.close = this.subscribeToStore({
        onConnect: () => {
          if (this.serviceState === undefined) {
            console.error("Connected while no longer listening.");
            return;
          }
          this.serviceState.connected = true;
          for (const listener of this.listeners) {
            listener.onUpdate({type: "CONNECTED"});
          }
          this.serviceSocket.subscribeStore(
            this.storeId,
            this.serviceState.store.service.revision,
            Object.fromEntries(Object.entries(this.serviceState.resources).map(([resourceId, resource]) => [resourceId, resource.service.revision]))
          );
        },
        onDisconnect: () => {
          if (this.serviceState === undefined) {
            console.error("Disconnected while no longer listening.");
            return;
          }
          this.serviceState.connected = false;
          for (const listener of this.listeners) {
            listener.onUpdate({type: "DISCONNECTED"});
          }
        },
        onMessage: (message: CloudResponse) => {
          if (this.serviceState === undefined) {
            console.error("Message while no longer listening.");
            return;
          }
          if (message.storeId !== this.storeId) return;
          switch (message.type) {
            case "STORE-INITIALIZED": {
              this.serviceState.store = reduceInitialized(message.store);
              this.datastore.putStore(this.storeId, this.serviceState.store.service)
                .catch(console.error);
              for (const listener of this.listeners) {
                listener.onUpdate({type: "STORE-INITIALIZED", store: message.store});
              }
              break;
            }
            case "STORE-CHANGESET-APPLIED": {
              let [state, processedChangesets] =
                reduceChangesetApplied(qlabStoreType, this.serviceState.store || DEFAULT_STORE, message.storeChangesets);
              this.serviceState.store = state;
              if (processedChangesets.length > 0) {
                this.datastore.putStore(this.storeId, state.service)
                  .catch(console.error);
                for (const listener of this.listeners) {
                  listener.onUpdate({type: "STORE-CHANGESET-APPLIED", changesets: processedChangesets});
                }
              }
              break;
            }
            case "STORE-CHANGESET-REJECTED": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "STORE-CHANGESET-REJECTED", changesetId: message.changesetId});
              }
              break;
            }
            case "RESOURCE-INITIALIZED": {
              this.serviceState.resources[message.resourceId] = reduceInitialized(message.resource);
              this.datastore.putResource(this.storeId, message.resourceId, message.resource)
                .catch(console.error);
              for (const listener of this.listeners) {
                listener.onUpdate({type: "RESOURCE-INITIALIZED", resourceId: message.resourceId, resource: message.resource});
              }
              break;
            }
            case "RESOURCE-CHANGESET-APPLIED": {
              let [state, processedChangesets] = reduceChangesetApplied(qlabResourceType, this.serviceState.resources[message.resourceId] || DEFAULT_RESOURCE, message.resourceChangesets);
              this.serviceState.resources[message.resourceId] = state;
              if (processedChangesets.length > 0) {
                this.datastore.putResource(this.storeId, message.resourceId, state.service)
                  .catch(console.error);
                for (const listener of this.listeners) {
                  listener.onUpdate({type: "RESOURCE-CHANGESET-APPLIED", resourceId: message.resourceId, changesets: processedChangesets});
                }
              }
              break;
            }
            case "RESOURCE-CHANGESET-REJECTED": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "RESOURCE-CHANGESET-REJECTED", resourceId: message.resourceId, changesetId: message.changesetId});
              }
              break;
            }
            case "MESSAGE-INITIALIZED": {
              if (!this.serviceState.messages) this.serviceState.messages = {};
              this.serviceState.messages[message.messageId] = reduceInitialized(message.message);
              for (const listener of this.listeners) {
                listener.onUpdate({type: "MESSAGE-INITIALIZED", messageId: message.messageId, message: message.message});
              }
              break;
            }
            case "MESSAGE-CHANGESETS-APPLIED": {
              const channel = this.serviceState.messages || {};
              let [state, processedChangesets] = reduceChangesetApplied(qlabMessageType, channel[message.messageId] || DEFAULT_MESSAGE, message.messageChangesets);
              if (!this.serviceState.messages) this.serviceState.messages = {};
              this.serviceState.messages[message.messageId] = state;
              if (processedChangesets.length > 0) {
                for (const listener of this.listeners) {
                  listener.onUpdate({type: "MESSAGE-CHANGESET-APPLIED", messageId: message.messageId, changesets: processedChangesets});
                }
              }
              break;
            }
            case "MESSAGE-CHANGESET-REJECTED": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "MESSAGE-CHANGESET-REJECTED", messageId: message.messageId, changesetId: message.changesetId});
              }
              break;
            }
            case "STREAM": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "STREAM", stream: message.stream});
              }
              break;
            }
            case "CONNECTION-ESTABLISHED": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "CONNECTION-ESTABLISHED", connectionId: message.connectionId, userId: message.userId});
              }
              break;
            }
            case "CONNECTION-CLOSED": {
              for (const listener of this.listeners) {
                listener.onUpdate({type: "CONNECTION-CLOSED", connectionId: message.connectionId});
              }
              break;
            }
          }
        }
      });
    }

    this.listeners.push(listener);
    if (this.serviceState) {
      listener.onInitial({
        connected: this.serviceState.connected,
        store: this.serviceState.store.service,
        resources: Object.fromEntries(Object.entries(this.serviceState.resources).map(([resourceId, resource]) => [
          resourceId, resource.service
        ])),
        messages: Object.fromEntries(Object.entries(this.serviceState.messages).map(([messageId, message]) => [
          messageId, message.service
        ]))
      });
    }

    return () => {
      const index = this.listeners.indexOf(listener);
      if (index !== -1) {
        this.listeners.splice(index, 1);
      }

      if (this.listeners.length === 0 && this.close) {
        this.close();
        this.serviceState = undefined;
        this.close = undefined;
      }
    };
  }

  async applyToStore(changesets: QLabStoreChangeset[]): Promise<void> {
    if (changesets.length === 0) return;
    return this.serviceSocket.applyToStore(this.storeId, changesets);
  }
  async applyToResource(resourceId: QLabResourceID, changesets: QLabResourceChangeset[]): Promise<void> {
    if (changesets.length === 0) return;
    return this.serviceSocket.applyToResource(this.storeId, resourceId, changesets);
  }
  async applyToMessage(messageId: QLabMessageID, changesets: QLabMessageChangeset[]): Promise<void> {
    if (changesets.length === 0) return;
    return this.serviceSocket.applyToMessage(this.storeId, messageId, changesets);
  }

  requestMessages(params: RequestMessagesParams) {
    return this.serviceSocket.requestMessages(this.storeId, params.lastMessage, params.limit);
  }

  stream(stream: QLabStream) {
    return this.serviceSocket.stream(this.storeId, stream);
  }

  private subscribeToStore(socketListener: QLabServiceHandler) {
    const store = this.datastore.getStore(this.storeId);
    const resources = this.datastore.getResourceIds(this.storeId).then(resourceIds =>
      Promise.all(resourceIds.map(resourceId => this.datastore.getResource(this.storeId, resourceId)))
        .then(resources => Object.fromEntries(resourceIds.map((resourceId, index) => [
          resourceId, resources[index]
        ])))
    );
    this.serviceSocket.addListener(socketListener);
    Promise.all([store, resources]).then(([store, resources]) => {
      this.serviceState = {
        connected: this.serviceSocket.isOpen(),
        store: reduceInitialized(store),
        resources: Object.fromEntries(Object.entries(resources).map(([resourceId, resource]) => [resourceId, reduceInitialized(resource)])),
        messages: {}
      };
      this.serviceSocket.subscribeStore(
        this.storeId,
        store.revision,
        Object.fromEntries(Object.entries(resources).map(([resourceId, resource]) => [resourceId, resource.revision]))
      );
      for (const listener of this.listeners) {
        listener.onInitial({
          connected: this.serviceState.connected,
          store: this.serviceState.store.service,
          resources: Object.fromEntries(Object.entries(this.serviceState.resources).map(([resourceId, resource]) => [
            resourceId, resource.service
          ])),
          messages: Object.fromEntries(Object.entries(this.serviceState.messages).map(([messageId, message]) => [
            messageId, message.service
          ]))
        });
      }
    });

    return () => {
      this.serviceState = undefined;
      this.serviceSocket.unsubscribeStore(this.storeId);
      this.serviceSocket.removeListener(socketListener)
    };
  }
}