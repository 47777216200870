import {OptionalSheetSignal, Sheet, SheetOperation} from "#common/legends/index.ts";
import {computed, MutableSignal} from "#common/signal";
import {Optional} from "#common/types/index.ts";
import {AssetCache} from "../resource/asset/asset-cache.ts";
import {NodeCache} from "./node-cache.ts";
import {WeakCache} from "../weak-cache.ts";
import {Cache} from "../cache.ts";
import {SheetReference, SheetReferenceKeyCache} from "./sheet-reference.ts";

export type SheetCache = Cache<Optional<SheetReference>, OptionalSheetSignal>;
export function SheetCache(
  assetCache: AssetCache,
  nodeCache: NodeCache
) {
  const cache = new WeakCache<Optional<SheetReference>, OptionalSheetSignal>(reference => computed(
    () => {
        if (reference === undefined) return undefined;
        if (reference.type === "link") {
          const asset = assetCache(reference.assetID).value;
          return asset?.sheets[reference.sheetID];
        } else if (reference.type === "copy") {
          const node = nodeCache(reference.nodeID).value;
          if (node?.type !== "token") return undefined;
          const sheet = node.data.tokenSheets[reference.tokenID];
          if (sheet?.type !== "copy") return undefined;
          return sheet.data;
        }
      },
      operations => {
        if (reference?.type === "link") {
          assetCache(reference.assetID).apply(asset => {
            if (!asset?.sheets[reference.sheetID]) return [];
            return [{
              type: "update-sheets",
              operations: [{
                type: "apply",
                key: reference.sheetID,
                operations
              }]
            }];
          });
        } else if (reference?.type === "copy") {
          nodeCache(reference.nodeID).apply(node => {
            if (node?.type !== "token") return [];
            if (node.data.tokenSheets[reference.tokenID]?.type !== "copy") return [];
            return [{
              type: "token",
              operations: [{
                type: "update-token-sheets",
                operations: [{
                  type: "apply",
                  key: reference.tokenID,
                  operations
                }]
              }]
            }]
          });
        }
      }
    )
  );

  return (reference: Optional<SheetReference>): MutableSignal<Optional<Sheet>, SheetOperation[]> => cache.get(reference ? SheetReferenceKeyCache(reference) : undefined);
}
