import {Button, InputGroup} from "#lib/components/index.ts";
import {MouseEvent, useCallback, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import {InvitationSection} from "./invitation-section.tsx";
import {ConfirmDeleteModal} from "./confirm-delete-modal.tsx";
import {useApplyToStore, useObservable} from "#lib/qlab/index.ts";
import {useUserID} from "#lib/auth/use-get-user-id.ts";
import {GameSignals, Player, UserID} from "common/legends/index.ts";
import {Color, ListOperation, MapFn, MapSignals} from "common/types/index.ts";
import {OwnerEntity} from "common/legends/game/owner/index.ts";
import {InputFile} from "#lib/components/input/input-file.tsx";
import {InputString} from "#lib/components/input/input-string.tsx";
import {Field, FieldLabel, Panel, Section} from "#lib/components/panel-header.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {useApplyToGameInvitations, useDeleteGame, useGame, useGameReference} from "../../../routes/game/index.ts";
import {useRemoveGameId} from "../../../routes/user-home/use-user-session.ts";
import {GamePlayerView} from "./game-player-view.tsx";
import {SystemsView} from "./systems-view.tsx";
import {useComputedValue, useRefValue} from "#lib/signal/index.ts";
import {InputOptionalScene} from "./input-optional-scene.tsx";
import {SenseListItem} from "./sense-list-item.tsx";
import {InputList} from "#lib/components/list/input-list.tsx";
import {FaPlus} from "react-icons/fa";
import {SenseIDFn} from "common/legends/game/sense/sense-i-d.ts";
import {SenseFn} from "common/legends/game/sense/sense.ts";
import {StageFn} from "common/legends/stage/stage.ts";
import {StageListItem} from "../../common/stage/stage-list-item.tsx";
import {generateStageID} from "common/legends/stage/stage-i-d.ts";
import {CurrentStageField} from "../../field/current-stage-field.tsx";
import {StageOverrideField} from "../../field/stage-override-field.tsx";

export function SettingsStackView() {
  const gameRef = useGameReference();
  const gameSignal = useGame();
  const {name, owner, defaultSceneID, players, systems, invitations, sensesRef, stagesRef, globalStageIDRef} = useMemo(() => GameSignals(gameSignal), [gameSignal]);
  const playerRefs = useRefValue(useMemo(() => MapSignals.expand(players), [players]));

  const {name: ownerName, icon: ownerIcon, color: ownerColor, stageIDRef: ownerStageIDRef} = useMemo(() => OwnerEntity(owner), [owner]);
  const applyToInvitations = useApplyToGameInvitations();

  const invitationsValue = useObservable(invitations.observe, [], [invitations]);

  const navigate = useNavigate();

  const userID = useUserID()!;
  const applyToUser = useApplyToStore("user", userID);
  const removeGameId = useRemoveGameId(applyToUser);
  const deleteGame = useDeleteGame();
  const onDeleteGame = useCallback(() => {
    removeGameId(gameRef.storeId);
    deleteGame(gameRef.storeId).then(() => {
      navigate("/");
    });
  }, [removeGameId, deleteGame, gameRef, navigate]);

  function removePlayer(userID: UserID) {
    players.apply(prev => MapFn.delete<UserID, Player>(userID, prev[userID]!));
  }

  const [isOpen, setIsOpen] = useState(false);

  const addSense = (ev: MouseEvent<HTMLButtonElement>) => {
    sensesRef.apply(prev => ListOperation.insert(prev.length, {
      senseID: SenseIDFn.generate(),
      name: "Sense",
      color: Color.WHITE
    }))
    ev.preventDefault();
    ev.stopPropagation();
  };
  const hasSenses: boolean = useComputedValue(sensesRef, sense => sense.length > 0);


  const addStage = (ev: MouseEvent<HTMLButtonElement>) => {
    stagesRef.apply(prev => ListOperation.insert(prev.length, {
      stageID: generateStageID(),
      name: "Stage"
    }))
    ev.preventDefault();
    ev.stopPropagation();
  };
  const hasStages: boolean = useComputedValue(stagesRef, stages => stages.length > 0);

  return <div className="tab-content flex flex-col gap-2 py-2">
    <Panel title="Game Settings">
      <Section>
        <Field>
          <FieldLabel>Game Name</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputString value={name} />
          </InputGroup>
        </Field>

        <Field>
          <FieldLabel>Default Scene</FieldLabel>
          <InputOptionalScene value={defaultSceneID} />
        </Field>
      </Section>
      <Section title="Game Master">
        <Field>
          <FieldLabel>Name</FieldLabel>
          <InputGroup className="mx-2 rounded-md overflow-hidden">
            <InputString value={ownerName} />
          </InputGroup>
        </Field>
        <Field>
          <FieldLabel>Icon</FieldLabel>
          <InputFile placeholder="Image" value={ownerIcon} />
        </Field>
        <ColorField label="Color" title="Owner Color" value={ownerColor} />
        <StageOverrideField valueRef={ownerStageIDRef} />
      </Section>
      <Section title="Senses" options={<Button onClick={addSense} size="small" className="rounded-md"><FaPlus /> Add Sense</Button>}>
        <div className="flex flex-col gap-0.5 mx-2 rounded-md overflow-hidden">
          <InputList accept={"legends/sense"} items={sensesRef} itemKey={SenseFn.itemKey} copy={SenseFn.copySense} ListItem={SenseListItem}/>
          {!hasSenses && <span className="italic text-sm text-white/50 px-4 flex justify-center">
          There are currently no senses.
        </span>}
        </div>
      </Section>
      <Section title="Stages" options={<Button onClick={addStage} size="small" className="rounded-md"><FaPlus /> Add Stage</Button>}>
        <CurrentStageField valueRef={globalStageIDRef} />

        <Field>
          <FieldLabel>Stages</FieldLabel>
          <div className="mx-2 rounded-md overflow-hidden">
            <InputList accept="legends/stage" items={stagesRef} itemKey={StageFn.getStageID} copy={StageFn.copyStage} ListItem={StageListItem} />
            {!hasStages && <span className="italic text-sm text-white/50 px-4 flex justify-center">
              There are currently no stages.
            </span>}
          </div>
        </Field>
      </Section>
      <Section title="Players">
        <div className="flex flex-col gap-0.5">
          {(Object.keys(playerRefs) as UserID[]).map(playerID => <GamePlayerView key={playerID} value={playerRefs[playerID]!} remove={() => {
            removePlayer(playerID)
          }} />)}
        </div>
        {Object.keys(playerRefs).length === 0 && <span className="italic text-sm text-white/50 px-4 flex justify-center">
          There are currently no players.
        </span>}
      </Section>
      <InvitationSection value={invitationsValue} apply={applyToInvitations} />
    </Panel>
    <Panel title="Game Systems">
      <SystemsView value={systems} />
    </Panel>
    <Panel title="Danger Zone!">
      <Section>
        <Button className="mx-2 rounded-md overflow-hidden" variant={"destructive"} onClick={() => setIsOpen(true)}>Delete Game</Button>
        <ConfirmDeleteModal open={isOpen} onClose={() => {
          setIsOpen(false);
        }} onConfirm={() => {
          onDeleteGame();
        }} />
      </Section>
    </Panel>
  </div>
}