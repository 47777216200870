import {Optional} from "common/types/index.ts";
import {Node, NodeId, NodeOperation} from "common/legends/index.ts";
import {useMemo} from "react";
import {SheetReference, SheetReferenceFn} from "../../common/sheet/sheet-reference.ts";
import {useAccess} from "../../../routes/game/model/store-context.tsx";
import {fromSignal, MutableRef, Ref} from "common/ref";

export function nodeSheetReference(node: Optional<Node>): Optional<SheetReference> {
  if (node?.type !== "token") return undefined;
  const tokenID = node.data.tokenReference.tokenID;
  const tokenSheet = node.data.tokenSheets[tokenID];
  if (!tokenSheet) return undefined;
  if (tokenSheet.type === "copy") {
    return {type: "copy", nodeID: node.data.id, tokenID: tokenID};
  } else if (tokenSheet.type === "link") {
    return {type: "link", assetID: node.data.tokenReference.assetID, sheetID: tokenSheet.data};
  }
}

export function useNode(nodeID: Optional<NodeId>): MutableRef<Optional<Node>, NodeOperation[]> {
  const access = useAccess();
  return useMemo(() => fromSignal(access.node(nodeID)), [access, nodeID]);
}

export function useNodeSheetReference(nodeRef: Ref<Optional<Node>>) {
  return useMemo((): Ref<Optional<SheetReference>> => {
    return nodeRef.map(nodeSheetReference).distinct(SheetReferenceFn.equals);
  }, [nodeRef]);
}
