import {KeyCache} from "#common/legends/access/key-cache.ts";
import {z} from "zod";
import {AssetID, NodeID, SheetID, TokenID} from "#common/legends/index.ts";

export const SheetReferenceKeyCache = KeyCache<SheetReference>(key => {
  switch (key?.type) {
    case "link": return `link-${key.assetID}-${key.sheetID}`;
    case "copy": return `copy-${key.nodeID}-${key.tokenID}`;
  }
});

export const SheetReference = z.discriminatedUnion("type", [
  z.object({type: z.literal("copy"), nodeID: NodeID, tokenID: TokenID}),
  z.object({type: z.literal("link"), assetID: AssetID, sheetID: SheetID})
]);
export type SheetReference = z.infer<typeof SheetReference>;
export const SheetReferenceFn = {
  equals: (a: SheetReference | undefined, b: SheetReference | undefined): boolean => {
    if (a?.type === "link" && b?.type === "link") {
      return a.assetID === b.assetID && a.sheetID === b.sheetID;
    } else if (a?.type === "copy" && b?.type === "copy") {
      return a.nodeID === b.nodeID && a.tokenID == b.tokenID;
    } else {
      return a === b;
    }
  }
}
