import {useParams} from "react-router";
import {Button, InputGroup, useTempRef} from "#lib/components/index.ts";
import {Background} from "../../background.tsx";
import {GameID, GameOperation, PlayerRef, playerType} from "common/legends/index.ts";
import {Modal, ModalBody, ModalTitle} from "#lib/components/modal/index.ts";
import {HSLA, MapFn, SetFn, ValueFn} from "common/types/index.ts";
import {InputString} from "#lib/components/input/input-string.tsx";
import {ColorField} from "#lib/components/input/color-field.tsx";
import {IconField} from "#lib/components/input/icon-field.tsx";
import {AccessToken} from "common/access-token";
import {useEffect, useMemo} from "react";
import {LoadingScreen} from "./loading-screen.tsx";
import {userIDRef} from "#lib/auth/use-get-user-id.ts";
import {PublicKey} from "common/crypto/index.ts";
import {generateStoreChangesetID} from "common/qlab/index.ts";
import {useRefValue} from "#lib/signal/index.ts";
import {QlabHttpClient} from "#lib/qlab/index.ts";
import {authTokenRef} from "#lib/auth/auth-client.ts";
import {Ref} from "common/ref";
import {createValueRef} from "common/ref";
import {ErrorScreen} from "./error-screen.tsx";
import {useNavigate} from "react-router-dom";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";

export function JoinRoute() {
  const {gameID, code} = useParams();
  const accessTokensRef: Ref<AccessToken[]> = useMemo(() => createValueRef([{type: "game-invitation", gameID: gameID as GameID, code: code as string} satisfies AccessToken]), [code]);
  return (<JoinPage gameID={gameID! as GameID} accessTokensRef={accessTokensRef} />);
}

export type JoinPageProps = {
  gameID: GameID;
  accessTokensRef: Ref<AccessToken[]>;
};

const COLORS: HSLA[] = [
  [0.0000, 0.75, 0.75, 1] as HSLA,
  [0.0625, 0.75, 0.75, 1] as HSLA,
  [0.1250, 0.75, 0.75, 1] as HSLA,
  [0.1875, 0.75, 0.75, 1] as HSLA,
  [0.2500, 0.75, 0.75, 1] as HSLA,
  [0.3125, 0.75, 0.75, 1] as HSLA,
  [0.3750, 0.75, 0.75, 1] as HSLA,
  [0.4375, 0.75, 0.75, 1] as HSLA,
  [0.5000, 0.75, 0.75, 1] as HSLA,
  [0.5625, 0.75, 0.75, 1] as HSLA,
  [0.6250, 0.75, 0.75, 1] as HSLA,
  [0.6875, 0.75, 0.75, 1] as HSLA,
  [0.7500, 0.75, 0.75, 1] as HSLA,
  [0.8125, 0.75, 0.75, 1] as HSLA,
  [0.8750, 0.75, 0.75, 1] as HSLA,
  [0.9375, 0.75, 0.75, 1] as HSLA,
];

export function JoinPage({gameID, accessTokensRef}: JoinPageProps) {
  const userID = useRefValue(userIDRef)!;
  const httpClient = useMemo(() => new QlabHttpClient(authTokenRef, accessTokensRef), [accessTokensRef]);
  const game = useQuery({
    queryKey: ["store", gameID, "game"],
    queryFn: async () => {
      const store = await httpClient.getStore(gameID);
      if (store.value?.type !== "game") return undefined;
      return store.value.data;
    },

  });
  const user = useQuery({
    queryKey: ["store", userID, "user"],
    queryFn: async () => {
      const store = await httpClient.getStore(userID);
      if (store.value?.type !== "user") return undefined;
      return store.value.data;
    }
  });

  const playerRef = useTempRef(playerType, () => ({
    name: "",
    icon: undefined,
    color: COLORS[Math.floor(Math.random() * COLORS.length)],
    gameMaster: false,
    stageID: undefined,
    publicKey: ["", ""] as PublicKey
  }));
  const {nameRef, colorRef, iconRef} = useMemo(() => PlayerRef(playerRef), [playerRef]);

  const queryClient = useQueryClient();
  const onJoin = useMutation({
    mutationKey: ["store", gameID, "game"],
    mutationFn: async () => {
      if (!user.isSuccess || !user.data) return;
      await httpClient.applyToStore(gameID, [{
        userId: userIDRef.value!,
        changesetId: generateStoreChangesetID(),
        revision: -1,
        operations: ValueFn.apply([{
          type: "game",
          operations: [{
            type: "update-players",
            operations: MapFn.put(userID, {
              name: nameRef.value,
              color: colorRef.value,
              icon: iconRef.value,
              publicKey: user.data.publicKey,
              stageID: undefined,
              gameMaster: false
            })
          }] satisfies GameOperation[]
        }])
      }]);
      await httpClient.applyToStore(userID, [{
        userId: userID,
        changesetId: generateStoreChangesetID(),
        revision: -1,
        operations: ValueFn.apply([{type: "user", operations: [{
          type: "update-game-ids",
          operations: SetFn.insert(gameID)
        }]}])
      }]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["store", gameID, "game"]});
    }
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (!game.data) return;
    if (game.data.players[userID]) navigate(`/game/${gameID}`);
  }, [game.data?.players[userID], userID, navigate]);

  if (user.isLoading || !user.data || game.isRefetching || game.isLoading || !game.data) return <LoadingScreen />;
  if (user.isError || game.isError) return <ErrorScreen errorMessage={"Could not load game."} />;
  if (onJoin.isLoading) return <LoadingScreen />;
  if (game.data.players[userID]) return <LoadingScreen />

  return (
    <Background>
      <Modal className="max-w-screen-sm">
        <ModalTitle>Join Game: {game.data.name}</ModalTitle>
        <ModalBody>
          <div className="flex flex-row items-center gap-2 ml-7 mr-2 shrink-0 group bg-zinc-900/50 rounded-md">
            <IconField value={iconRef} className="-ml-5 cursor-pointer" />
            <ColorField value={colorRef} className="px-1 w-8 basis-8 shrink-0"/>
            <InputGroup className="flex-1">
              <InputString placeholder="Name" value={nameRef} />
            </InputGroup>
          </div>
          <Button type="submit" variant="primary" className="flex-1" disabled={onJoin.isLoading} onClick={() => onJoin.mutate()}>Join</Button>
        </ModalBody>
      </Modal>
    </Background>
  );
}