import {ExpandableLabel, IconButton, InputGroup, InputGroupLabel, InputNumber, useToggle} from "#lib/components/index.ts";
import {DragIndicator} from "#lib/components/tree-view/drag-indicator.tsx";
import React, {Ref, useMemo} from "react";
import {FaTrash} from "react-icons/fa";
import {InteractionActionTeleport, InteractionActionTeleportFn, InteractionActionTeleportOperation} from "common/legends/node/interaction/action/interaction-action-teleport.ts";
import {InteractionActionTargetField} from "./interaction-action-target-field.tsx";
import {NodeIDField} from "./node-i-d-field.tsx";
import {Node} from "common/legends/node/index.ts";
import {Fieldset} from "#lib/components/fieldset/fieldset.tsx";
import {MutableRef} from "common/ref";
import {PointSignals} from "common/types/generic/point/index.ts";
import {NodePath} from "../../../../../viewport/common/context/get-elements-at-point.ts";

function isTeleportElement(node: Node, path: NodePath): boolean {
  if (path.type === "asset") return false;
  if ("mountable" in node.data) return node.data.mountable;
  return false;
}

export function InputInteractionActionTeleport({dragHandlerRef, value, remove}: {
  value: MutableRef<InteractionActionTeleport, InteractionActionTeleportOperation[]>;
  dragHandlerRef: Ref<HTMLButtonElement>;
  remove: () => void;
}) {
  const {targetRef, nodeIDRef, offsetRef} = useMemo(() => InteractionActionTeleportFn.expand(value), [value]);
  const {x, y} = useMemo(() => PointSignals(offsetRef), [offsetRef]);
  const [expanded, toggleExpanded] = useToggle(false);

  return <>
    <ExpandableLabel expanded={expanded} toggleExpand={toggleExpanded}>
      <IconButton ref={dragHandlerRef} title="Move"><DragIndicator /></IconButton>
      <InputGroup className="flex-1">
        <InputGroupLabel>Teleport</InputGroupLabel>
      </InputGroup>
      <IconButton size="small" title="Remove" variant="destructive" onClick={() => remove()}><FaTrash /></IconButton>
    </ExpandableLabel>
    {expanded && <Fieldset>
      <InteractionActionTargetField valueRef={targetRef}/>
      <NodeIDField valueRef={nodeIDRef} predicate={isTeleportElement}/>
      <div className="flex flex-row">
        <InputGroup>
          <InputGroupLabel>Offset</InputGroupLabel>
        </InputGroup>
        <InputGroup className="flex-1">
          <InputGroupLabel>X</InputGroupLabel>
          <InputNumber value={x}/>
        </InputGroup>
        <InputGroup className="flex-1">
          <InputGroupLabel>Y</InputGroupLabel>
          <InputNumber value={y}/>
        </InputGroup>
      </div>
    </Fieldset>}
  </>;
}