import {ObjectType, Point, PointOperation, Size, SizeOperation, sizeType, StringOperation, Transform, TransformOperation, Type} from "#common/types/index.ts";
import {LocalNode, LocalNodeOperation, localNodeTypePropTypes} from "#common/legends/node/local-node.js";
import {MutableRef} from "#common/ref";
import {Mask, MaskOperation} from "#common/types/generic/mask/mask.js";

export type CameraElement = LocalNode & {
  size: Size;
};

export type CameraElementOperation =
  | LocalNodeOperation
  | {type: "update-size", operations: SizeOperation[]}
  ;

export const cameraElementType: Type<CameraElement, CameraElementOperation> = new ObjectType(() => ({
  ...localNodeTypePropTypes(),
  size: sizeType,
}), v => {
  if (!v.transform) v.transform = Transform.DEFAULT;
  return v;
});

export const CameraElementFn = {
  withinPoint: (value: CameraElement, x: number, y: number): boolean => {
    const [w, h] = value.size;
    const [nx, ny] = value.origin;
    return ((0 - nx) <= x && x <= (w - nx) && (0 - ny) <= y && y <= (h - ny));
  },
  expand(value: MutableRef<CameraElement, CameraElementOperation[]>) {
    return {
      nameRef: value.map<string, StringOperation[]>(
        value => value.name,
        (_, operations) => [{type: "update-name", operations}]
      ),
      originRef: value.map<Point, PointOperation[]>(
        value => value.origin,
        (_, operations) => [{type: "update-origin", operations}]
      ),
      pivotRef: value.map<Point, PointOperation[]>(
        value => value.pivot,
        (_, operations) => [{type: "update-pivot", operations}]
      ),
      transformRef: value.map<Transform, TransformOperation[]>(
        value => value.transform,
        (_, operations) => [{type: "update-transform", operations}]
      ),
      selectionMaskRef: value.map<Mask, MaskOperation[]>(
        value => value.selectionMask,
        (_, operations) => [{type: "update-selection-mask", operations}]
      ),
      sizeRef: value.map<Size, SizeOperation[]>(
        value => value.size,
        (_, operations) => [{type: "update-size", operations}]
      )
    } as const;
  }

} as const;