import {NodeId, OptionalNodeSignal} from "#common/legends/index.ts";
import {Cache} from "../cache.ts";
import {computed} from "#common/signal";
import {QLabDatabaseOperation, QLabDatabaseSignal, QLabResourceID} from "#common/qlab/index.ts";
import {ListOperation, Optional, Tree, TreeOperation, ValueFn} from "#common/types/index.ts";

export type NodeCache = Cache<Optional<NodeId>, OptionalNodeSignal>;
export function NodeCache(databaseRef: QLabDatabaseSignal): NodeCache {
  return (nodeID: Optional<NodeId>): OptionalNodeSignal => {
    return computed(
      () => {
        if (nodeID === undefined) return undefined;
        const database = databaseRef.value;
        for (const resourceID of (Object.keys(database.resources) as QLabResourceID[])) {
          const resource = database.resources[resourceID];
          if (resource?.type === "scene") {
            const item = Tree.getItemById(resource.data.children, nodeID);
            if (item) return item;
          } else if (resource?.type === "asset") {
            for (const [_, token] of Object.entries(resource.data.tokens)) {
              const item = Tree.getItemById(token.children, nodeID);
              if (item) return item;
            }
          }
        }
        return undefined;
      },
      operations => {
        if (nodeID === undefined) return;
        databaseRef.apply(database => {
          for (const [resourceID, resource] of Object.entries(database.resources)) {
            if (resource?.type === "scene") {
              const nodePath = Tree.getPath(resource.data.children, node => node.data.id === nodeID);
              if (nodePath === undefined) continue;
              return [{
                type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                  type: "scene", operations: [{
                    type: "update-children", operations: TreeOperation.apply(nodePath, operations)
                  }]
                }])
              }] satisfies QLabDatabaseOperation[];
            } else if (resource?.type === "asset") {
              for (let tokenIndex = 0; tokenIndex < resource.data.tokens.length; tokenIndex ++) {
                const token = resource.data.tokens[tokenIndex];
                const path = Tree.getPath(token.children, node => node.data.id === nodeID);
                if (path === undefined) continue;
                return [{
                  type: "resource", resourceID: resourceID as QLabResourceID, operations: ValueFn.apply([{
                    type: "asset", operations: [{
                      type: "update-tokens", operations: ListOperation.apply(tokenIndex, [{
                        type: "update-children", operations: TreeOperation.apply(path, operations)
                      }])
                    }]
                  }])
                }];
              }
            }
          }
          return [];
        });
      }
    );
  };
}
