import {StoreType, StoreTypes} from "common/qlab/index.ts";
import {ExtractValue} from "common/types/index.ts";
import {useCallback} from "react";
import {filter, map, toPromise} from "common/observable";
import {pipe} from "common/pipe";
import {useDatabase} from "../../../../../routes/game/model/store-context.tsx";

export function useGetStore() {
  const database = useDatabase();
  return useCallback(<Type extends StoreType, Value = ExtractValue<StoreTypes[Type]>>(type: Type): Promise<Value> => {
    return pipe(
      database.observe,
      filter(store => {
        return store.store?.type === type;
      }),
      map(store => store.store!.data as unknown as Value),
      toPromise
    );
  }, [database]);
}
