import {SceneID} from "#common/legends/index.ts";
import {listIdentity} from "#common/observable";
import {computed} from "#common/signal";
import {QLabDatabaseSignal, QLabResourceID} from "#common/qlab/index.ts";
import {IdentityCache} from "#common/legends/access/identity-cache.ts";

export function SceneIDsCache(database: QLabDatabaseSignal) {
  const cached = IdentityCache<QLabDatabaseSignal, SceneID[]>(listIdentity);
  return () => computed(() => cached(database, () => {
    const resourceIDs = Object.keys(database.value.resources) as QLabResourceID[];
    const sceneIDs = [];
    for (const resourceID of resourceIDs) {
      const resource = database.value.resources[resourceID];
      if (!resource) continue;
      if (resource.type === "scene") {
        sceneIDs.push(resourceID as SceneID);
      }
    }
    return sceneIDs;
  }));
}
